import React, { useState } from 'react'
import intl from 'react-intl-universal'
import styled from 'styled-components'
import { LargeWrapper, Button } from './index'
import { Navigation } from '../services'
import { Media, Devices } from '../theme'
const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${props => (props.isHidden ? 'none' : 'block')};
  z-index: 20;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      button + button {
        margin-left: 30px;
      }
    }
  }
  @media ${Devices.tablet} {
    > div {
      flex-wrap: wrap;
      justify-content: center;
      > div {
        button {
          margin-top: 0.3rem;
        }
      }
    }
  }
  @media ${Devices.phone} {
    padding: 0.3rem 0;
  }
`

const Text = styled.p`
  margin: 0;
  color: #fff;
  width: 980px;
  font-size: 20px;
  line-height: 1.5;
  ${Media.phone`
    width: 100%;
    font-size: .3rem;
    text-align: center;
  `}
`

const Disclaimer = () => {
  const [isHidden, setIsHidden] = useState(true)

  React.useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const hidden = localStorage.getItem('disclaimer-hidden')
      if (!Boolean(hidden)) {
        setIsHidden(false)
      }
    }
  }, [setIsHidden])

  const handleDismiss = () => {
    setIsHidden(true)
    localStorage.setItem('disclaimer-hidden', true)
  }

  return (
    <Wrapper isHidden={isHidden}>
      <LargeWrapper>
        <Text>{intl.get('app.cookies_policy')}</Text>
        <div>
          <Button
            variant='outlined'
            onClick={() => {
              Navigation.to({ link: '/privacy-policy/', isInternal: process.env.GATSBY_PACKAGE_NAME === 'main' })
            }}
          >
            {intl.get('product-cloud.banner.button2')}
          </Button>
          <Button onClick={handleDismiss}>{intl.get('app.cookies_policy.agree')}</Button>
        </div>
      </LargeWrapper>
    </Wrapper>
  )
}

export default Disclaimer
