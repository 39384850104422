import axios from 'axios'
import { Toast } from '@raysync/common/components'
import intl from 'react-intl-universal'
const http = axios.create({
  baseURL:
    process.env.GATSBY_ENV === 'development'
      ? 'https://cloud-sit.raysync.cn/raysync/api'
      : 'https://client.raysync.cloud/raysync/api',
  // baseURL: 'https://client.raysync.cloud/raysync/api',
  timeout: 1000 * 90,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})
http.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Toast.error(intl.get('pay.server.error'))
    return Promise.reject(error)
  },
)
http.interceptors.response.use(
  data => {
    let code = data.data.code
    if (code !== 0) {
      if (code === 244) {
        Toast.error(intl.get('app.phone-exist'))
      } else {
        Toast.error(data.data.msg)
      }
    }
    return data
  },
  error => {
    Toast.error(intl.get('pay.server.error'))
    return Promise.reject(error)
  },
)
export default http
