import React from 'react'

const SvgContact = props => (
  <svg id='contact_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 60 60' xmlSpace='preserve' {...props}>
    <style>{'.contact_svg__st0{fill:#fff}'}</style>
    <path
      className='contact_svg__st0'
      d='M30 60a5.1 5.1 0 01-4.42-2.56l-6.01-10.41h-9.01C4.74 47.03 0 42.3 0 36.48V10.56C0 4.74 4.74 0 10.56 0h38.88C55.25 0 59.99 4.73 60 10.55v25.92c0 5.82-4.74 10.56-10.56 10.56h-9.01l-6.02 10.42A5.095 5.095 0 0130 60zM10.56 4.44c-3.37 0-6.11 2.74-6.11 6.11v25.92c0 3.37 2.74 6.11 6.11 6.11h11.58l7.29 12.64c.23.4.91.39 1.13.01l7.3-12.65h11.58c3.37 0 6.11-2.74 6.11-6.11V10.56c0-3.37-2.75-6.11-6.12-6.11H10.56z'
    />
    <path
      className='contact_svg__st0'
      d='M12.92 24.79c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4zm0 0M26 24.79c0 2.21 1.79 4 4 4a3.999 3.999 0 002-7.46 4.022 4.022 0 00-4 0c-1.24.71-2 2.03-2 3.46zm0 0M39.08 24.79c0 1.43.76 2.75 2 3.46s2.76.71 4 0c1.24-.71 2-2.03 2-3.46 0-1.43-.76-2.75-2-3.46a4.022 4.022 0 00-4 0c-1.24.71-2 2.03-2 3.46zm0 0'
    />
  </svg>
)

export default SvgContact
