import React from 'react'

const SvgIndustry2 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry2_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry2_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry2_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='industry2_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry2_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={23}
        height={19}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <rect x={8} y={8} width={16} height={16} rx={2} fill='url(#industry2_svg__a)' />
    <g transform='matrix(-1 0 0 1 44 0)' filter='url(#industry2_svg__b)'>
      <rect x={22} width={22} height={22} rx={2} fill='url(#industry2_svg__c)' />
      <rect x={22.5} y={0.5} width={21} height={21} rx={1.5} stroke='url(#industry2_svg__d)' />
    </g>
    <g filter='url(#industry2_svg__e)'>
      <path
        d='M7.436 6.6h-2.04L2.126 15h1.746l.732-1.956h3.582L8.918 15h1.788l-3.27-8.4zM6.398 8.256l1.212 3.246H5.18l1.218-3.246zM11.786 6.6V15h3.462c2.628 0 4.362-1.668 4.362-4.2s-1.734-4.2-4.362-4.2h-3.462zm3.444 1.566c1.77 0 2.628 1.092 2.628 2.634 0 1.524-.858 2.634-2.628 2.634h-1.77V8.166h1.77z'
        fill='#FFF'
      />
    </g>
  </svg>
)

export default SvgIndustry2
