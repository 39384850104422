import React from 'react'

const SvgIndustry4 = props => (
  <svg fill='none' width={24} height={24.487} viewBox='0 0 24 24.487' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry4_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry4_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry4_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <clipPath id='industry4_svg__f'>
        <rect x={2} y={2} width={20} height={20} rx={0} />
      </clipPath>
      <clipPath id='industry4_svg__g'>
        <rect x={2} y={2} width={19.93} height={20} rx={0} />
      </clipPath>
      <filter
        id='industry4_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry4_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={24}
        height={24}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path
      d='M0 14C0 8.477 4.477 4 10 4h4c5.523 0 10 4.477 10 10v8a2 2 0 01-2 2H2a2 2 0 01-2-2z'
      fill='url(#industry4_svg__a)'
    />
    <g filter='url(#industry4_svg__b)'>
      <circle cx={12} cy={12} fill='url(#industry4_svg__c)' r={12} />
      <circle cx={12} cy={12} stroke='url(#industry4_svg__d)' r={11.5} />
    </g>
    <g filter='url(#industry4_svg__e)'>
      <g clipPath='url(#industry4_svg__f)'>
        <g clipPath='url(#industry4_svg__g)'>
          <path
            d='M21.74 9.36c-.22-.2-.56-.2-.79 0-2.76 2.76-5.47 3.47-8.53 2.17-1.23-3.05-.59-5.76 2.17-8.53.23-.22.23-.59.01-.82l-.01-.01a.582.582 0 00-.82-.01l-.01.01c-2.12 2.12-3.12 4.23-3.12 6.53 0 .78.12 1.55.36 2.29-3.12-.94-6 0-8.82 2.77-.11.11-.18.26-.18.41 0 .16.06.31.18.41.22.23.58.24.81.02l.02-.02c2.77-2.76 5.47-3.46 8.53-2.17 1.23 3.05.59 5.76-2.17 8.53-.11.11-.17.26-.18.41 0 .16.06.31.18.41a.542.542 0 00.83.06c2.76-2.82 3.7-5.71 2.76-8.82 3.12.94 6 0 8.82-2.82.22-.24.2-.61-.04-.83v.01z'
            fill='#FFF'
          />
          <path
            d='M12.8 7.46l-.02.02c-.23.21-.24.57-.03.8 0 .01.02.02.03.03l2.94 2.94c.23.23.6.23.83 0 .23-.23.23-.6 0-.83l-2.96-2.94a.547.547 0 00-.79-.02zM14.03 5.11l-.02.02c-.11.1-.18.25-.18.4 0 .16.06.31.18.41l4.12 4.12c.23.23.6.23.83 0 .23-.23.23-.6 0-.83l-4.12-4.11a.57.57 0 00-.81-.02v.01zM10.13 18.9c.23-.23.23-.6 0-.83l-4.12-4.12a.57.57 0 00-.81-.02l-.02.02a.57.57 0 00-.02.81q.01.02.02.02L9.3 18.9c.23.23.6.23.83 0zM11.28 16.55l.01-.01a.57.57 0 00.02-.81l-.02-.02-2.94-2.94a.57.57 0 00-.81-.01l-.01.01c-.11.11-.18.26-.18.41 0 .16.07.3.18.41l2.94 2.94c.22.23.59.23.82.01l-.01.01z'
            fill='#FFF'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgIndustry4
