import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Wrapper, H2 } from '@raysync/common/components'
import { Button } from '@raysync/common/components'

export const BannerWrap = styled(Wrapper)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0;
    align-content: space-between;
  `}
`
export const BannerContainer = styled.div`
  ${({ theme: { color } }) => css`
    position: relative;
    height: 460px;
    overflow: hidden;
    background-color: ${color.panel};
    ${Media.phone`
      height: 10.4rem;
   `}
  `}
`

export const BannerLeft = styled.div`
  max-height: 640px;
  ${Media.phone`
    width: 100%;
  `}
`
export const BannerRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${p => (p.right ? p.right + 'px' : 0)};
  overflow: hidden;
  svg,
  img {
    height: ${p => (p.isProduct ? '360px' : '400px')};
  }

  &.solution6-banner svg {
    width: 684px;
  }
  &.network1-banner svg {
    height: 360px;
    width: 430px;
  }
  &.network3-banner svg {
    height: 360px;
    width: 430px;
  }
  ${Media.phone`
    width: 100%;
    svg,img {
      width: 7.3rem !important;
      height: 5.3rem !important;
      transform: translate(-1.3rem,.1rem) scale(1.2);
    }
  `}
`

export const BannerTitle = styled(H2)`
  ${({ theme: { Hack } }) => css`
    line-height: 1.4;
    padding-bottom: 20px;
    font-size: 40px;
    max-width: 550px;
    span {
      font-weight: 500;
    }

    ${Media.phone`
    margin: .5rem auto 0;
    max-width: 100%;
    padding: 0.4rem .54rem;
    font-size: 24px;
    line-height: 30px;
  `}
  `}
`
export const BannerDesc = styled.p`
  ${({ theme: { color, typography, Hack } }) => css`
    width: 512px;
    font-size: ${typography.text};
    span {
      display: inline-block;
      width: 100%;
    }

    line-height: 32px;
    color: #636772;
    &.zh-CN-solution2 {
      white-space: nowrap;
    }
    &.zh-CN-network1 {
      width: 490px;
    }
    &.zh-CN-network4 {
      width: 545px;
    }
    &.zh-CN-network5 {
      width: 575px;
    }
    &.zh-CN-network6 {
      width: 490px;
    }

    ${Hack.desktopEn`
      font-size: ${typography.text};
    `}

    ${Media.phone`
      margin: 0 auto;
      max-width: 100%;
      padding: 0 .54rem;
      font-size: ${typography.textThin};
      line-height: 18px;
      text-align: center;
      span {
        text-align: center;
      }
      &.zh-CN-solution2 {
        white-space: initial;
      }
    `}
  `}
`
export const BannerButton = styled(Button)`
  ${({ theme: { Hack, typography } }) => css`
    margin-top: 20px;

    min-width: 140px;
    min-height: 36px;
    font-size: ${typography.text};

    ${Hack.desktopEn`
      min-width: 120px;
      min-height: 44px;
      font-size: 20px;
    `}

    ${Media.phone`
      display: block;
      margin: .52rem auto .66rem;
    `}
  `}
`

export const BannerImg = styled.img`
  height: 418px;
  ${Media.phone`
    width: 100%;
    height: 4rem;
  `}
`
export const IndustryBannerContainer = styled(BannerContainer)`
  height: 400px;
`
