import React from 'react'
import styled, { css } from 'styled-components'
import { Media } from '../../theme'
const FormItemWrap = styled.div`
  position: relative;
  margin-bottom: 14px;
  ${Media.phone`
     width: 100%;
  `}
`
const Label = styled.label`
  ${({ theme: { color } }) => css`
    display: block;
    position: relative;
    color: #636786;
    line-height: 1;
    font-size: 16px;
    padding-bottom: 10px;
    white-space: pre;
    ${p => !p.hasContent && 'width: 0;'} ::before {
      content: '*';
      display: ${p => (p.required ? 'inline-block' : 'none')};
      width: 7px;
      margin-right: 2px;
      color: ${color.secondary};
      font-size: 12px;
      line-height: 1;
    }
    ${Media.phone`
      font-size: .24rem;
      padding-bottom: .14rem;
    `}
  `}
`
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  > div {
    display: flex;
    ${Media.phone`
      ${p =>
        p.hasTips &&
        css`
          flex-wrap: wrap;
        `}
    `}
  }
`

const ErrorMessage = styled.span`
  color: #ff4d4f;
  text-align: left;
  margin-left: 8px;
  font-size: 16px;
  ${Media.phone`
    font-size: .24rem;
    margin-left: .1rem;
  `}
`
const EnFormItem = props => {
  let {
    required,
    labelStyle,
    labelWidth,
    label,
    children,
    message,
    errorLeft,
    tips,
    contentStyle,
    ...otherProps
  } = props
  return (
    <FormItemWrap {...otherProps}>
      <Label style={labelStyle} labelWidth={labelWidth} required={required} hasContent={!!label}>
        {label}
        <ErrorMessage errorLeft={errorLeft}>{message}</ErrorMessage>
      </Label>
      <InputWrapper hasMessage={required} hasTips={!!tips}>
        <div style={contentStyle}>{children}</div>
      </InputWrapper>
    </FormItemWrap>
  )
}
export default EnFormItem
