import React from 'react'
import intl from 'react-intl-universal'
import { useScroll } from '@raysync/common/hooks'
import { Flex, Icon, ShowAtMobile } from '@raysync/common/components'
import { Navigation } from '@raysync/common/services'
import {
  Navbar,
  Brand,
  StyledWrapper,
  NavbarWrap,
  LinkShape,
  LinkWrap,
  IconWrap,
  DropdownWrap,
  DropdownMenu,
  ItemWrap,
  Link,
  ApplyButton,
} from './en-atoms'
import MobileMenu from './en-mobile-menu'
import LogoIcon from '../svg/enHome/logo'

function ScrollHandle({ handler }) {
  useScroll(handler)
  return null
}

const currentPackageName = process.env.GATSBY_PACKAGE_NAME
class Header extends React.PureComponent {
  state = {
    isTransparent: false,
    isExpand: false,
    navigationList: [
      {
        text: intl.get('app.header.products'),
        children: [
          { link: '/enterprise/', text: intl.get('enterprise.link') },
          { link: '/smb/', text: intl.get('app.smb') },
        ],
      },
      {
        text: intl.get('app.header.solution'),
        children: [
          { link: '/solutions/petabyte-scale-data-transfer/', text: intl.get('app.footer.solution9') },
          { link: '/solutions/massive-file-transfer/', text: intl.get('app.footer.solution19') },
          { link: '/solutions/data-migration-data-sync-and-data-backup/', text: intl.get('app.footer.solution12') },
          { link: '/solutions/p2p-large-scale-file-transfer/', text: intl.get('app.footer.solution17') },
          { link: '/solutions/file-management/', text: intl.get('app.footer.solution14') },
          { link: '/solutions/data-collaboration-and-secure-file-sharing/', text: intl.get('app.footer.solution13') },
          {
            link: '/solutions/sdk-and-api-intergration/',
            text: intl.get('app.footer.solution20'),
          },
        ],
      },
      {
        text: intl.get('app.header.industries'),
        children: [
          // { link: '/solutions/industry-government/', text: intl.get('app.footer.solution1') },
          // { link: '/solutions/industry-ad/', text: intl.get('app.footer.solution2') },
          { link: '/solutions/industry-media/', text: intl.get('app.footer.solution.media') },
          { link: '/solutions/industry-gaming/', text: intl.get('app.footer.solution.gaming') },
          { link: '/solutions/industry-internet/', text: intl.get('app.footer.solution7') },
          { link: '/solutions/industry-marine/', text: intl.get('app.footer.solution.marine') },
          { link: '/solutions/industry-manufacturing/', text: intl.get('app.footer.solution6') },
          { link: '/solutions/industry-financing/', text: intl.get('app.footer.solution3') },
          { link: '/solutions/industry-gene/', text: intl.get('app.footer.solution4') },
          { link: '/solutions/industry-car/', text: intl.get('app.footer.solution5') },

          // { link: '/solutions/industry-moive/', text: intl.get('app.footer.solution8') },
        ],
      },
      {
        link: '/pricing/',
        text: intl.get('app.header.pricing'),
      },
      {
        link: '/casestudies/',
        text: intl.get('app.header.example'),
      },
      {
        text: intl.get('app.header.company'),
        children: [
          { link: '/about-us/', text: intl.get('app.header.company.about') },
          { link: '/partners/', text: intl.get('app.header.company.partners') },
          { link: '/news/', text: intl.get('app.footer.entry2') },
        ],
      },
      {
        text: intl.get('app.header.resources'),
        children: [
          { link: '/support/document/index/', text: intl.get('app.header.support') },
          { link: '/support/document/', text: intl.get('app.header.resources.white-paper') },
          { link: '/support/video/', text: intl.get('app.header.resources.video-tutorial') },
          { link: '/support/faq/', text: intl.get('app.header.resources.faq') },
          { link: '/calculator/', text: intl.get('app.footer.calculator') },
        ],
      },
    ],
  }

  handleScroll = e => {
    this.setState({
      isTransparent: e.target.scrollingElement.scrollTop >= 100,
    })
  }

  toggleExpand = () => {
    this.setState({ isExpand: !this.state.isExpand })
  }

  handleActive = ({ children, hasMulti }, pathname) => {
    let isActive = false
    if (hasMulti) {
      children.forEach(c => {
        c.items.some(i => new RegExp(`${i.link}$`).test(pathname)) && (isActive = true)
      })
    } else {
      isActive = children.some(i => new RegExp(`${i.link}$`).test(pathname))
    }
    return isActive
  }
  render() {
    const { navigationList, isExpand, isTransparent } = this.state
    const { location, pageType } = this.props
    let pathname = /\/$/.test(location.pathname) ? location.pathname : location.pathname + '/'
    const locale = intl.getInitOptions().currentLocale
    return (
      <React.Fragment>
        <Navbar isTransparent={isTransparent}>
          {pageType === 'home' && <ScrollHandle handler={this.handleScroll} />}
          <StyledWrapper>
            <Brand href={locale === 'ja' ? '/ja' : '/'} alt='Raysync' title='Raysync'>
              <LogoIcon />
            </Brand>
            <NavbarWrap halign='space-between' grow={2}>
              <LinkWrap className='hide-at-mobile'>
                {navigationList.map((l, i) =>
                  l.children ? (
                    <DropdownMenu key={i} hasMulti={l.hasMulti} isActive={this.handleActive(l, pathname)}>
                      <span style={{ fontSize: '16px' }}>{l.text}</span>
                      <DropdownWrap>
                        {l.children.map((k, j) =>
                          k.title ? (
                            <ItemWrap key={j + 100}>
                              <h4>{k.title}</h4>
                              {k.items.map((item, o) => (
                                <LinkShape isActive={new RegExp(`${item.link}$`).test(pathname)} key={o + 400}>
                                  <Link
                                    isInternal={currentPackageName === 'main'}
                                    to={item.link}
                                    isActive={new RegExp(`${item.link}$`).test(pathname)}
                                  >
                                    {item.text}
                                  </Link>
                                </LinkShape>
                              ))}
                            </ItemWrap>
                          ) : (
                            <LinkShape isActive={new RegExp(`${k.link}$`).test(pathname)} key={j + 100}>
                              <Link
                                style={{ fontSize: 14 }}
                                to={k.link}
                                isInternal={currentPackageName === 'main'}
                                isActive={new RegExp(`${k.link}$`).test(pathname)}
                              >
                                {k.text}
                              </Link>
                            </LinkShape>
                          ),
                        )}
                      </DropdownWrap>
                    </DropdownMenu>
                  ) : l.packageName ? (
                    <Link
                      key={i}
                      to={l.link}
                      style={{ fontSize: '16px' }}
                      isInternal={currentPackageName === l.packageName}
                      isActive={pathname.split('/').includes(l.packageName)}
                    >
                      {l.text}
                    </Link>
                  ) : (
                    <Link
                      key={i}
                      to={l.link}
                      style={{ fontSize: '16px' }}
                      isInternal={currentPackageName === 'main'}
                      isActive={l.link === '/' ? /^(\/ja)?\/$/.test(pathname) : new RegExp(`${l.link}$`).test(pathname)}
                    >
                      {l.text}
                    </Link>
                  ),
                )}
              </LinkWrap>
              <Flex>
                <ApplyButton id='FreeTrial' rel='nofollow' onClick={() => Navigation.toApply({ type: 'apply' })}>
                  {intl.get('app.header.en.apply')}
                </ApplyButton>
              </Flex>
            </NavbarWrap>
            <IconWrap onClick={() => this.toggleExpand()}>
              <Icon type={isExpand ? 'close' : 'menu'}></Icon>
            </IconWrap>
          </StyledWrapper>
          <ShowAtMobile>
            <MobileMenu
              isExpand={isExpand}
              closeExpand={() => this.toggleExpand()}
              list={navigationList}
              pathname={pathname}
            ></MobileMenu>
          </ShowAtMobile>
        </Navbar>
      </React.Fragment>
    )
  }
}

export default Header
