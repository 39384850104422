import React from 'react'
import PropTypes from 'prop-types'
import { navigate as gatsbyNavigate } from 'gatsby'
import { LocaleService, LOCALES } from '../../services'
import { NavAnchor, GatsbyLink } from './atoms'

const Link = ({ to, children, isInternal = true, isActive, target, onClick, ...rest }) => {
  const handleClick = e => {
    if (onClick) {
      onClick(e)
    }
  }

  const currentLocale = LocaleService.getCurrentLocale()
  const link = LocaleService.isForeign && currentLocale !== LOCALES.EN ? `/${currentLocale}${to}` : to

  if (!target && isInternal) {
    return (
      <GatsbyLink {...rest} to={link} isActive={isActive} onClick={handleClick}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <NavAnchor href={link} isActive={isActive} target={target} {...rest}>
      {children}
    </NavAnchor>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  language: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Link.defaultProps = {
  to: '',
}

export default Link

export const navigate = (to, options, localeTo) => {
  if (typeof window === 'undefined') {
    return
  }
  const { routed, path } = window.__INTL__
  if (localeTo) {
    gatsbyNavigate(`/${localeTo}${to}`, options)
  } else {
    const link = routed ? `/${path}${to}` : `${to}`
    gatsbyNavigate(link, options)
  }
}
