import styled, { css } from 'styled-components'

import { transition, multiLineTextEllipsis, Media } from '../../../theme'

import Wrapper from '../../wrapper'

export const Card = styled.div`
  width: 280px;
  overflow: hidden;
  box-shadow: 0px 0px 12px 0px rgba(37, 42, 58, 0.12);
  ${Media.phone`
    width: 100%;
    margin-bottom: .5rem;
  `}
`

export const MediaWrap = styled.div`
  display: block;
  width: 100%;
  height: 160px;
  overflow: hidden;
  position: relative;
  a {
    width: 100%;
  }
  img {
    width: 100%;
    height: 160px;
    transition: ${transition(['transform'])};
    :hover {
      transform: scale(1.2);
    }
  }
  ${Media.phone`
    height: 3.38rem;
    img {
      height: 3.38rem;
    }
  `}
`

export const Trivia = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    padding: 7px 12px 16px;
    border-radius: 5px;
    a {
      color: ${color.primary};
      font-size: ${typography.textSmall};
      font-weight: 600;
      line-height: 24px;
      height: 48px;
      ${multiLineTextEllipsis(2)};
      margin-bottom: ${spacing.base};
    }
    p {
      font-size: ${typography.textThin};
      color: ${color.text};
      line-height: 1;
    }
    ${Media.phone`
    a{
      height: initial;
      font-weight: 400;
    }
    `}
  `}
`
export const NewsContainer = styled.div`
  padding-top: 42px;
`

export const NewsWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
export const More = styled.div`
  ${({ theme: { color, typography } }) => css`
    padding-top: 32px;
    text-align: center;
    line-height: 14px;
    a {
      color: ${color.secondary};
      font-size: ${typography.textSmall};
      :hover {
        color: #dd501b;
      }
    }
    ${Media.phone`
      padding-top: .1rem;
    `}
  `}
`
