import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, IconType } from '../icon'
import { ContainedButton, OutlinedButton, PaginationButton, CodeButton } from './atoms'
class Button extends React.PureComponent {
  handleClick = e => {
    const { onClick } = this.props
    if (onClick) {
      onClick(e)
    }
  }
  render() {
    const {
      color = 'default',
      variant = 'contained',
      hasRadius = false,
      type,
      size = 'small',
      children,
      className,
      backgroundColor,
      loading = false,
      fullWidth = false,
      disabled = false,
      ...rest
    } = this.props
    const Tag = {
      contained: ContainedButton,
      outlined: OutlinedButton,
      pagination: PaginationButton,
      code: CodeButton,
    }[variant]

    const classes = classNames(className, {
      [`btn-${size}`]: size,
      [`btn-loading`]: loading,
    })

    return (
      <Tag
        {...rest}
        hasRadius={hasRadius}
        type={type}
        color={color}
        fullWidth={fullWidth}
        backgroundcolor={backgroundColor}
        onClick={this.handleClick}
        className={classes}
        rel='nofollow'
        disabled={disabled || loading}
      >
        {loading && <Icon type={IconType.Loading} />} {children}
      </Tag>
    )
  }
}

Button.propTypes = {
  color: PropTypes.oneOf(['default', 'primary', 'white']),
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  variant: PropTypes.oneOf(['outlined', 'contained', 'pagination', 'code']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Button
