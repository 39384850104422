import styled, { css } from 'styled-components'
import NativeLink from '../link'
import Button from '../button/button'
import Arrow from './arrow.png'
import { transition, hideAtMobile, showAtMobile, Media } from '../../theme'

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  padding-left: 20px;
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  .hide-at-mobile {
    ${hideAtMobile}
  }
  ${Media.phone`
    justify-content: space-between;
    min-width: unset;
  `}
`
const commonLink = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 25px;
  padding-top: 4px;
  font-weight: 600;
  height: 60px;

  line-height: 52px;
  color: ${p => (p.isActive ? p.theme.color.secondary : '#fff')};
  font-size: 16px;
  span {
    font-weight: 600;
  }
  transition: ${transition('border-color', 'color')};

  :hover {
    color: ${p => p.theme.color.secondary};
    border-bottom: 4px solid ${p => (p.isActive ? p.theme.color.secondary : 'transparent')};
    border-bottom-color: ${p => p.theme.color.secondary};
  }
`
export const Link = styled(NativeLink)`
  width: auto !important;
  /* border-bottom: 4px solid ${p => (p.isActive ? p.theme.color.secondary : 'transparent')}; */
`
export const Navbar = styled.header`
  display: flex;
  align-content: center;
  position: fixed;
  z-index: 4800;
  top: 0;
  width: 100%;
  height: 60px;
  min-width: 100px;
  background: ${p => (p.isTransparent ? 'rgba(32,31,38, 0.9)' : 'rgba(32,31,38, 1)')} !important;
  ${Media.phone`
    height: .9rem;
  `}
`

export const Brand = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  svg {
    ${({ theme: { Hack } }) => css`
    width: 95px;
    height: 30px;

  ${Hack.desktopForeign`
    width: 150px;
  `}

  ${Media.phone`
    width: 1.2rem;
    height:.4rem;
  `}

  ${Hack.mobileForeign`
    width: 1.8rem;
  `}
`}
  }
`

export const Logo = styled.img`
  ${({ theme: { Hack } }) => css`
    width: 95px;
    height: 30px;

  ${Hack.desktopForeign`
    width: 150px;
  `}

  ${Media.phone`
    width: 1.2rem;
    height:.4rem;
  `}

  ${Hack.mobileForeign`
    width: 1.8rem;
  `}
`}
`

export const LinkShape = styled.div`
  width: 100%;
  padding: 0 20px;
  border-radius: 10px;
  background: ${({ isActive }) => (isActive ? '#f3f6f7' : '#fff')};
  :hover {
    background: #f3f6f7;
  }
`

export const LinkWrap = styled.div`
  width: 100%;
  > a {
    ${commonLink};
  }
`
export const NavbarWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${Media.phone`
    padding: .1rem .7rem .1rem 0;
    justify-content: flex-end;
  `}
`

export const ApplyButton = styled(Button)`
  min-width: 180px;
  height: 100%;
  border-radius: 0;
  ${Media.phone`
    min-width: 1.8rem;
  `}
`

export const DropdownWrap = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    display: none;
    position: absolute;
    white-space: nowrap;
    top: 60px;
    left: 50%;
    text-align: left;
    /* background: rgba(0, 0, 0, 0.6); */
    transform: translateX(-50%);
    /* box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1); */

    background: #ffffff;
    border: 1px solid #f3f6f7;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    a,
    span {
      width: 12em;
      line-height: 32px;
      display: block;
      color: #636772;
      font-size: ${typography.textThin};
      ${Hack.en`
        width: 20em;
        font-size: ${typography.textMini};
      `}
      ${Hack.ja`
        width: 16em;
      `}
    }
  `}
`
export const DropdownMenu = styled.div`
  ${commonLink};
  cursor: default;
  :hover {
    ${DropdownWrap} {
      display: ${p => (p.hasMulti ? 'flex' : 'block')};
      padding: ${p => (p.hasMulti ? '' : '10px')};
    }
  }
`
export const ItemWrap = styled.div`
  display: inline-block;
  padding: 0 48px 20px 32px;
  h4 {
    font-weight: 600;
    border-bottom: 1px solid #e6ebef;
    margin-bottom: 4px;
  }
`
export const Telphone = styled.a`
  ${({ theme: { color, typography, spacing } }) => css`
    color: ${color.secondary};
    font-size: ${typography.text};
    margin-right: ${spacing.base};
    svg {
      width: 16px;
      height: 16px;
      margin: 0px 5px 0 0;
      path {
        fill: ${color.secondary};
      }
    }
  `}
`
export const IconWrap = styled.div`
  ${({ theme: { color, Hack } }) => css`
    ${showAtMobile}
    ${Media.phone`
      display: flex;
      padding-right: .3rem;
      svg {
        width: 0.5rem;
        height: 0.5rem;
        color: #C1C7D6;
        path {
          color: #C1C7D6;
        }
      }
    `}
  `}
`

export const MobileMenuContainer = styled.div`
  position: fixed;
  overscroll-behavior: contain;
  -ms-scroll-chaining: contain;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 6000;
  ${Media.phone`
      top: .9rem;
  `}
`
export const Mask = styled.div`
  width: 100vw;
  background-color: transparent;
  bottom: 0;
  height: ${p => (p.isExpand ? '20vh' : '0px')};
`
export const ListWrap = styled.div`
  position: relative;
  max-width: 100vw;
  overflow: auto;
  height: ${p => (p.isExpand ? '80vh' : '0px')};
  z-index: 9000;
  background: white;
  transition: ${transition('height')};
  border-radius: 0px 0px 20px 20px;
  ${p =>
    p.isExpand &&
    css`
      padding: 0 0 3rem 0;
    `}
`
export const ListItemLink = styled(Link)`
  display: block;
  padding: 0 0.4rem;
  line-height: 63px;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #ebebeb;
  font-size: 18px;
  font-weight: 500;
`
export const ListItemBlock = styled.div`
  display: block;
  padding: 0 0.4rem;
  line-height: 63px;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #ebebeb;
  font-size: 18px;
  font-weight: 500;
  color: ${p => (p.isActive ? p.theme.color.secondary : p.theme.color.primary)};
  ::after {
    content: '';
    position: absolute;
    right: 0.3rem;
    top: 46%;
    width: 0.34rem;
    height: 0.2rem;
    background: url(${Arrow}) 100% 100%/ 100% 100%;
    transform: rotate(${p => (p.isExpand ? '0deg' : '180deg')});
    transition: ${transition('transform')};
  }
`

export const ListItem = styled.div`
  list-style: none;
  max-height: ${p => (p.isExpand ? p.maxHeight + 'px' : '64px')};

  transition: max-height 0.5s ease-in;
  overflow: hidden;
`
export const ExpandContent = styled.div``
export const ExpandItem = styled(Link)`
  ${({ theme: { color } }) => css`
    background: ${color.panel};
    display: block;
    padding: 0 0.4rem;
    font-size: 16px;
    line-height: 0.9rem;
    color: ${color.primary};
  `}
`

export const ExpandTitle = styled.p`
  ${({ theme: { color } }) => css`
    font-size: 15px;
    padding: 0 0.4rem;
    line-height: 1;
    color: ${color.text};
  `}
`
export const MultiContent = styled.div`
  ${({ theme: { color } }) => css`
    padding: 0.4rem 0 0.2rem;
    border-bottom: 1px solid #ebebeb;
    background: ${color.panel};
  `}
`
