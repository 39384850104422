import React from 'react'
import { H3 } from '@raysync/common/components'
import { ListWrap, ListItem, SvgContainer } from './atoms'

const List = ({ data, height }) => (
  <ListWrap>
    {data.map((l, i) => (
      <ListItem key={i} style={{ height: height + 'px' }}>
        <SvgContainer>{l.svg}</SvgContainer>
        <div>
          <H3>{l.title}</H3>
          <p>{l.desc}</p>
        </div>
      </ListItem>
    ))}
  </ListWrap>
)

export default List
