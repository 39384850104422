import React from 'react'

const SvgToastSuccess = props => (
  <svg viewBox='0 0 20 20' {...props}>
    <path
      d='M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5.85 7.12L8.58 14.3 4.07 9.9 5.5 8.38l3.08 3.08 5.83-5.83 1.44 1.49zm0 0'
      fill='#3ab056'
    />
  </svg>
)

export default SvgToastSuccess
