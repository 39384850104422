import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { NavAnchor } from './atoms'
import intl from 'react-intl-universal'
import FacebookIcon from '../svg/enHome/facebook'
import TwitterIcon from '../svg/enHome/twitter'
import YoutubeIcon from '../svg/enHome/youtube'
import InstagramIcon from '../svg/enHome/instagram'
import LinkedinIcon from '../svg/enHome/linkedin'
import { NewMedia } from '../../theme'
const contacts = [
  {
    text: 'Facebook',
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/Raysync-1505841156187794',
  },
  {
    text: 'Twitter',
    icon: <TwitterIcon width={40} />,
    link: 'https://twitter.com/raysync',
  },
  {
    text: 'Youtube',
    icon: <YoutubeIcon width={40} />,
    link: 'https://www.youtube.com/channel/UCxng8SZfQ6HhbY7utuALVVw/',
  },
  {
    text: 'Instagram',
    icon: <InstagramIcon />,
    link: 'https://www.instagram.com/raysynctech/',
  },
  {
    text: 'Linkedin',
    icon: <LinkedinIcon />,
    link: 'https://www.linkedin.com/company/rasysync',
  },
]

const SnsContainer = styled.div`
  padding-top: 20px;
  p {
    color: #7e7e7e;
  }
  svg {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 10px;
    path {
      fill: rgb(193, 199, 214);
    }
    &:hover path {
      fill: unset;
    }
  }
  ${NewMedia.phone`
    p{
      font-size:.24rem;
      line-height:24px;
    }
    // svg{
    //   width: .4rem;
    //   height: .4rem;
    // }
  `}
`
class Sns extends PureComponent {
  render() {
    return (
      <SnsContainer>
        <div style={{ display: 'flex' }}>
          <p>{`${intl.get('app.footer.follow-us')}：`}</p>
          {contacts.map((l, i) => (
            <NavAnchor key={i} rel='nofollow' href={l.link} target='_blank'>
              {l.icon}
            </NavAnchor>
          ))}
        </div>
      </SnsContainer>
    )
  }
}

export default Sns
