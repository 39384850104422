import React from 'react'
import { Formik } from 'formik'
import {
  ModalPortal,
  Button,
  FormItemStyled as FormItem,
  TextArea,
  TextInput,
  H2,
  Toast,
} from '@raysync/common/components'
import { useTimer } from '@raysync/common/hooks'
import API from '@raysync/common/services/api'
import { isObjectEmpty } from '@raysync/common/utils'
import SuccessImg from '../../images/success.png'
import { ApplyWrap, ButtonGroup, TextAreaContainer, CodeWrap, SuccessContent } from './atoms'

const phoneRegExp = /^1\d{10}$/

const ApplyContent = props => {
  const [codeType, setCodeType] = React.useState()
  const [visible, setVisible] = React.useState()
  const { timerText, isRunning, startTimer, stopTimer } = useTimer('')
  const handleValidate = values => {
    const errors = {}
    if (!values.company) {
      errors.company = '组织/公司不能为空'
    }
    if (!values.name) {
      errors.name = '姓名不能为空'
    }
    if (!phoneRegExp.test(values.phone)) {
      errors.phone = '请输入正确手机号'
    }
    if (!values.verificationCode) {
      errors.verificationCode = '验证码不能为空'
    }

    return errors
  }
  const handleSubmitEvent = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    const params = {
      ...values,
      area: 1,
      codeType,
    }
    API.apply
      .addPartnerInfo(params)
      .then(res => {
        if (res.data.code === 0) {
          setVisible(true)
          props.closeModal && props.closeModal()
          resetForm()
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const sendCode = (type, phone) => {
    API.apply.sendVerificationCode({ type, phone }).then(res => {
      if (res.data.code === 0) {
        setCodeType(type)
        startTimer(60)
        Toast.info('验证码发送成功！')
      } else {
        stopTimer()
      }
    })
  }
  const initialValues = {
    company: '',
    jobTitle: '',
    name: '',
    phone: '',
    email: '',
    verificationCode: '',
    message: '',
  }
  return (
    <ApplyWrap>
      <H2>镭速生态渠道合作申请</H2>
      <p>为便于尽快核对通过申请，请填写您的真实、有效信息</p>
      <Formik enableReinitialize initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmitEvent}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          const canSendCode = phoneRegExp.test(values.phone)
          const passed =
            values.company && values.name && values.verificationCode && values.phone && isObjectEmpty(errors)
          return (
            <form onSubmit={handleSubmit}>
              <FormItem required>
                <label htmlFor='company'>
                  <span>*</span>组织/公司
                </label>
                <TextInput
                  placeholder='请填写您的组织或公司全称'
                  name='company'
                  id='company'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errorMessage={touched.company && errors['company']}
                  value={values.company}
                ></TextInput>
              </FormItem>
              <FormItem>
                <label htmlFor='jobTitle'>您的职位</label>
                <TextInput
                  placeholder='请填写您的职位'
                  id='jobTitle'
                  name='jobTitle'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.jobTitle}
                ></TextInput>
              </FormItem>
              <FormItem required>
                <label htmlFor='name'>
                  <span>*</span>您的称呼
                </label>
                <TextInput
                  placeholder='请告知我们如何称呼您'
                  name='name'
                  id='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  errorMessage={touched.name && errors['name']}
                ></TextInput>
              </FormItem>
              <FormItem required>
                <label htmlFor='phone'>
                  <span>*</span>您的电话
                </label>
                <TextInput
                  placeholder='请填写您的联系电话'
                  name='phone'
                  id='phone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  errorMessage={touched.phone && errors['phone']}
                ></TextInput>
              </FormItem>
              <FormItem required>
                <label htmlFor='verificationCode'>
                  <span>*</span>验证码
                </label>
                <CodeWrap>
                  <TextInput
                    placeholder='请填写验证码'
                    name='verificationCode'
                    id='verificationCode'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.verificationCode}
                    errorMessage={touched.verificationCode && errors['verificationCode']}
                  ></TextInput>
                  <ButtonGroup>
                    <Button
                      variant='code'
                      type='button'
                      color='white'
                      onClick={() => sendCode(1, values.phone)}
                      disabled={!canSendCode || isRunning}
                    >
                      {isRunning ? timerText : '短信验证码'}
                    </Button>
                    <Button
                      variant='code'
                      type='button'
                      color='white'
                      onClick={() => sendCode(2, values.phone)}
                      disabled={!canSendCode || isRunning}
                    >
                      {isRunning ? timerText : '语音验证码'}
                    </Button>
                  </ButtonGroup>
                </CodeWrap>
              </FormItem>
              <FormItem>
                <label htmlFor='email'>您的邮箱</label>
                <TextInput
                  placeholder='请输入您的邮箱'
                  name='email'
                  id='email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                ></TextInput>
              </FormItem>
              <TextAreaContainer>
                <label htmlFor='message'>主营业务和期望合作描述</label>
                <TextArea
                  placeholder='请填写您的主营业务和期望合作描述，500字以内'
                  name='message'
                  id='message'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.message}
                ></TextArea>
              </TextAreaContainer>

              <Button type='submit' id='agent-apply' size='large' disabled={!passed} loading={isSubmitting}>
                提交申请
              </Button>
            </form>
          )
        }}
      </Formik>
      <ModalPortal hideHeader visible={visible} minWidth={200}>
        <SuccessContent>
          <img alt='submitted successfully' src={SuccessImg} />
          <h4>提交成功</h4>
          <p>感谢您的申请，我们将第一时间与您取得联系</p>
          <Button
            onClick={() => {
              setVisible(false)
            }}
          >
            关闭
          </Button>
        </SuccessContent>
      </ModalPortal>
    </ApplyWrap>
  )
}
export default ApplyContent
