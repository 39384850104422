import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import transition from '../../theme/transition'

const commonLink = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: ${p => p.theme.typography.text};
  color: ${p => p.theme.color.primary};
  transition: ${transition(['color', 'background-color', 'border-color'])};
  :hover {
    color: ${p => p.theme.color.secondary};
  }
  ${p =>
    p.isActive &&
    css`
      color: ${p => p.theme.color.secondary} !important;
    `};
`

export const NavAnchor = styled.a`
  ${commonLink}
`

export const GatsbyLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  ${commonLink}
`
