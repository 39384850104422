import React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import { ToastWrap } from './atoms'
import { duration } from '../../theme/transition'
import SuccessIcon from '../svg/toast-success'
import ErrorIcon from '../svg/toast-error'

const IconMap = Object.freeze({
  info: <SuccessIcon />,
  error: <ErrorIcon />,
})

// TODO: transition refactor
const ToastState = {
  entering: { top: '10%' },
  entered: { top: '10%' },
  exiting: { top: '-5em' },
  exited: { top: '-5em' },
}

class Toast extends React.PureComponent {
  state = { isShow: true }
  timerID = null
  componentDidMount() {
    this.startTimer()
  }
  componentWillUnmount() {
    this.clearTimer()
  }
  render() {
    const { children, type } = this.props
    const { isShow } = this.state
    return (
      <Transition appear in={isShow} timeout={duration.standard}>
        {state => (
          <ToastWrap
            className={state}
            type={type}
            onMouseEnter={this.clearTimer}
            onMouseLeave={this.startTimer}
            style={{ ...ToastState[state] }}
          >
            {IconMap[type]}
            {children}
          </ToastWrap>
        )}
      </Transition>
    )
  }
  startTimer = () => {
    this.timerID = window.setTimeout(() => {
      this.close()
    }, this.props.duration)
  }
  clearTimer = () => {
    if (this.timerID) {
      window.clearTimeout(this.timerID)
      this.timerID = null
    }
  }
  close() {
    this.clearTimer()
    this.setState({ isShow: false })
    window.setTimeout(() => {
      this.props.onClose()
    }, duration.standard)
  }
}

Toast.propTypes = {
  onClose: PropTypes.func,
  duration: PropTypes.number,
}

export default Toast
