import { useEffect } from 'react'

export default function useScroll(handler) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = e => handler(e)
      window.addEventListener('scroll', handleScroll, false)
      return () => {
        window.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [handler])
}
