import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const SEO = ({ title, keywords, description }) => (
  <Helmet title={title}>
    <meta name='keywords' content={keywords} />
    <meta name='description' content={description} />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
  </Helmet>
)

SEO.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
}

SEO.defaultProps = {
  title: '',
  keywords: '',
  description: '',
}

export default SEO
