import React from 'react'

const SvgTwitter = props => (
  <svg viewBox='0 0 24 24' {...props}>
    <path
      d='M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zm5.9-18.5h-1.1l-4.1 4.8-3.3-4.8H5.7l4.9 7.2-4.9 5.8h1.1l4.3-5 3.5 5h3.8L13.2 11l4.7-5.5zm-2.8 12.2L7.2 6.4h1.7l7.9 11.3h-1.7z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#c1c7d6'
    />
  </svg>
)

export default SvgTwitter
