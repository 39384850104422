import React from 'react'

const SvgFacebook = props => (
  <svg viewBox='0 0 25 25' {...props}>
    <path
      d='M12.5.4C5.9.4.5 5.8.5 12.4s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm3.2 12h-2.1v7.4h-3.1v-7.4H9.1V9.7h1.5V8c0-1.2.6-3.1 3.1-3.1H16v2.5h-1.7c-.3 0-.7.1-.7.7v1.5h2.3l-.2 2.8z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#c1c7d6'
    />
  </svg>
)

export default SvgFacebook
