import { useEffect } from 'react'

export default function useSmoothScroll() {
  useEffect(() => {
    document.body.style.scrollBehavior = 'smooth'
    document.querySelector('html').style.scrollBehavior = 'smooth'
    return () => {
      document.body.style.scrollBehavior = 'unset'
      document.querySelector('html').style.scrollBehavior = 'unset'
    }
  }, [])
}
