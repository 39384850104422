import React from 'react'

const SvgIndustry3 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry3_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry3_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry3_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='industry3_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry3_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={12}
        height={14}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <circle cx={10} cy={10} fill='url(#industry3_svg__a)' r={10} />
    <g filter='url(#industry3_svg__b)'>
      <circle cx={14} cy={14} fill='url(#industry3_svg__c)' r={10} />
      <circle cx={14} cy={14} stroke='url(#industry3_svg__d)' r={9.5} />
    </g>
    <g filter='url(#industry3_svg__e)'>
      <path
        d='M17.845 9.15a.676.676 0 00-.848 0l-2.985 2.492-2.985-2.492a.675.675 0 00-.848 0c-.239.199-.239.508 0 .707l2.95 2.463h-2.532c-.334 0-.597.22-.597.499s.263.498.597.498h2.806v1.845h-2.806c-.334 0-.597.219-.597.498 0 .28.263.499.597.499h2.806v2.343c0 .279.263.498.597.498.334 0 .597-.22.597-.498v-2.343h2.806c.334 0 .597-.22.597-.499 0-.28-.263-.498-.597-.498h-2.806v-1.845h2.806c.334 0 .597-.22.597-.498 0-.28-.263-.499-.597-.499h-2.531l2.949-2.463c.239-.199.239-.508 0-.707h.024z'
        fill='#FFF'
      />
    </g>
  </svg>
)

export default SvgIndustry3
