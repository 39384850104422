import React from 'react'
import styled, { css } from 'styled-components'
import intl from 'react-intl-universal'
import Link from '../link'
import IndustryIcon1 from '../svg/layout/industry1'
import IndustryIcon2 from '../svg/layout/industry2'
import IndustryIcon3 from '../svg/layout/industry3'
import IndustryIcon4 from '../svg/layout/industry4'
import IndustryIcon5 from '../svg/layout/industry5'
import IndustryIcon6 from '../svg/layout/industry6'
import IndustryIcon7 from '../svg/layout/industry7'
import IndustryIcon8 from '../svg/layout/industry8'
import ScenneIcon1 from '../svg/layout/scene1'
import ScenneIcon2 from '../svg/layout/scene2'
import ScenneIcon3 from '../svg/layout/scene3'
import ScenneIcon4 from '../svg/layout/scene4'
import ScenneIcon5 from '../svg/layout/scene5'
import ScenneIcon6 from '../svg/layout/scene6'
import ScenneIcon7 from '../svg/layout/scene7'
import ScenneIcon8 from '../svg/layout/scene8'

const Content = styled.p`
  color: #333;
  display: flex;
`
const ContentLeft = styled.div`
  background-color: #f5f7fb;
  width: 260px;
  min-width: 260px;
  padding: 60px 20px 0 60px;
  > h3 {
  }
`
const Tab = styled.div`
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
  border-left: 2px solid #f5f7fb;
  cursor: pointer;
  color: #1c1f2b;
  font-weight: bold;
  margin-bottom: 20px;
  /* :hover {
    color: #fd5c1f;
    border-left-color: #fd5c1f;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  } */
  ${p =>
    p.actived &&
    css`
      color: #fd5c1f;
      border-left-color: #fd5c1f;
      background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    `}
`
const ContentRight = styled.div`
  background-color: ##f5f7fb;
  width: 1140px;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0px 20px 48px;
`
const MenuItem = styled(Link)`
  width: 340px;
  height: 90px;
  padding: 10px;
  display: flex;
  line-height: 1.5;
  margin-bottom: 30px;
  margin-right: 20px;
  svg {
    display: block;
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  h4 {
    display: block;
    font-size: 16px;
    color: #1c1f2b;
    font-weight: bold;
    margin-bottom: 4px;
  }
  p {
    font-size: 14px;
    color: #636772;
  }
  font-size: 14px;
  color: #252a3a;
  :hover {
    h4 {
      color: ${p => p.theme.color.secondary};
    }
    background: #f5f7fb;
  }
`
const SolutionMenu = () => {
  const [currentTab, setCurrentTab] = React.useState('1')
  const scenneItems = [
    {
      link: '/solutions/large-file-transfer/',
      title: intl.get('app.footer.scenes5'),
      text: '解决TB、PB级超大文件传输难题，实现高速数据交互',
      svg: <ScenneIcon1 />,
    },
    {
      link: '/solutions/data-transfer/',
      title: intl.get('app.footer.scenes6'),
      text: '智能压缩、小文件I/O磁盘优化技术实现每秒千个小文件高速传输',
      svg: <ScenneIcon2 />,
    },
    {
      link: '/solutions/multi-transfer/',
      title: intl.get('app.footer.scenes7'),
      text: '解决跨国数据丢包、高延迟传输问题，实现跨国数据安全快速交换',
      svg: <ScenneIcon3 />,
    },
    {
      link: '/solutions/data-sync/',
      title: intl.get('app.footer.scenes4'),
      text: '满足企业单向和双向数据同步定时数据同步、增量同步需求',
      svg: <ScenneIcon4 />,
    },
    {
      link: '/solutions/file-exchange/',
      title: intl.get('solution-file-exchange.banner.title'),
      text: '支持多种隔离网络架构下的跨网数据交换、摆渡、外发、审批、防泄密等',
      svg: <ScenneIcon5 />,
    },
    {
      link: '/solutions/data-migration/',
      title: intl.get('app.footer.scenes3'),
      text: '高效实现本地、异地、第三方云储存之间的数据迁移，降低数据迁移成本，提升迁移效率',
      svg: <ScenneIcon6 />,
    },
    {
      link: '/solutions/data-collaboration/',
      title: intl.get('app.footer.scenes1'),
      text: '支持企业内的多部门、多团队，内外部之间的高效数据传输，实现文件共享与业务协同',
      svg: <ScenneIcon7 />,
    },
    {
      link: '/solutions/data-distribution/',
      title: intl.get('app.footer.scenes2'),
      text: '实现服务端与客户端之间的一对一、一对多等多种分发模式，提升多地数据的传输管理效率',
      svg: <ScenneIcon8 />,
    },
  ]
  const industryItems = [
    {
      link: '/solutions/industry-government/',
      title: '政务与科研院所',
      text: '实现各部门单位之间、实验室与外部科研人员之间的数据高速安全传输',
      svg: <IndustryIcon1 />,
    },
    {
      link: '/solutions/industry-ad/',
      title: '广告媒体',
      text: '满足视频、图文稿件的异地安全&高速回传，实现对媒体数据的快速采集、存储和智能处理',
      svg: <IndustryIcon2 />,
    },
    {
      link: '/solutions/industry-financing/',
      title: '金融行业',
      text: '采用网银级传输加密技术，支持多种网络隔离场景下的文件安全交换，满足合规、监管需求',
      svg: <IndustryIcon3 />,
    },
    {
      link: '/solutions/industry-gene/',
      title: '基因行业',
      text: '加速基因文件大数据备份速度提高基因科研成果在不同科研机构的流传效率',
      svg: <IndustryIcon4 />,
    },
    {
      link: '/solutions/industry-car/',
      title: '汽车行业',
      text: '集中高速采集并管理全球试验场数据、路测数据等，提高车企对测试数据的研究效率',
      svg: <IndustryIcon5 />,
    },
    {
      link: '/solutions/industry-manufacturing/',
      title: '生产制造业',
      text: '替代FTP，作为智能制造稳定可靠的传输基座，提高与生产供应链之间的文件协同效率',
      svg: <IndustryIcon6 />,
    },
    {
      link: '/solutions/industry-internet/',
      title: 'IT互联网行业',
      text: '支持PB级代码库，为CI/CD提供高效版本分发通道，打造DevSecOps高速传输一体化模式',
      svg: <IndustryIcon7 />,
    },
    {
      link: '/solutions/industry-moive/',
      title: '影视制作',
      text: '替代传统寄硬盘模式，加速影视素材在制作、发行、上映各个阶段的利用效率 ',
      svg: <IndustryIcon8 />,
    },
  ]
  const currentItems = currentTab === '1' ? scenneItems : industryItems
  return (
    <Content>
      <ContentLeft>
        <Tab
          actived={currentTab === '1'}
          onClick={() => {
            setCurrentTab('1')
          }}
        >
          应用场景解决方案
        </Tab>
        <Tab
          actived={currentTab === '2'}
          onClick={() => {
            setCurrentTab('2')
          }}
        >
          行业应用解决方案
        </Tab>
      </ContentLeft>
      <ContentRight>
        {currentItems.map(item => (
          <MenuItem key={item.title} to={item.link} target='_blank'>
            {item.svg}
            <div>
              <h4>{item.title}</h4>
              <p>{item.text}</p>
            </div>
          </MenuItem>
        ))}
      </ContentRight>
    </Content>
  )
}

export default SolutionMenu
