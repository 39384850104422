import React from 'react'
import styled, { css } from 'styled-components'
import CheckboxIcon from './icon'
import { GroupContext } from './group'
const CheckboxWrap = styled.label`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 10px 0;
  cursor: pointer;
`

const StyledInput = styled.input`
  position: absolute;
  left: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
`

const LabelText = styled.span`
  cursor: pointer;
`

const StyledCheckbox = styled.div`
  ${({ theme: { color }, checked }) => css`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 10px;
    border: 1px solid ${checked ? color.secondary : '#a8b2b8'};
    background: ${checked ? color.secondary : 'white'};
    color: white;
    svg {
      width: 12px;
      height: 12px;
    }
  `}
`

const Checkbox = props => {
  let { onChange, children, value, checked, style, ...otherProps } = props
  const group = React.useContext(GroupContext)
  const handleChange = isChecked => {
    if (group) {
      isChecked ? group.registerValue(value) : group.cancelValue(value)
    }
    onChange && onChange(isChecked)
  }
  return (
    <CheckboxWrap style={style}>
      <StyledInput
        {...otherProps}
        type='checkbox'
        name='checkbox'
        value={value}
        checked={checked}
        onChange={e => handleChange(e.target.checked)}
      ></StyledInput>
      <StyledCheckbox checked={checked}>{checked && <CheckboxIcon />}</StyledCheckbox>
      <LabelText>{children}</LabelText>
    </CheckboxWrap>
  )
}
export default Checkbox
