import styled, { css } from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

export const ListWrap = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-wrap: wrap;
    > div:not(:nth-child(2n)) {
      margin-right: ${spacing.base};
      ${Media.phone`
        margin-right: 0;
      `}
    }
  `}
`
export const ListItem = styled.div`
  ${({ theme: { color, typography, spacing, Hack } }) => css`
    padding: ${spacing.large} ${spacing.large} 5px ${spacing.base};
    display: flex;
    align-items: flex-start;
    width: 590px;
    min-height: 140px;
    box-shadow: 0px 0px 12px 0px rgba(226, 231, 236, 1);
    background-color: white;
    margin-bottom: ${spacing.base};

    :hover {
      box-shadow: rgba(37, 42, 58, 0.12) 0px 0px 20px 0px;
    }
    h3 {
      font-size: 20px;
    }
    p {
      margin-top: 10px;
      color: ${color.text};
      line-height: 20px;
      font-size: ${typography.textThin};
    }
    svg {
      display: block;
      width: 48px;
      height: 48px;
    }

    ${Hack.desktopEn`
      padding-bottom: ${spacing.large};
      h3 {
        font-size: ${typography.h3};
        line-height:1.2;
      }
      p {
        line-height: 24px;
      }
    `}

    ${Media.phone`
      padding: .3rem .2rem .2rem .3rem;
      width: 100%;
      min-height: 1.4rem;
      margin-bottom: .3rem;
      box-shadow: 0px 0px 6px 0px rgba(226, 231, 236, 1);
      svg{
        width: .6rem;
        height: .6rem;
      }
      h3 {
        font-size: .3rem;
        line-height: .4rem;
      }
      p{
        margin-top:.1rem;
        font-size: .2rem;
        line-height: .3rem;
      }
    `}
  `}
`

export const SvgContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-right: ${spacing.base};
  `}
`

export const TheorySection = styled(Wrapper)`
  padding: 80px 0 106px 0;
`
