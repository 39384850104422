import React from 'react'
import intl from 'react-intl-universal'
import { graphql, StaticQuery } from 'gatsby'
import { Navigation } from '@raysync/common/services'
import { Billboard, Button } from '@raysync/common/components'
import { BannerContainer, BannerWrap, InviteTextContainer, ButtonContainer } from './atoms'

class InviteBanner extends React.PureComponent {
  render() {
    const { type, variant = 'normal', hasDesc = true, onApplyClick } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            InviteBg: file(relativePath: { eq: "index/bg2.png" }) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={({ InviteBg }) => (
          <BannerContainer>
            <BannerWrap variant={variant}>
              <InviteTextContainer>
                <span>{intl.get(`${type}.invite.title`)}</span>
                {hasDesc ? <p>{intl.get(`${type}.invite.desc`)}</p> : null}
              </InviteTextContainer>
              <ButtonContainer>
                <Button
                  rel='nofollow'
                  id='GetFreeTrial'
                  size='large'
                  onClick={() => {
                    if (onApplyClick) {
                      onApplyClick()
                    } else {
                      Navigation.toApply({ type: 'apply', pageType: type })
                    }
                  }}
                  color='white'
                >
                  {intl.get(`${type}.invite.button1`)}
                </Button>
                {variant === 'inquire' && (
                  <Button
                    size='large'
                    onClick={() => Navigation.toApply({ type: 'prompt', pageType: type })}
                    variant='outlined'
                    color='white'
                  >
                    {intl.get(`${type}.invite.button2`)}
                  </Button>
                )}
              </ButtonContainer>
            </BannerWrap>
            <Billboard fluid={InviteBg.childImageSharp.fluid} alt='Invite background image' />
          </BannerContainer>
        )}
      />
    )
  }
}

export default InviteBanner
