import React from 'react'

const SvgIndustry1 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry1_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry1_svg__b'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry1_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry1_svg__e'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry1_svg__f'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <filter
        id='industry1_svg__c'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={14}
        height={24}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry1_svg__g'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={12}
        height={18}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path d='M2 10a2 2 0 012-2h16a2 2 0 012 2v14H2z' fill='url(#industry1_svg__a)' />
    <rect y={23} width={24} height={1} rx={0.5} fill='url(#industry1_svg__b)' />
    <g transform='matrix(-1 0 0 1 38 0)' filter='url(#industry1_svg__c)'>
      <path d='M19 2a2 2 0 012-2h10a2 2 0 012 2v22H19z' fill='url(#industry1_svg__d)' />
      <path d='M19.5 2.5a2 2 0 012-2h9a2 2 0 012 2v21s0 0 0 0h-13s0 0 0 0z' stroke='url(#industry1_svg__e)' />
    </g>
    <g>
      <rect x={10} y={18} width={4} height={6} rx={0} fill='#FFF' />
      <rect x={10} y={18} width={4} height={6} rx={0} fill='url(#industry1_svg__f)' />
    </g>
    <g filter='url(#industry1_svg__g)'>
      <rect x={8} y={2} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={8} y={6} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={8} y={10} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={8} y={14} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={14} y={2} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={14} y={6} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={14} y={10} width={2} height={2} rx={0} fill='#FFF' />
      <rect x={14} y={14} width={2} height={2} rx={0} fill='#FFF' />
    </g>
  </svg>
)

export default SvgIndustry1
