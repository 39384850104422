import React from 'react'

const SvgWeixin = props => (
  <svg id='weixin_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 25 20' xmlSpace='preserve' {...props}>
    <style>{'.weixin_svg__st0{fill:#8c9aae}'}</style>
    <path
      className='weixin_svg__st0'
      d='M8.99.03C4.32.03.5 3.27.5 7.4c0 2.38 1.27 4.33 3.4 5.85l-.85 2.61 2.97-1.52c1.06.22 1.92.44 2.98.44.27 0 .53-.01.79-.03-.17-.58-.26-1.19-.26-1.82 0-3.78 3.19-6.86 7.22-6.86.28 0 .55.02.82.05-.74-3.5-4.4-6.09-8.58-6.09zM6.24 5.88c-.64 0-1.28-.44-1.28-1.08 0-.65.64-1.08 1.28-1.08S7.3 4.15 7.3 4.8c0 .64-.43 1.08-1.06 1.08zm5.94 0c-.64 0-1.28-.44-1.28-1.08 0-.65.64-1.08 1.28-1.08s1.06.43 1.06 1.08c0 .64-.42 1.08-1.06 1.08zm0 0'
    />
    <path
      className='weixin_svg__st0'
      d='M24.5 12.82c0-3.46-3.4-6.29-7.22-6.29-4.04 0-7.22 2.82-7.22 6.29s3.18 6.29 7.22 6.29c.85 0 1.7-.22 2.55-.43l2.33 1.3-.64-2.17c1.71-1.31 2.98-3.04 2.98-4.99zm-9.56-1.09c-.42 0-.85-.43-.85-.87 0-.43.43-.87.85-.87.64 0 1.06.44 1.06.87.01.44-.42.87-1.06.87zm4.67 0c-.42 0-.84-.43-.84-.87 0-.43.42-.87.84-.87.64 0 1.06.44 1.06.87.01.44-.42.87-1.06.87zm0 0'
    />
  </svg>
)

export default SvgWeixin
