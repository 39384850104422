import React from 'react'
import RcSelect, { Option as RcOption } from 'rc-select'
export const Option = RcOption

class Select extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props
    return <RcSelect {...rest}>{children}</RcSelect>
  }
}

export default Select
