import React from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Row, Column } from '../grid'
import { LocaleService } from '../../services'
import { Title, NavAnchor, NavLink, PostCardsContainer, DeusColumn } from './atoms'
import Email from './email'
import NewMedia from '../../theme/new-media'
import LanguageBar from './language-bar'
import { useMedia } from '../../hooks'
import SecurityImg1 from './images/security1.png'
import SecurityImg2 from './images/security2.png'

export const EnNavLink = styled(NavLink)`
  color: #999999;
`
export const EnNavAnchor = styled(NavAnchor)`
  color: #999999;
  font-size: 14px;
  ${NewMedia.phone`
      font-size: .24rem;
    `}
`
const ImgBox = styled.div`
  display: flex;
  transform: translateY(-10px);
  img {
    width: 80px;
    height: 100px;
    &:last-child {
      margin-left: 20px;
    }
  }
`
export const EnNavAnchorPlain = EnNavAnchor.withComponent('p')

const currentPackageName = process.env.GATSBY_PACKAGE_NAME
const isInMain = currentPackageName === 'main'
const isInNews = currentPackageName === 'news'
const isInSupport = currentPackageName === 'support'
// const Mark = styled.span`
//   color: ${p => p.theme.color.secondary};
// `
const locale = intl.getInitOptions().currentLocale
const EnPostCards = () => {
  let isMedia = useMedia()
  return (
    <PostCardsContainer>
      <Row>
        {/* products */}
        <DeusColumn lg='2.50' xs='6'>
          <Title>{intl.get('app.footer.product.title')}</Title>
          <EnNavLink isInternal={isInMain} to='/enterprise/'>
            {intl.get('enterprise.link')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/smb/'>
            {intl.get('app.footer.product7')}
          </EnNavLink>
          <Title top={28}>{intl.get('app.header.resources')}</Title>
          <EnNavLink isInternal={isInSupport} to='/support/document/index/'>
            {intl.get('app.header.support')}
          </EnNavLink>
          <EnNavLink isInternal={isInSupport} to='/support/document/'>
            {intl.get('app.header.resources.white-paper')}
          </EnNavLink>
          <EnNavLink isInternal={isInSupport} to='/support/video/'>
            {intl.get('app.header.resources.video-tutorial')}
          </EnNavLink>
          <EnNavLink isInternal={isInSupport} to='/support/faq/'>
            {intl.get('app.header.resources.faq')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/calculator/'>
            {intl.get('app.footer.calculator')}
          </EnNavLink>
        </DeusColumn>
        {/* Solutions */}
        <DeusColumn lg='2.96' xs='6'>
          <Title>{intl.get('app.header.solution')}</Title>
          <EnNavLink isInternal={isInMain} to='/solutions/petabyte-scale-data-transfer/'>
            {intl.get('app.footer.solution9')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/massive-file-transfer/'>
            {intl.get('app.footer.solution19')}
          </EnNavLink>

          <EnNavLink isInternal={isInMain} to='/solutions/data-migration-data-sync-and-data-backup/'>
            {intl.get('app.footer.solution12')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/p2p-large-scale-file-transfer/'>
            {intl.get('app.footer.solution17')}
          </EnNavLink>

          <EnNavLink isInternal={isInMain} to='/solutions/file-management/'>
            {intl.get('app.footer.solution14')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/data-collaboration-and-secure-file-sharing/'>
            {intl.get('app.footer.solution13')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/sdk-and-api-intergration/'>
            {intl.get('app.footer.solution20')}
          </EnNavLink>
        </DeusColumn>
        {/* Industry Solutions */}
        <DeusColumn lg='3.5' xs='6'>
          <Title>{intl.get('app.header.industries')}</Title>

          <EnNavLink isInternal={isInMain} to='/solutions/industry-financing/'>
            {intl.get('app.footer.solution3')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-gene/'>
            {intl.get('app.footer.solution4')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-car/'>
            {intl.get('app.footer.solution5')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-manufacturing/'>
            {intl.get('app.footer.solution6')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-internet/'>
            {intl.get('app.footer.solution7')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-media/'>
            {intl.get('app.footer.solution.media')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-marine/'>
            {intl.get('app.footer.solution.marine')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/solutions/industry-gaming/'>
            {intl.get('app.footer.solution.gaming')}
          </EnNavLink>
        </DeusColumn>
        {/* Fast-entry */}
        <DeusColumn lg='3.04' xs='6'>
          <Title>{intl.get('app.header.company')}</Title>
          <EnNavLink isInternal={isInMain} to='/pricing/'>
            {intl.get('app.header.pricing')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/example/'>
            {intl.get('app.footer.entry3')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/about-us'>
            {intl.get('app.header.company.about')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/partners/'>
            {intl.get('app.header.agent')}
          </EnNavLink>
          <EnNavLink isInternal={isInNews} to='/news/'>
            {intl.get('app.footer.entry2')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/privacy-policy/'>
            {intl.get('app.footer.entry6')}
          </EnNavLink>
          <EnNavLink isInternal={isInMain} to='/terms-of-service'>
            {'Terms Of Service'}
          </EnNavLink>
        </DeusColumn>
      </Row>
      <Row style={{ paddingTop: '30px' }}>
        {/* Net-products */}
        {!LocaleService.isForeign && (
          <DeusColumn lg='2.5' xs='6'>
            <Title>{intl.get('app.footer.net.product.title')}</Title>
            <EnNavLink isInternal={isInMain} to='/network/point/'>
              {intl.get('app.footer.net.product1')}
            </EnNavLink>
            <EnNavLink isInternal={isInMain} to='/network/ipv6/'>
              {intl.get('app.footer.net.product2')}
            </EnNavLink>
            <EnNavLink isInternal={isInMain} to='/network/sd-wan/'>
              {intl.get('app.footer.net.product3')}
            </EnNavLink>
          </DeusColumn>
        )}

        {/* Support & Serivce */}
        <DeusColumn lg='2.5' xs='6'>
          <ImgBox>
            <img src={SecurityImg1} alt='DPP Committed to Security mark for Broadcast' />
            <img src={SecurityImg2} alt='DPP Committed to Security mark for Production' />
          </ImgBox>
        </DeusColumn>
        {/* Concact-us */}
        <Column lg='2.96' xs='8'>
          <Title locale={locale} phoneFontSize={24}>
            {intl.get('app.footer.contact')}
          </Title>
          {!LocaleService.isForeign && (
            <EnNavAnchor rel='noopener noreferrer dofollow' href={`tel:${intl.get('app.contact.phone.value')}`}>
              {`${intl.get('app.footer.contact.phone')}`}
            </EnNavAnchor>
          )}

          <EnNavAnchorPlain rel='noopener noreferrer dofollow' locale={locale} phoneFontSize={20}>
            {`${intl.get('app.footer.contact.qq')}: ${intl.get('app.footer.contact.qqnumber')}`}
          </EnNavAnchorPlain>
          <EnNavAnchor
            rel='noopener noreferrer dofollow'
            href={`mailto:${intl.get('app.contact.email.value')}`}
            locale={locale}
            phoneFontSize={20}
          >
            {`${intl.get('app.footer.contact.email')}: ${intl.get('app.contact.email.value')}`}
          </EnNavAnchor>
        </Column>

        <DeusColumn lg='3.25' xs='4'>
          <Title>{intl.get('app.footer.subscribe')}</Title>
          <Email buttonStyle={{ width: 100, borderRadius: 6 }} />
        </DeusColumn>
        <Column lg='3.04' xs='4' style={{ paddingLeft: isMedia ? '0' : '30px' }}>
          <Title>{intl.get('app.footer.select-language')}</Title>
          <LanguageBar />
        </Column>
      </Row>
    </PostCardsContainer>
  )
}

export default EnPostCards
