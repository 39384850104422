import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './index'

let mousePosition
let mousePositionEventBinded
const mouseListener = event => {
  mousePosition = {
    x: event.pageX,
    y: event.pageY,
  }
}

class ModalPortal extends React.Component {
  container = null
  modalRef = null
  componentDidMount() {
    if (mousePositionEventBinded) {
      return
    }
    document.documentElement.addEventListener('click', mouseListener)
    mousePositionEventBinded = true
  }
  shouldComponentUpdate({ visible }) {
    return !!(this.props.visible || visible)
  }
  componentWillUnmount() {
    document.documentElement.removeEventListener('click', mouseListener)
    if (this.container) {
      this.container.parentNode.removeChild(this.container)
      this.container = null
    }
  }
  render() {
    // NOTE: can not create container on server
    if (typeof window === 'undefined') {
      return null
    }
    const { visible } = this.props
    if (visible || this.modalRef) {
      return ReactDOM.createPortal(
        React.createElement(Modal, { ...this.props, ref: this.saveRef, origin: mousePosition }),
        this.getContainer(),
      )
    }
    return null
  }
  getContainer() {
    if (!this.container) {
      const container = document.createElement('div')
      document.body.appendChild(container)
      this.container = container
    }
    return this.container
  }
  saveRef = instance => {
    this.modalRef = instance
  }
}

ModalPortal.propTypes = Modal.propTypes

export default ModalPortal
