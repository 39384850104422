import React from 'react'

const SvgScene3 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='scene3_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene3_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene3_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='scene3_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene3_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={20}
        height={20}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <circle cx={10} cy={14} fill='url(#scene3_svg__a)' r={10} />
    <g filter='url(#scene3_svg__b)'>
      <circle cx={13} cy={11} fill='url(#scene3_svg__c)' r={11} />
      <circle cx={13} cy={11} stroke='url(#scene3_svg__d)' r={10.5} />
    </g>
    <g filter='url(#scene3_svg__e)'>
      <path
        d='M14.797 2.57q-.68.178-.68 1.104 0 .414.133 1.144.17.954 1.09 1.503.173.102.178.111l-.003.005q.011.086-.014.245-.02.134-.048.19-.077.027-.402.05-1.126.078-1.726.598-.523.455-.591 1.24-.032.368.058 1.1.083.673.044.915-.037.226-.22.329-.208.116-.533.003-.296-.102-.953-.527-.643-.416-.977-.549-.766-.306-1.37.025-.625.34-.865 1.134-.112.373-.18 1.14-.073.824-.17.912-.346.315-1.488-.142h-.001q-.134-.054-.279-.054h-.002q-.112 0-.22.033-.106.033-.2.096-.092.063-.162.15-.07.088-.112.192-.054.134-.054.279v.009q.002.15.06.286.06.137.166.242.107.104.246.16 2.015.805 3.058-.144.38-.346.528-.976.07-.296.124-.911.082-.93.25-1.075.042.008.115.037.198.079.719.416.809.523 1.275.685.96.333 1.76-.113.812-.456.965-1.395.074-.454-.035-1.34-.111-.903.028-1.024.22-.192.846-.235.701-.049 1.049-.245.644-.363.78-1.259.12-.776-.195-1.279-.197-.315-.68-.603-.333-.198-.383-.481-.103-.569-.108-.85l.017-.028q.103-.175.103-.378v-.01q-.002-.092-.025-.181-.016-.06-.041-.116-.025-.056-.06-.108-.034-.051-.076-.096-.042-.045-.09-.083-.05-.038-.104-.067-.054-.03-.113-.05-.058-.019-.119-.03-.06-.01-.122-.01h-.003q-.096.001-.188.026zM16.609 13.5q.932-.492 3.028 1.594.106.105.243.161.138.057.286.057h.002q.15 0 .287-.058.138-.057.243-.163.105-.105.162-.243.056-.137.056-.286v-.002q0-.149-.057-.287-.058-.137-.164-.243-2.878-2.863-4.785-1.858-.9.471-1.2 1.188-.181.43-.153 1.13.011.268-.003.309-.035.094-.222.264-.614.56-.851 1.31-.454 1.417.987 2.89.104.107.242.166.137.058.286.06H15.032q.14-.006.268-.06.129-.056.229-.154.108-.106.167-.245.058-.14.058-.291v-.03q-.006-.14-.061-.267-.055-.128-.152-.227-.81-.828-.63-1.389.116-.367.431-.654.457-.416.618-.853.12-.322.096-.89-.015-.366.036-.488.092-.22.517-.442z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M4.937 7.593Q4.25 9.219 4.25 11q0 1.78.687 3.407.664 1.57 1.875 2.781 1.21 1.21 2.78 1.875 1.627.687 3.408.687 1.78 0 3.407-.688 1.57-.663 2.781-1.874 1.21-1.21 1.875-2.78.687-1.627.687-3.408 0-1.78-.688-3.407-.663-1.57-1.874-2.781-1.21-1.21-2.78-1.875Q14.78 2.25 13 2.25q-1.78 0-3.407.687-1.57.664-2.781 1.875-1.21 1.21-1.875 2.78zm2.935 8.535Q5.75 14.005 5.75 11t2.122-5.128Q9.995 3.75 13 3.75t5.128 2.122Q20.25 7.995 20.25 11t-2.122 5.128Q16.005 18.25 13 18.25t-5.128-2.122z'
        fillRule='evenodd'
        fill='#FFF'
      />
    </g>
  </svg>
)

export default SvgScene3
