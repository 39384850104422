import styled, { keyframes } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { duration } from '../../theme/transition'

const moveIn = keyframes`
  0% {
    top: -6em;
  }
  100% {
    top: 10%;
  }
`
const moveOut = keyframes`
  0% {
    top: 10%;
  }
  100% {
    top: -6em;
  }
`
export const ToastWrap = styled.div`
  position: fixed;
  z-index: 5000;
  top: -6em;
  left: 50%;
  height: 2.58em;
  white-space: nowrap;
  padding: 0 26px;
  line-height: 2.58em;
  touch-action: none;
  border-radius: 5px;
  transform: translateX(-50%);
  font-size: 14px;
  color: #25252a;
  background-color: #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  &.entering {
    animation: ${moveIn} ${duration.standard}ms;
  }
  &.exiting {
    animation: ${moveOut} ${duration.standard}ms;
  }
  svg {
    width: 16px;
    height: 16px;
    margin: -2px 9px 0 0;
  }
  ${Media.phone`
    font-size: .24rem;
    padding: 0 .12rem;
    svg {
      width: 12px;
      height: 12px;
    }
  `}
`
