import React from 'react'

const SvgLogo = props => (
  <svg id='logo_svg___\u56FE\u5C42_2' data-name='\u56FE\u5C42 2' viewBox='0 0 488.04 153.25' {...props}>
    <defs>
      <style>{'.logo_svg__cls-2{stroke-width:0;fill:#fff}'}</style>
    </defs>
    <g id='logo_svg___\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
      <path
        d='M97.36 0C79.53 0 61.77 3.44 46.07 7.27l11.59 8.33c-15.62 3-31.63 2.07-40.4.13V49.9c11.9 3.15 51 .83 68.25-6.18l-5.38-6.5c5.72-.58 11.48-.83 17.23-.76a183.81 183.81 0 0151.29 7.09V7.27C133.83 3.61 115.19 0 97.36 0z'
        strokeWidth={0}
        fill='#f05327'
      />
      <path
        className='logo_svg__cls-2'
        d='M151.79 132.48c-9.77.94-19.43 2.83-28.84 5.64v10.07c-13.21 3-34.63 5.92-51.88 4.83-17.8-1.12-40.77-7.57-50.7-11.33v-36.1c14.05 7.46 40.65 12.52 62.56 12.52 12.08-.07 24.12-1.22 36-3.42l-1.74-9c11.23-3.84 18.21-7.1 34.55-8.73l.05 35.52zM129.36 50.45c-12.47 0-30.68 2.86-35.82 4.31l6 9.55c-13.4 3.35-26.78 3.33-36.6 3.33-23 0-42.62-4.87-62.94-12.6v36.19c15.61 5.31 35.7 9.46 54.72 9.46s39.89-2.58 57.74-8.76l-1.67-4.41c6.07-.8 12.18-1.19 18.3-1.18 12.78 0 23.55.45 36.92 3.63V53.69c-7.43-1.62-24.18-3.24-36.65-3.24zM258.67 24.78h32.89l-1.99 10.48h-34.33l-7.78 40.93h17.16l6.15-32.35h17.17l-6.16 32.35h17.16l6.16-32.35h17.16l-6.16 32.35h17.17l7.79-40.93h-34.33l2-10.48h32.89l1.63-8.58H260.3l-1.63 8.58zM315.18 81h-68.65l-9.25 48.63-1.63 8.58h65.79l1.63-8.58h-14.3l3-15.73h17.18l-4.62 24.31h17.16L332.34 81h-17.16zm-36 8.58l-3 15.73h-17.11l3-15.73h17.11zm-7.62 40h-17.12l3-15.73h17.16l-3.04 15.73zm21.79-24.32l3-15.73h17.2l-3 15.73h-17.2zM226.32 41.95h21.53l1.63-8.59h-21.52l3.26-17.16h-14.3l-4.9 25.75h14.3z'
      />
      <path
        className='logo_svg__cls-2'
        d='M283.14 46.5h-8.58l-2.18 11.44h8.58l2.18-11.44zM269.01 75.69h8.58l2.17-11.44h-8.58l-2.17 11.44zM317.46 46.5h-8.58l-2.17 11.44h8.58l2.17-11.44zM303.33 75.69h8.58l2.18-11.44h-8.58l-2.18 11.44zM228.07 92.08h9.95l1.64-8.58h-9.96l5.27-27.65h9.95l1.63-8.58H210.8l-1.63 8.58h8.63l-5.26 27.65h-8.64l-1.63 8.58h8.64l-8.78 46.17h27.11l1.63-8.58h-9.95l7.15-37.59zM397.8 16.15h-22.65l-12.5 19.68h22.64l12.51-19.68zM394.51 45.41h-35.97l-2.18 11.45h15.8l-20.28 81.39H468.17l1.63-8.58h-96.28l20.99-84.26z'
      />
      <path
        className='logo_svg__cls-2'
        d='M486.41 34.74l1.63-8.58h-32.9l1.91-10.01h-17.16l-1.91 10.01h-32.89l-1.63 8.58h32.89l-1.26 6.62h-33.34l-8.17 42.91h8.62l-16.61 42.01h15.81l19.81-50.09h-11.79l5-26.24h19.04l-14.53 76.33h17.16l14.53-76.33h19.04l-4.99 26.24h-11.41l.37 50.09h15.81l-.32-42.01h8.31l6.53-34.32 1.63-8.59h-33.34l1.26-6.62h32.9z'
      />
    </g>
  </svg>
)

export default SvgLogo
