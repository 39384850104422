import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import { Input, Button } from '../../components'
import { SubscribeForm, SubscribedText } from './atoms'
import API from '@raysync/common/services/api'
const Email = ({ buttonStyle }) => {
  const [subscribeStatus, setSubscribeStatus] = useState('0')

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem('subscribeStatus')) {
      setSubscribeStatus(localStorage.getItem('subscribeStatus'))
    }
  }, [])

  const handleSubmit = (values, { setSubmitting }) => {
    API.email
      .subscribe({ email: values.email, areaType: 2 })
      .then(res => {
        if (res.data.code === 0) {
          localStorage.setItem('subscribeStatus', '1')
          setSubscribeStatus('1')
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (subscribeStatus !== '1') {
    return (
      <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
        {({ values, handleSubmit, isSubmitting, handleChange }) => (
          <SubscribeForm onSubmit={handleSubmit}>
            <Input
              value={values.email}
              name='email'
              type='email'
              maxLength={60}
              onChange={handleChange}
              required
              placeholder='info@example.com'
            ></Input>
            <Button type='submit' loading={isSubmitting} style={{ ...buttonStyle }}>
              {intl.get('app.footer.subscribe.button')}
            </Button>
          </SubscribeForm>
        )}
      </Formik>
    )
  } else {
    return <SubscribedText>{intl.get('app.footer.subscribe.success')}</SubscribedText>
  }
}

export default Email
