import styled, { css } from 'styled-components'
import { transition, Media } from '../../theme'
import Wrapper from '../wrapper'
import NewMedia from '../../theme/new-media'

export const SwiperContainer = styled.div`
  position: relative;
  ${Media.phone`
    width: 100%;
  `}
`

export const SwipeNavWrap = styled(Wrapper)`
  width: 100%;
  margin-top: -4px;
  display: flex;
  gap: 25px;
  /* bottom: 120px; */
  justify-content: center;
`

export const SwipeNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 262px;
  min-height: 102px;
  border-radius: 10px;
  opacity: 1;
  background: rgba(135, 150, 170, 0.1);
  border: 1px solid rgba(135, 150, 170, 0.2);
  ${props =>
    props.active &&
    css`
      border-color: rgba(253, 92, 31, 1);
      opacity: 1;
    `}
`

export const EnSwipeNavWrap = styled(SwipeNavWrap)`
  position: absolute;
  max-width: 100%;
  bottom: 70px;
  margin-bottom: 10px;
  ${Media.phone`
    bottom: 3rem;
  `}

  ${NewMedia.phone`
    margin-bottom: 0px;
    span{
      height:.2rem;
      border-radius:10px;
      :not(:first-child){
        margin-left: .6rem;
      }
    }
    
    bottom:0.6rem;
  `}
`
export const EnSwipeNav = styled.span`
  ${({ theme: { color, spacing } }) => css`
    width: ${p => (p.active ? '40px' : '14px')};
    cursor: pointer;
    display: block;
    height: 14px;
    border-radius: 7px;
    transition: all 0.5 linear;
    background-color: ${p => (p.active ? (p.activedColor ? 'white' : color.secondary) : 'rgba(193,199,214,0.4)')};
    & + & {
      /* margin-left: ${spacing.small}; */
      margin-left: 27px;
    }
  `}

  ${NewMedia.phone`
    width: ${p => (p.active ? '.6rem' : '.2rem')};
    height:.2rem;
    border-radius: ${p => (p.active ? '.1rem' : '50%')};

  `}
`
export const TouchBarNavWrap = styled.div`
  ${({ theme: { color } }) => css`
    width: 100%;
    height: 0.08rem;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    ::before {
      content: '';
      width: 0.38rem;
      background-color: ${color.secondary};
      border-radius: 4px;
      transform: ${p => `translateX(${(p.activedIndex + 1) * 0.38}rem)`};
      transition: ${transition('transform')};
    }
  `}
`
export const DotNavWrap = styled.div`
  width: 100%;
  margin-top: 0.3rem;
  display: flex;
  justify-content: center;
`
export const DotNav = styled.span`
  cursor: pointer;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: all 0.5 linear;
  background-color: ${p => (p.active ? p.theme.color.secondary : '#E1E1E1')};
  & + & {
    margin-left: 8px;
  }
`

export const TouchBarNav = styled.span`
  width: 0.38rem;
  background-color: #e1e5eb;
  transition: ${transition('background-color')};
`

export const ArrowSwiperWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
export const ArrowButton = styled.div`
  min-width: 0.4rem;
  height: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem;
  background-color: ${p => (p.active ? p.theme.color.secondary : '#E1E1E1')};
  transform: rotate(${p => (p.isLeft ? '-180deg' : '0deg')});
`
