import React, { useRef, useState } from 'react'
import intl from 'react-intl-universal'
import { Formik } from 'formik'
import { StaticQuery, graphql } from 'gatsby'
import { useMedia } from '@raysync/common/hooks'
import {
  Billboard,
  LgWrapper,
  Button,
  ModalPortal,
  CloseIcon,
  H2,
  Toast,
  Select,
  Option,
} from '@raysync/common/components'
import { Container, BannerContainer, ModalWrap, ButtonGroup } from './atoms'
import PlaySvg from '@raysync/common/components/svg/home/play'
import Check from '@raysync/common/components/svg/check'
import {
  Input,
  ButtonMask,
  FormItem,
  FormItemContainer,
  Containervideobanner,
  ButtonContainer,
  ApplyText,
  SubText,
} from '@raysync/main/src/style/apply.atoms'
import { LOCALES, LocaleService, LocationService } from '@raysync/common/services'
import API from '@raysync/common/services/api'

const bannerItems = [
  '传输速度比FTP/HTTP提升100倍',
  '支持亿级文件量、PB级大文件高速传输',
  '跨区域、跨国大数据迁移/归档',
  '支持本地及主流公有云部署',
]
const PHONE_REGEX = /^1\d{10}$/
const AreaMap = LocaleService.isForeign ? 2 : 1
const VideoBanner = ({}) => {
  const isMobile = useMedia()
  const videoRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [userType, setUserType] = useState('enterprise')
  const play = () => {
    setTimeout(() => {
      videoRef.current && videoRef.current.play()
    }, 200)
  }
  const pause = () => {
    videoRef.current && videoRef.current.pause()
  }
  const userTypeMap = Object.freeze({
    enterprise: '企业用户',
    college: '高校组织',
    social: '社会组织',
    personal: '个人用户',
    confidential: '保密用户',
  })
  const pageTypeMap = Object.freeze({
    product1: '软件-大文件传输系统',
    product2: '软件-SDK传输应用集成',
    product3: '软件-镭速Proxy代理加速',
    product4: '软件-镭速FTP加速 ',
    product5: '软件-RaySSH加速',
    solution1: '软件-海量数据传输',
    solution2: '软件-跨国数据传输',
    solution3: '软件-超大文件传输',
    solution4: '软件-数据同步',
    solution5: '软件-数据迁移',
    solution6: '软件-数据分发',
    solution7: '软件-数据协作',
    ad: '软件-广告媒体行业',
    gene: '软件-基因行业',
    car: '软件-汽车行业',
    moive: '软件-影视行业',
    internet: '软件-IT互联网行业',
    government: '软件-政府机构',
    financing: '软件-金融行业',
    manufacturing: '软件-生产制造业',
    network1: '软件-IPv6',
    network2: '软件-SD-WAN',
    network3: '软件-点对点专线',
    network4: '软件-最后一公里',
    network5: '软件-企业互联专线',
    network6: '软件-弹性混合云',
    wechat: '微信公众号',
    gy: '贵阳展会',
    bj: '北京展会',
  })
  const userTypeInfo = {
    enterprise: { placeholder: intl.get('apply.placeholder.userType2') },
    college: { placeholder: intl.get('apply.placeholder.userType3') },
    social: { placeholder: intl.get('apply.placeholder.userType4') },
    personal: { placeholder: intl.get('apply.placeholder.userType4') },
    confidential: { placeholder: intl.get('apply.placeholder.userType4') },
  }[userType]
  // 添加初始值
  const initialValues = {
    userType: 'enterprise',
    company: '',
    phone: '',
    concation: '',
    transPath: [],
  }
  // 3. 添加 handleSubmit 函数
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (LocaleService.currentLocale === LOCALES.ZH && !PHONE_REGEX.test(values.phone)) {
      Toast.error(intl.get('apply.placeholder.phone.error'))
      setSubmitting(false)
      return
    }
    const type = LocationService.getLocationSearchParams().get('type')
    setSubmitting(true)
    let data = {
      ...values,
      company: values.company,
      userType: userTypeMap[values.userType],
      transData: values.transDataAmount ? values.transDataAmount + values.transDataUnit : '',
      transFreque: values.transFreque ? intl.get('apply.placeholder.mbs.times', { times: values.transFreque }) : '',
      area: AreaMap,
      type: pageTypeMap[type] || type || '软件官网',
      transPath: values.transPath.join(','),
      applyType: 1,
    }

    API.apply
      .add(data)
      .then(response => {
        let data = response.data
        if (data.code === 0) {
          reportBingEvent()
          Toast.info(intl.get('apply.submit.success'))
          resetForm()
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const reportBingEvent = () => {
    if (typeof window !== 'undefined' && process.env.GATSBY_ENV !== 'development') {
      try {
        window._hmt.push(['_trackEvent', 'apply', 'click', LocaleService.currentLocale])
        window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      } catch (error) {
        console.log(error)
      }
    }
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { eq: "video.png" }) {
            ...fluidImage
          }
          mobileBanner: file(relativePath: { eq: "video_mobile.png" }) {
            ...fluidImage
          }
        }
      `}
      render={({ banner, mobileBanner }) => {
        return (
          <>
            <Container>
              <LgWrapper>
                <BannerContainer>
                  <h1>
                    企业大数据、海量
                    {isMobile && <br />}
                    文件<span>高速传输</span>系统
                  </h1>
                  <p>安全、稳定、又可靠的大文件传输方式</p>
                  <ul>
                    {bannerItems.map(item => (
                      <li key={item}>
                        {!isMobile && <Check />}
                        {item}
                      </li>
                    ))}
                  </ul>
                  <ButtonGroup>
                    {isMobile && (
                      <Button
                        rel='nofollow'
                        as='a'
                        id='RequestFreeTrial'
                        size='large'
                        target='_blank'
                        onClick={() => {
                          window.open('/apply', '_blank')
                        }}
                      >
                        申请免费试用
                      </Button>
                    )}
                    <Button
                      rel='nofollow'
                      id='RequestFreeTrial'
                      size='large'
                      color='white'
                      variant='outlined'
                      onClick={() => {
                        play()
                        setIsVisible(true)
                      }}
                    >
                      <PlaySvg />
                      观看视频
                    </Button>
                  </ButtonGroup>
                </BannerContainer>
              </LgWrapper>

              {!isMobile && (
                <Containervideobanner>
                  <H2>申请免费试用</H2>
                  <ApplyText style={{ color: '#FFFFFF' }}>
                    仅面向<span>企业、社会和高校组织</span>用户提供服务
                  </ApplyText>
                  <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                    {({
                      values,
                      errors,
                      handleChange,
                      submitForm,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit} style={{ marginTop: '-10px' }}>
                          <>
                            <FormItem
                              key={'userType'}
                              style={{ marginBottom: '10px', backgroundColor: '#FFFFFF', borderRadius: '4px' }}
                            >
                              <Select
                                name='userType'
                                onChange={value => {
                                  setFieldValue('userType', value)
                                  setUserType(value)
                                }}
                                onBlur={handleBlur}
                                value={values.userType}
                              >
                                <Option value='enterprise' label={intl.get('apply.userType.option2')}>
                                  {intl.get('apply.userType.option2')}
                                </Option>
                                <Option value='college' label={intl.get('apply.userType.option3')}>
                                  {intl.get('apply.userType.option3')}
                                </Option>
                                <Option value='social' label={intl.get('apply.userType.option4')}>
                                  {intl.get('apply.userType.option4')}
                                </Option>
                                <Option value='confidential' label={intl.get('apply.userType.option4')}>
                                  保密用户
                                </Option>
                              </Select>
                            </FormItem>
                            {['enterprise', 'college', 'social'].includes(values.userType) && (
                              <FormItem
                                required
                                key={'personal'}
                                style={{ marginBottom: '10px', backgroundColor: '#FFFFFF', borderRadius: '4px' }}
                              >
                                <Input
                                  placeholder={userTypeInfo.placeholder}
                                  maxLength='50'
                                  name='company'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.company}
                                />
                              </FormItem>
                            )}

                            <FormItemContainer
                              required
                              style={{ marginBottom: '10px', backgroundColor: '#FFFFFF', borderRadius: '4px' }}
                            >
                              <Input
                                placeholder={intl.get('apply.placeholder.phone')}
                                name='phone'
                                maxLength='20'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                isError={errors.phone}
                              />
                            </FormItemContainer>
                            <FormItem
                              required
                              key={'concation'}
                              style={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}
                            >
                              <Input
                                placeholder={intl.get('apply.placeholder.concation')}
                                name='concation'
                                maxLength='50'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.concation}
                              />
                            </FormItem>
                          </>
                          <SubText style={{ marginTop: '0px' }}>
                            请确保您填写的联系方式无误，以便我们第一时间联系到您
                          </SubText>

                          <ButtonContainer>
                            {/* 提交按钮 */}
                            <Button
                              type='submit'
                              fullWidth
                              id={process.env.GATSBY_ENV !== 'development' ? 'apply' : ''}
                              size='large'
                              loading={isSubmitting}
                            >
                              {intl.get('apply.submit')}
                            </Button>
                          </ButtonContainer>
                        </form>
                      )
                    }}
                  </Formik>
                </Containervideobanner>
              )}

              <Billboard fluid={isMobile ? mobileBanner.childImageSharp.fluid : banner.childImageSharp.fluid} />
            </Container>
            {isVisible && (
              <ModalPortal
                hideHeader
                visible={isVisible}
                spotlightStyle={{ padding: 0 }}
                contentStyle={{ padding: 0, borderRadius: 0, boxShadow: 'unset', minWidth: 'unset' }}
                onClose={() => {
                  pause()
                  setIsVisible(false)
                }}
              >
                <ModalWrap>
                  <CloseIcon
                    onClick={() => {
                      pause()
                      setIsVisible(false)
                    }}
                  />
                  <video controls preload='none' disablePictureInPicture controlsList='nodownload' ref={videoRef}>
                    <source
                      type='video/mp4'
                      src='https://raysync.oss-cn-shenzhen.aliyuncs.com/setup/document/Raysync%20introduction%20V7.mp4'
                    />
                    <track kind='captions' />
                  </video>
                </ModalWrap>
              </ModalPortal>
            )}
          </>
        )
      }}
    />
  )
}
export default VideoBanner
