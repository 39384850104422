import React from 'react'

const SvgInstagram = props => (
  <svg viewBox='0 0 25 25' {...props}>
    <path
      d='M12.2 24.6c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.3 12-12 12zm7-14.9c.1-2.1-1.6-4-3.7-4.1h-.4c-1.1-.1-4.6-.1-5.7 0-2.1-.1-4 1.6-4.1 3.7v.4c-.1 1.1-.1 4.6 0 5.7-.1 2.1 1.6 4 3.7 4.1h.4c1.1.1 4.6.1 5.7 0 2.1.1 4-1.6 4.1-3.7V9.7zm-2.8 8.2c-2.7.4-5.4.4-8.2 0-.6-.3-1.1-.7-1.3-1.3-.3-1.4-.4-2.8-.3-4.1-.1-1.4 0-2.8.3-4.1.3-.6.7-1.1 1.3-1.3 2.7-.4 5.4-.4 8.2 0 .6.4 1.1.9 1.3 1.4.3 1.4.3 2.8.3 4.1.1 1.4 0 2.8-.3 4.1-.3.6-.8 1-1.3 1.2zM12.2 9c-1.9 0-3.6 1.6-3.6 3.6 0 1.9 1.6 3.6 3.6 3.6 1.9 0 3.6-1.6 3.6-3.6S14.2 9 12.2 9zm3.7-1c-.5 0-.8.4-.8.8 0 .5.4.8.8.8s.8-.4.8-.8c.1-.4-.3-.8-.8-.8zm-3.7 6.9c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#c1c7d6'
    />
  </svg>
)

export default SvgInstagram
