import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'

export const BannerContainer = styled.div`
  ${({ theme: { color } }) => css`
    position: relative;
    height: 400px;
    overflow: hidden;
    z-index: 0;
    background-color: ${color.panel};
    ${Media.phone`
      height: 3.9rem;
    `}
  `}
`
export const BannerTitle = styled.h1`
  max-width: 1200px;
  color: white;
  font-size: 40px;
  text-align: center;
  margin: 142px auto 20px;
  line-height: 1;
  ${Media.phone`
    max-width: 5.8rem;
    font-size: .48rem;
    line-height: .72rem;
    margin: .8rem auto .3rem;
  `}
`
export const BannerDesc = styled.p`
  color: white;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  ${Media.phone`
    font-size: .22rem;
  `}
`
