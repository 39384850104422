import React from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Media } from '@raysync/common/theme'

const BannerHotline = styled.span`
  color: #fd5c1f;
  font-weight: bold;
  margin-left: 20px;
  ${Media.phone`
      display: none;
    `}
`

const Hotline = () => <BannerHotline>热线咨询：{intl.get(`pricing.phone`)}</BannerHotline>

export default Hotline
