import styled, { css } from 'styled-components'
import { Wrapper } from '@raysync/common/components'
export const BannerContainer = styled.div`
  height: 200px;
`

export const BannerWrapNet = styled(Wrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => (p.variant === 'normal' ? '0 100px' : '0 80px')};
`

export const InviteTextContainer = styled.div`
  ${({ theme: { typography } }) => css`
    line-height: 1;
    color: white;
    h2 {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p {
      font-size: ${typography.h4};
    }
  `}
`

export const ButtonContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    button {
      :nth-child(2) {
        margin-left: ${spacing.base};
      }
    }
  `}
`
