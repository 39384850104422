import React from 'react'
import intl from 'react-intl-universal'
import { Navigation, LocaleService } from '@raysync/common/services'
import styled from 'styled-components'
import { Media } from '@raysync/common/theme'
import { BannerContainer, BannerTitle, BannerWrap, BannerDesc, BannerButton, BannerLeft, BannerRight } from '../atoms'
import Hotline from '@raysync/common/components/hotline'
const SolutionBannerContainer = styled(BannerContainer)`
  height: 400px;
  ${Media.phone`
    height: 10.4rem;
  `}
`
const SolutionTitle = styled(BannerTitle)`
  font-weight: 700;
  ${Media.phone`
    margin-top: 0.8rem;
  `}
`

const SolutionBanner = ({ type, pic, bg }) => (
  <SolutionBannerContainer
    className={`solution-banner ${type}-wrap`}
    style={
      bg ? { backgroundImage: `url(${bg})`, backgroundPosition: 'right top', backgroundRepeat: 'no-repeat' } : undefined
    }
  >
    <BannerWrap className='banner-wrap'>
      <BannerLeft>
        <SolutionTitle>{intl.get(`${type}.banner.title`)}</SolutionTitle>
        <BannerDesc className={`${LocaleService.currentLocale}-${type}`}>
          {intl.getHTML(`${type}.banner.desc`)}
        </BannerDesc>
        <BannerButton
          size='large'
          onClick={() => {
            Navigation.toApply({ type: 'solution', pageType: type })
          }}
        >
          {intl.get(`${type}.banner.button`)}
        </BannerButton>
        <Hotline />
      </BannerLeft>
      {pic ? <BannerRight className={`${type}-banner`}>{pic}</BannerRight> : null}
    </BannerWrap>
  </SolutionBannerContainer>
)
export default SolutionBanner
