import React from 'react'
import { H3 } from '@raysync/common/components'
import { TitleWrap } from './atoms'
import TitleIcon from './icon'

const CommonTitle = ({ children, top = 0, bottom = 0 }) => (
  <TitleWrap top={top} bottom={bottom}>
    <TitleIcon />
    <H3>{children}</H3>
  </TitleWrap>
)

export default CommonTitle
