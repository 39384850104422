import React from 'react'
import intl from 'react-intl-universal'
import { Navigation, LocaleService } from '@raysync/common/services'
import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Button, Modal } from '@raysync/common/components'
import { useMedia } from '@raysync/common/hooks'
import ApplyContent from '@raysync/main/src/views/partners/apply-content'
import { BannerContainer, BannerTitle, BannerWrap, BannerDesc, BannerButton, BannerLeft, BannerRight } from './atoms'
import collaborationIcon1 from './collaboration-icon1.png'
import collaborationIcon2 from './collaboration-icon2.png'
import collaborationIcon3 from './collaboration-icon3.png'
import collaborationIcon4 from './collaboration-icon4.png'
import collaborationIcon5 from './collaboration-icon5.png'
import ftpIcon from './ftp-icon.png'

// 导入所有需要的banner图片
import collaborationBanner from './home-collaboration1.png'
import ferryBanner from './home-ferry1.png'
import ftpBanner from './home-ftp1.png'

const SolutionBannerContainer = styled(BannerContainer)`
  height: 400px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    left: 1160px;
    top: 0px;
    width: 400px;
    height: 320px;
    object-fit: contain;
  }

  ${props =>
    props.type === 'collaboration' &&
    css`
      &.solution-banner.collaboration-wrap {
        background-position: right 0px top !important;
        background-size: 110% 110% !important;
        ${Media.phone`
        background-size: 26.35rem 12.9rem !important;
      background-position: calc(100% + 9.5rem) calc(100% + 0.4rem) !important;
      `}
      }
    `}

  ${Media.phone`
    height: 10.4rem;
    
    img {
      position: absolute;
      right: -1rem;
      bottom: 0;
      width: 10rem;
      height: 8rem;
      left: auto;
      top: auto;
    }
  `}
`
const SolutionTitle = styled.p`
  font-weight: 700;
  text-align: left;
  .highlight {
    color: ${p => p.theme.color.secondary};
  }
  p {
    width: 570px;
  }
  span {
    font-family: Source Han Sans;
    font-size: 42px;
    font-weight: bold;
    line-height: 56px;
    letter-spacing: 0.04em;
    font-variation-settings: 'opsz' auto;
  }
  ${Media.phone`
    margin-top: 0.8rem !important;
    span{
      font-size: 0.64rem;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ${props =>
      props.type === 'collaboration' &&
      css`
        span {
          font-size: 0.48rem;
          line-height: normal;
        }
        p {
          text-align: center;
        }
      `}
  `}
`
const SolutionBanner = ({ type, pic, bg }) => {
  const isMobile = useMedia()
  const [visible, setVisible] = React.useState(false)

  const handleClick = () => {
    if (isMobile) {
      Navigation.to({ link: '/partners/apply' })
    } else {
      setVisible(true)
    }
  }

  const getImageStyle = () => {
    if (isMobile) {
      switch (type) {
        case 'collaboration':
          return { right: '0.5rem', bottom: '0.37rem', width: '4.76rem', height: '3.80rem' }
        case 'ferry':
          return { right: '0rem', bottom: '0.37rem', width: '5.84rem', height: '4rem' }
        case 'ftp':
          return { right: '1.4rem', bottom: '0.37rem', width: '3.3rem', height: '4rem' }
        default:
          return { right: '-1rem', bottom: '0', width: '10rem', height: '8rem' }
      }
    } else {
      switch (type) {
        case 'collaboration':
          return { left: '880px', top: '-20px', width: '400px', height: '320px' }
        case 'ferry':
          return { left: '780px', top: '-30px', width: '496px', height: '340px' }
        case 'ftp':
          return { left: '760px', top: '0px', width: '247px', height: '300px' }
        default:
          return { left: '1160px', top: '0px', width: '400px', height: '320px' }
      }
    }
  }

  const getBannerImage = () => {
    switch (type) {
      case 'collaboration':
        return collaborationBanner
      case 'ferry':
        return ferryBanner
      case 'ftp':
        return ftpBanner
      default:
        return null
    }
  }

  return (
    <SolutionBannerContainer
      className={`solution-banner ${type}-wrap`}
      style={
        bg
          ? { backgroundImage: `url(${bg})`, backgroundPosition: 'right top', backgroundRepeat: 'no-repeat' }
          : undefined
      }
      type={type}
    >
      <BannerWrap className='banner-wrap' type={type}>
        <BannerLeft>
          <SolutionTitle type={type}>{intl.getHTML(`${type}.banner.title1`)}</SolutionTitle>
          {type === 'collaboration' ? (
            <ul>
              <li>
                <img src={collaborationIcon1} />
                低成本，高收益
              </li>
              <li>
                <img src={collaborationIcon2} />
                长期合作承诺
              </li>
              <li className={isMobile ? '' : 'third-item'}>
                <img src={collaborationIcon3} />
                形式灵活、高效返佣
              </li>
              <li>
                <img src={collaborationIcon4} />
                线索资源共享、商务团队支持
              </li>
              <li>
                <img src={collaborationIcon5} />
                技术赋能与市场推广、助力伙伴加速成长
              </li>
            </ul>
          ) : (
            <BannerDesc className={`${LocaleService.currentLocale}-${type}`}>
              {intl.getHTML(`${type}.banner.${isMobile && type === 'ftp' ? 'desc2' : 'desc1'}`)}
            </BannerDesc>
          )}
          {type === 'collaboration' ? (
            <BannerButton
              size='large'
              style={
                isMobile ? { marginLeft: '0.89rem', marginTop: '0.6rem' } : { marginRight: '20px', marginTop: '40px' }
              }
              onClick={handleClick}
            >
              成为合作伙伴
            </BannerButton>
          ) : type === 'large' ? (
            <BannerButton
              size='banner'
              style={
                isMobile ? { marginLeft: '0.89rem', marginTop: '0.1rem' } : { marginRight: '20px', marginTop: '40px' }
              }
              onClick={() => {
                Navigation.to({ link: 'https://www.raysync.cn/solutions/raysync-ferry/', isInternal: false })
              }}
            >
              了解更多
            </BannerButton>
          ) : (
            <BannerButton
              size='large'
              style={
                isMobile ? { marginLeft: '0.89rem', marginTop: '0.1rem' } : { marginRight: '20px', marginTop: '40px' }
              }
              onClick={() => {
                Navigation.to({ link: 'https://www.raysync.cn/solutions/ftp-alternative-upgrade/', isInternal: false })
              }}
            >
              了解更多
            </BannerButton>
          )}
          <Modal
            visible={visible}
            top='60px'
            minWidth={'800px'}
            closable
            onClose={() => {
              setVisible(false)
            }}
          >
            <ApplyContent
              closeModal={() => {
                setVisible(false)
              }}
            />
          </Modal>
          <BannerButton
            rel='nofollow'
            id='RequestFreeTrial'
            size='large'
            variant='outlined'
            onClick={() => {
              Navigation.toApply({ type: 'solution', pageType: type })
            }}
          >
            {intl.get(`${type}.banner.button`)}
          </BannerButton>
        </BannerLeft>
        <img src={getBannerImage()} style={getImageStyle()} alt={`${type} banner image`} />
        {pic ? <BannerRight className={`${type}-banner`}>{pic}</BannerRight> : null}
        {type === 'ftp' && !isMobile ? (
          <ul className='ftp-ul'>
            <li>
              <img src={ftpIcon} />
              高性能传输
            </li>
            <li>
              <img src={ftpIcon} />
              数据稳定高效
            </li>
            <li className='third-item'>
              <img src={ftpIcon} />
              企业级安全性
            </li>
            <li>
              <img src={ftpIcon} />
              集成控制
            </li>
            <li>
              <img src={ftpIcon} />
              可视化的文件传输流转
            </li>
            <li>
              <img src={ftpIcon} />
              多方式传输
            </li>
            <li>
              <img src={ftpIcon} />
              出色的兼容性
            </li>
          </ul>
        ) : null}
      </BannerWrap>
    </SolutionBannerContainer>
  )
}
export default SolutionBanner
