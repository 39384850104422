import { useEffect } from 'react'

/**
 * @description use like below
 * import React, { useRef } from 'react'
 * const Wrap = () => {
 *   const ref = useRef()
 *   useHover(ref, () => callback, isTrigger?:boolean)
 *   return <Element ref={ref} />
 * }
 */
export function useHover(ref, callback, isTrigger = true) {
  useEffect(() => {
    if (isTrigger) {
      const node = ref.current
      const handleMouseOverOrOut = event => {
        node.contains(event.target) && callback()
      }
      if (node) {
        node.addEventListener('mouseover', handleMouseOverOrOut)
        node.addEventListener('mouseout', handleMouseOverOrOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOverOrOut)
          node.removeEventListener('mouseout', handleMouseOverOrOut)
        }
      }
    }
  })
}
