import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Wrapper } from '@raysync/common/components'
export const BannerContainer = styled.div`
  height: 240px;
  ${Media.phone`
    height: 2.4rem;
  `}
`

export const CounterWrap = styled(Wrapper)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${p => p.theme.Hack.mobileForeign`
    align-items: flex-start;
    padding-top: .6rem;
  `}
`
export const Box = styled.div`
  ${({ theme: { typography, Hack } }) => css`
    color: white;
    line-height: 1;
    p {
      font-size: ${typography.h4};
    }
    ${Media.phone`
      p{
        font-size: ${typography.textThin};
      }
      margin: 0 auto;
    `}
    ${Hack.mobileForeign`
      width:33%;
    `}
  `}
`

export const CountText = styled.h1`
  font-size: 60px;
  font-weight: bold;
  ${Media.phone`
    font-size:30px;
    white-space: nowrap;
  `}
  ${p => p.theme.Hack.mobileForeign`
    font-size: 24px;
  `}
`

export const Divider = styled.div`
  width: 50px;
  height: 2px;
  background-color: white;
  margin: 22px 0;
  ${Media.phone`
    width: .5rem;
    margin: .3rem 0 .2rem 0;
  `}
`
