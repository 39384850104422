const SearchIcon =
  'M849.5 822.5L738.9 712c60-60.7 97.1-144.1 97.1-236 0-185.3-150.7-336-336-336S164 290.7 164 476s150.7 336 336 336c67.4 0 130-20.1 182.7-54.4l115.9 115.9c7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5c13.9-14.1 13.9-36.9-0.1-51zM236 476c0-145.6 118.4-264 264-264s264 118.4 264 264-118.4 264-264 264-264-118.4-264-264z'
const GoIcon =
  'M885.29 162.73A519.68 519.68 0 0 0 508.033 0C288.982 0 93.227 137.77 20.907 342.784a27.179 27.179 0 0 0 51.242 17.792C136.875 177.152 312.064 53.888 508.032 53.888a465.067 465.067 0 0 1 337.536 145.621 452.523 452.523 0 0 1 122.965 345.046C952.832 770.9 768.94 953.387 540.843 969.003c-231.168 16-440.192-141.782-485.547-366.635-3.072-15.36 0.981-31.232 11.093-43.52 10.496-12.63 25.942-19.883 42.411-19.883h524.032l-122.368 93.44a26.795 26.795 0 0 0-4.95 37.846 27.264 27.264 0 0 0 38.102 4.906l185.6-141.781a26.41 26.41 0 0 0 4.693-4.821l0.939-1.28a27.861 27.861 0 0 0 3.456-6.528l0.085-0.128a26.24 26.24 0 0 0 1.28-7.467v-1.536a26.368 26.368 0 0 0-1.024-7.168l-0.384-1.067a26.283 26.283 0 0 0-2.858-5.802l-0.768-1.28-0.47-0.726a26.795 26.795 0 0 0-4.693-4.608l-0.384-0.426L540.203 347.86a27.307 27.307 0 0 0-38.102 5.12 26.837 26.837 0 0 0 5.163 37.76l124.885 94.294H108.8c-32.81 0-63.573 14.506-84.352 39.68a105.387 105.387 0 0 0-22.357 88.234c48.298 239.702 262.656 411.008 506.752 411.008 11.818 0 23.765-0.426 35.712-1.194 255.018-17.494 460.586-221.44 478.165-474.496A505.6 505.6 0 0 0 885.29 162.73'
const ArrowRightIcon =
  'M676.8 512.128a32 32 0 0 1-9.984 23.232l-265.6 251.264a32 32 0 0 1-43.968-46.528l241.088-228.032L357.248 283.84a32.064 32.064 0 0 1-1.28-45.248c12.16-12.8 32.448-13.376 45.248-1.28l265.6 251.52a32 32 0 0 1 9.984 23.296z'
const ArrowLeftIcon =
  'M347.2 511.872v-0.008c0-9.137 3.829-17.379 9.97-23.21l265.614-251.277a31.888 31.888 0 0 1 21.984-8.744c17.678 0 32.008 14.33 32.008 32.008 0 9.157-3.845 17.416-10.009 23.25L425.664 511.936 666.752 740.16c6.165 5.855 10.001 14.113 10.001 23.266 0 8.516-3.32 16.256-8.736 21.998-12.145 12.784-32.434 13.36-45.233 1.264l-265.6-251.52c-6.155-5.844-9.984-14.086-9.984-23.223v-0.076z'
const ClockIcon =
  'M737.28 516.48a32 32 0 1 1 0 64H497.536a32 32 0 0 1-32-32V233.344a32 32 0 0 1 64 0V516.48h207.616zM512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768z m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z'
const QQIcon =
  'M870.397491 709.634201s-25.599821 70.143509-72.703491 133.119068c0 0 83.967412 28.671799 76.799463 103.423276 0 0 2.559982 82.943419-179.198746 77.311459 0 0-127.999104-10.239928-166.398835-64.511549h-33.791764c-38.399731 54.783617-166.398835 64.511548-166.398835 64.511549-182.270724 5.631961-179.198746-77.311459-179.198746-77.311459-7.16795-74.23948 76.799462-103.423276 76.799463-103.423276C179.20233 779.77771 153.602509 709.634201 153.602509 709.634201c-113.663204 184.830706-102.399283-25.599821-102.399283-25.599821 21.503849-124.927126 111.103222-206.334556 111.103222-206.334556-12.79991-113.663204 33.791763-133.631065 33.791763-133.631064C205.826143-6.136789 505.856043 0.007168 512 0.007168c6.143957 0 305.66186-6.143957 315.901789 344.061592 0 0 47.10367 19.96786 34.30376 133.631064 0 0 89.599373 81.919427 111.103222 206.334556-0.511996 0 10.751925 210.430527-102.91128 25.599821z m0 0'

const QaIcon =
  'M512 0c-283.031 0-512 228.969-512 512s228.969 512 512 512c283.031 0 512-228.969 512-512-1.59-283.031-230.559-512-512-512zM542.211 812.521h-90.634v-90.634h90.634v90.634zM648.745 454.757c-9.54 17.491-28.621 38.161-55.652 65.193-28.621 27.031-46.112 47.702-50.882 58.832-4.77 11.13-7.95 30.211-7.95 60.422l7.95 23.851h-90.634l-1.59-25.441c1.59-30.211 6.36-57.242 14.311-77.913 9.54-22.261 28.621-47.702 57.242-77.913 27.031-27.031 42.932-47.702 49.292-57.242 4.77-9.54 7.95-20.671 7.95-36.571 0-22.261-6.36-39.752-20.671-52.472-14.311-12.72-33.391-20.671-58.832-20.671-54.062 0-73.143 38.161-77.913 106.534h-90.634v-9.54c1.59-31.801 4.77-55.652 12.72-74.733s19.081-36.571 36.571-52.472c17.491-15.901 36.571-27.031 57.242-33.391 20.671-6.36 41.342-11.13 63.602-11.13 46.112 0 85.863 14.311 116.075 41.342 30.211 27.031 46.112 65.193 46.112 111.304 0 23.851-4.77 44.522-14.311 62.012z'

const LoadingIcon =
  'M512 1020.19207492c-31.97364417 0-57.89529966-25.92165549-57.89529966-57.89529966s25.92165549-57.89529966 57.89529966-57.89529967c216.71625886 0 392.40147559-175.68521671 392.40147559-392.40147559S728.71625886 119.59852441 512 119.59852441 119.59852441 295.28374114 119.59852441 512c0 31.97364417-25.92165549 57.89529966-57.89529967 57.89529966S3.80792508 543.97364417 3.80792508 512c0-280.66612035 227.5259546-508.19207492 508.19207492-508.19207492S1020.19207492 231.33387965 1020.19207492 512 792.66612035 1020.19207492 512 1020.19207492z'

const TopIcon = 'M726.4 422.08L183.424 965.12l-90.496-90.496L635.904 331.52z'

const PhoneIcon =
  'M396.595555 304.994775L287.595744 416.041531c56.802718 125.887105 176.548989 265.591088 316.252972 312.670818l94.159461-93.647725s223.116983 38.891951 272.755394 63.455289c43.497577 20.981184 57.826191 67.549178 51.17362 125.375369-7.164307 61.92008-88.530363 198.553646-234.375179 200.088854-146.356553 1.535209-361.285757-108.488074-515.318354-271.220186C117.699326 590.031839-19.957712 383.80215 2.558681 209.300106 24.563338 34.286325 176.548989 3.070417 214.929204 0.511736c38.380215-3.070417 69.084387 8.187779 92.112516 36.845007 30.192436 37.356743 89.553835 267.638033 89.553835 267.638032z m0 0'

const EmailIcon =
  'M703.560275 577.559026L47.970019 2.558401C48.609619 0.6396 47.970019 1.918801 48.609619 0h1299.667708c1.918801 0 7.035603 1.918801 10.233604 2.558401L703.560275 577.559026zM1407.12055 83.148032v864.739538c0 5.116802-1.2792 10.233604-2.558401 15.350406l-464.989382-490.573391 464.349781-408.06496c1.2792 5.756402 3.198001 11.512804 3.198002 18.548407zM3.198001 63.960025l464.989382 408.064959-456.034978 480.339788c-5.756402-2.558401-6.396002-2.558401-12.152405-5.116802V82.508432c0-6.396002 1.918801-12.792005 3.198001-18.548407z m890.323548 462.430981l460.51218 484.816989c-2.558401 5.756402-2.558401 6.396002-5.116802 12.792005H58.203623c-2.558401-5.756402-2.558401-6.396002-5.116802-12.152405l460.51218-484.816989 171.412867 150.306059c10.873204 9.594004 26.86321 9.594004 37.736414 0l170.773267-150.945659z m0 0.6396'

const LinkedinIcon =
  'M12.315,0.021 C6.006,0.021 0.891,5.135 0.891,11.442 C0.891,17.751 6.006,22.863 12.315,22.863 C18.624,22.863 23.740,17.750 23.740,11.442 C23.740,5.135 18.626,0.021 12.315,0.021 L12.315,0.021 ZM9.051,16.924 L6.464,16.924 L6.464,8.332 L9.051,8.332 L9.051,16.924 L9.051,16.924 ZM7.740,7.279 C6.923,7.279 6.379,6.680 6.396,5.943 C6.379,5.169 6.923,4.592 7.758,4.592 C8.592,4.592 9.119,5.170 9.136,5.943 C9.136,6.680 8.591,7.279 7.740,7.279 L7.740,7.279 ZM19.024,16.925 L16.438,16.925 L16.438,12.164 C16.438,11.057 16.064,10.301 15.127,10.301 C14.413,10.301 13.988,10.811 13.817,11.303 C13.749,11.462 13.714,11.725 13.714,11.970 L13.714,16.925 L11.128,16.925 L11.128,11.074 C11.128,10.003 11.094,9.088 11.060,8.332 L13.305,8.332 L13.424,9.510 L13.476,9.510 C13.816,8.966 14.667,8.138 16.045,8.138 C17.748,8.138 19.023,9.299 19.023,11.829 L19.024,16.925 L19.024,16.925 Z'
const twitterIcon =
  'M12.131,0.862 C5.822,0.862 0.706,5.976 0.706,12.284 C0.706,18.591 5.822,23.703 12.131,23.703 C18.441,23.703 23.555,18.591 23.555,12.284 C23.555,5.976 18.441,0.862 12.131,0.862 L12.131,0.862 ZM17.886,9.980 C17.892,10.104 17.895,10.228 17.895,10.353 C17.895,14.156 14.998,18.541 9.703,18.541 C8.077,18.541 6.563,18.065 5.289,17.249 C5.515,17.276 5.743,17.289 5.976,17.289 C7.325,17.289 8.567,16.829 9.552,16.057 C8.292,16.034 7.229,15.201 6.862,14.058 C7.038,14.092 7.218,14.109 7.404,14.109 C7.666,14.109 7.921,14.074 8.162,14.008 C6.845,13.744 5.852,12.580 5.852,11.186 C5.852,11.175 5.852,11.163 5.853,11.149 C6.241,11.366 6.684,11.494 7.157,11.511 C6.384,10.993 5.876,10.112 5.876,9.114 C5.876,8.587 6.018,8.091 6.266,7.667 C7.686,9.408 9.808,10.554 12.201,10.674 C12.152,10.464 12.126,10.243 12.126,10.018 C12.126,8.429 13.415,7.140 15.006,7.140 C15.833,7.140 16.581,7.490 17.107,8.049 C17.762,7.920 18.379,7.680 18.935,7.350 C18.719,8.022 18.263,8.587 17.669,8.942 C18.252,8.873 18.806,8.718 19.323,8.489 C18.937,9.068 18.449,9.575 17.886,9.980 L17.886,9.980 Z'
const instagramIcon =
  'M12.234,22.863 C5.926,22.863 0.814,17.750 0.814,11.443 C0.814,5.135 5.926,0.022 12.234,0.022 C18.541,0.022 23.653,5.135 23.653,11.443 C23.653,17.750 18.541,22.863 12.234,22.863 ZM18.822,8.719 C18.910,6.699 17.328,4.942 15.308,4.853 L14.957,4.853 C13.903,4.766 10.564,4.766 9.510,4.853 C7.490,4.766 5.733,6.348 5.645,8.368 L5.645,8.719 C5.558,9.773 5.558,13.112 5.645,14.166 C5.558,16.187 7.139,17.944 9.159,18.032 L9.510,18.032 C10.564,18.120 13.903,18.120 14.957,18.032 C16.977,18.120 18.734,16.538 18.822,14.517 L18.822,8.719 ZM16.187,16.538 C13.639,16.890 11.004,16.890 8.368,16.538 C7.754,16.275 7.314,15.835 7.139,15.308 C6.875,13.991 6.787,12.673 6.875,11.443 C6.787,10.125 6.875,8.807 7.139,7.577 C7.402,6.962 7.841,6.523 8.368,6.348 C10.916,5.996 13.551,5.996 16.187,6.348 C16.802,6.611 17.240,7.050 17.416,7.577 C17.680,8.894 17.767,10.213 17.680,11.443 C17.767,12.760 17.680,14.079 17.416,15.308 C17.153,15.923 16.714,16.363 16.187,16.538 ZM12.234,8.016 C10.389,8.016 8.808,9.511 8.808,11.443 C8.808,13.288 10.301,14.869 12.234,14.869 C14.078,14.869 15.659,13.376 15.659,11.443 C15.659,9.597 14.078,8.016 12.234,8.016 ZM15.747,7.138 C15.308,7.138 14.957,7.490 14.957,7.928 C14.957,8.368 15.308,8.719 15.747,8.719 C16.187,8.719 16.538,8.368 16.538,7.928 C16.538,7.490 16.187,7.138 15.747,7.138 ZM12.234,13.639 C11.004,13.639 10.037,12.673 10.037,11.443 C10.037,10.213 11.004,9.247 12.234,9.247 C13.464,9.247 14.430,10.213 14.430,11.443 C14.430,12.673 13.464,13.639 12.234,13.639 Z'
const facebookIcon =
  'M12.032,0.862 C5.721,0.862 0.606,5.976 0.606,12.284 C0.606,18.590 5.721,23.704 12.032,23.704 C18.342,23.704 23.455,18.590 23.455,12.284 C23.455,5.976 18.340,0.862 12.032,0.862 L12.032,0.862 ZM15.054,12.236 L13.076,12.236 C13.076,15.397 13.076,19.288 13.076,19.288 L10.143,19.288 C10.143,19.288 10.143,15.435 10.143,12.236 L8.749,12.236 L8.749,9.747 L10.143,9.747 L10.143,8.132 C10.143,6.976 10.691,5.175 13.104,5.175 L15.274,5.183 L15.274,7.600 C15.274,7.600 13.953,7.600 13.697,7.600 C13.442,7.600 13.076,7.728 13.076,8.278 L13.076,9.744 L15.309,9.744 L15.054,12.236 L15.054,12.236 Z'
const youtubeIcon =
  'M11.963,22.863 C5.654,22.863 0.539,17.749 0.539,11.443 C0.539,5.136 5.654,0.022 11.963,0.022 C18.272,0.022 23.387,5.136 23.387,11.443 C23.387,17.749 18.272,22.863 11.963,22.863 ZM17.889,7.797 C17.532,7.165 17.152,7.048 16.377,7.000 C15.602,6.951 13.656,6.928 11.966,6.928 C10.275,6.928 8.329,6.951 7.554,7.000 C6.781,7.048 6.399,7.165 6.042,7.797 C5.685,8.429 5.494,9.523 5.494,11.440 L5.494,11.443 L5.494,11.446 C5.494,13.362 5.685,14.456 6.042,15.088 C6.399,15.720 6.779,15.837 7.554,15.886 C8.329,15.934 10.275,15.958 11.966,15.958 C13.656,15.958 15.602,15.934 16.377,15.886 C17.150,15.837 17.532,15.720 17.889,15.088 C18.246,14.456 18.437,13.360 18.437,11.443 C18.437,9.526 18.246,8.429 17.889,7.797 ZM10.675,9.069 L14.349,11.443 L10.675,13.816 L10.675,9.069 Z'

const MenuIcon =
  'M128 768l768 0 0-85.333333L128 682.666667 128 768zM128 554.666667l768 0 0-85.333333L128 469.333333 128 554.666667zM128 256l0 85.333333 768 0L896 256 128 256z'

const CloseIcon =
  'M572.16 512l183.466667-183.04a42.666667 42.666667 0 1 0-60.586667-60.586667L512 451.84l-183.04-183.466667a42.666667 42.666667 0 0 0-60.586667 60.586667l183.466667 183.04-183.466667 183.04a42.666667 42.666667 0 0 0 0 60.586667 42.666667 42.666667 0 0 0 60.586667 0l183.04-183.466667 183.04 183.466667a42.666667 42.666667 0 0 0 60.586667 0 42.666667 42.666667 0 0 0 0-60.586667z'

const IconType = Object.freeze({
  Search: 'search',
  Go: 'go',
  ArrowLeft: 'arrow-left',
  ArrowRight: 'arrow-right',
  Clock: 'clock',
  QQ: 'qq',
  Question: 'question',
  Loading: 'loading',
  Top: 'top',
  Phone: 'phone',
  Email: 'email',
  Linkedin: 'linkedin',
  Twitter: 'twitter',
  Instagram: 'instagram',
  Facebook: 'facebook',
  Youtube: 'youtube',
  Menu: 'menu',
  Close: 'close',
})

export const IconMap = new Map([
  [IconType.Search, SearchIcon],
  [IconType.Go, GoIcon],
  [IconType.ArrowLeft, ArrowLeftIcon],
  [IconType.ArrowRight, ArrowRightIcon],
  [IconType.Clock, ClockIcon],
  [IconType.QQ, QQIcon],
  [IconType.Question, QaIcon],
  [IconType.Loading, LoadingIcon],
  [IconType.Top, TopIcon],
  [IconType.Phone, PhoneIcon],
  [IconType.Email, EmailIcon],
  [IconType.Linkedin, LinkedinIcon],
  [IconType.Twitter, twitterIcon],
  [IconType.Instagram, instagramIcon],
  [IconType.Facebook, facebookIcon],
  [IconType.Youtube, youtubeIcon],
  [IconType.Close, CloseIcon],
  [IconType.Menu, MenuIcon],
])

export default IconType
