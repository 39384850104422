import React from 'react'

const SvgUp = props => (
  <svg width={23} height={13} {...props}>
    <path
      fillRule='evenodd'
      fill='#FFF'
      d='M21.698 12.622a.988.988 0 01-.701-.289l-9.218-9.165-9.218 9.165a.997.997 0 01-1.403 0 .983.983 0 010-1.395L11.77 1.76c-.305-1.07 1.16-1.07.71 0l9.919 9.178a.988.988 0 01-.701 1.684z'
    />
  </svg>
)

export default SvgUp
