import styled, { css } from 'styled-components'
import { LargeWrapper, Wrapper, Flex, Link } from '@raysync/common/components'
import { Media, hideAtMobile } from '@raysync/common/theme'
import IntroBg from '../images/apply/intro_bg.png'

export const ErrorText = styled.p`
  color: red;
  p {
    text-align: left !important;
  }
`
export const Input = styled.input`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    font-size: ${typography.textSmall};
    border: 1px solid ${p => (p.iserror ? 'red !important' : color.line)};
    padding-left: 16px;
    border-radius: 7px;
    display: block;
    width: 100%;
    ::placeholder {
      font-size: ${typography.textSmall};
      color: ${color.text};
      opacity: 1;
    }
    ${p =>
        p.iserror &&
        css`
          border-color: red;
        `}
      :focus {
      outline: none;
    }
    height: 44px;
  `}
`

export const FormItem = styled.div`
  margin-bottom: 16px;
  position: relative;
  .rc-select-selector {
    border-radius: 7px !important;
  }
  ${p => p.theme.Hack.desktopEn`
     margin-bottom: 10px;
  `}
  ${p =>
    p.required &&
    css`
      ::after {
        content: '*';
        position: absolute;
        left: 6px;
        top: 52%;
        transform: translateY(-50%);
        color: red;
      }
    `};
`
export const FormItemContainer = styled(FormItem)`
  display: flex;
  select {
    width: 80px;
  }
`

export const RequiredFormItem = styled.div`
  ${({ theme: { color, typography } }) => css`
  position: relative;
  margin-bottom: 14px;
  width: 100%;
  ${p => p.theme.Hack.desktopEn`
     margin-bottom: 10px;
  `}
  margin-bottom:${p => (p.inline ? '0px !important' : '')};
  :before {
    position: absolute;
    content: '*';
    color: red;
    line-height: 40px;
    left: 4px;
  }
  input {
    border: 1px solid ${p => (p.iserror ? 'red !important' : color.line)};
  }
`}
`

export const TextArea = styled.textarea`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    font-size: ${typography.textSmall};
    border: 1px solid ${color.line};
    padding-left: 12px;
    width: 100%;
    ::placeholder {
      font-size: ${typography.textSmall};
      color: ${color.text};
      opacity: 1;
    }
    ${p =>
        p.iserror &&
        css`
          border-color: red;
        `}
      :focus {
      outline: none;
    }
    padding-top: 12px;
    line-height: 24px;
    height: 130px;
    display: block;
    resize: none;
  `}
`

export const ApplyWrap = styled.div`
  ${({ theme: { color } }) => css`
    padding: 100px 0 140px;
    min-height: calc(100vh - 60px);
    background-color: ${color.panel};
    text-align: center;
    position: relative;
    z-index: 1;
    background: #f5f7fb;
  `}
  ${Media.phone`
    padding: 1.2rem 0 .4rem;
    min-height: calc(100vh - .6rem);
  `}
`

export const ApplySection = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ApplyContainer = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    box-shadow: initial;
  `}
`
export const DescriptionContent = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    color: #182940;
    width: 600px;
    text-align: left;
    margin-top: 100px;
    > div {
      width: 100%;
      height: 100%;

      .h2 {
        margin-bottom: 30px;
        font-weight: bold;
        line-height: 1.5;
        font-size: 48px;
        span {
          display: block;
          font-weight: bold;
          line-height: 1.5;
          font-size: 48px;
          letter-spacing: 1.15px;
          color: ${color.secondary};
        }
      }
      > p {
        font-size: ${typography.h4};
        line-height: 28px;
        margin-bottom: 12px;
      }
    }
    ${hideAtMobile};
  `}
`
export const PartnerWrap = styled.div`
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  > div > p {
    color: #636772;
    a {
      color: #636772;
    }
  }
  ${Media.phone`
    bottom: .6rem;
    > div > p {
      width: 100vw;
      font-size: .2rem;
    }
  `}
`
export const CalculatorLink = styled(Link)`
  ${({ theme: { Hack } }) => css`
    color: white;
    font-size: 20px;
    margin-top: 5px;
    :hover {
      color: white;
      svg {
        transform: translateX(4px);
      }
    }
    svg {
      width: 14px;
      height: 12px;
      transition: transform 0.3s ease-in-out;
    }
  `}
`

export const EnIntro = styled.article`
  h4 {
    font-size: 22px;
    margin: 15px 0;
  }
`

export const IntroContainer = styled.div`
  ${({ theme: { typography, Hack } }) => css`
    > div {
      display: flex;
      margin-top: 20px;
      position: relative;
      z-index: 1;
      img {
        margin-right: 10px;
      }
      h4 {
        font-size: 20px;
        margin-bottom: 14px;
        font-weight: 500;
      }
      p {
        line-height: 21px;
        font-size: ${typography.textSmall};
      }
    }
  `}
  position: relative;
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: -18px;
    right: 206px;
    width: 160%;
    height: 120%;
    opacity: 0.6;
    z-index: 1;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid #fff;
  }
`

export const ApplyDesc = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: #9e9e9e;
    line-height: 26px;
    font-size: ${typography.text};
    padding-top: ${p => p.top + 'px'};
    padding-bottom: ${p => p.bottom + 'px'};
    a {
      display: inline-block;
      line-height: 26px;
      color: ${color.secondary};
    }
    b {
      position: relative;
      top: -1.5px;
      font-size: 18px;
      margin-right: 8px;
    }
  `}
`
export const ApplyText = styled.p`
  line-height: 1.5;
  color: ${p => (p.isSub ? '#8796AA' : '#182940')};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 10px auto 30px;
  span {
    font-weight: 700;
    color: ${p => p.theme.color.secondary};
  }
  ${Media.phone`
    margin: .1rem auto .3rem;
    font-size: ${p => (p.isSub ? '.2rem' : '.28rem')};
  `}
`
export const SubText = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
  margin: 36px auto 10px;
  color: #8796aa;
  ${Media.phone`
    margin: .3rem auto .2rem;
    font-size: .2rem;
  `}
`
export const SuccessContainer = styled.div`
  padding: 60px 30px 40px;
  width: 540px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 19px 0px rgba(161, 173, 179, 0.349);
  h2 {
    font-weight: bold;
  }
  ${Media.phone`
    width: 100%;
    padding: 0.6rem .5rem 1rem;
    h2 {
      font-size: .48rem;
      margin-bottom: .2rem;
      span {
       font-size: .48rem;
      }
    }
  `}
`
export const Containervideobanner = styled.div`
  padding: 18px 40px 40px;
  width: 480px;
  height: 410px;
  border-radius: 12px;
  opacity: 1;
  background: rgba(28, 31, 43, 0.6);
  border: 1px solid rgba(99, 103, 114, 0.6);
  box-shadow: 0px 4px 20px 0px rgba(28, 31, 43, 0.1);
  position: absolute;
  top: 40px;
  right: 250px;
  h2 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #ffffff;
  }
  ${Media.phone`
    width: 100%;
    padding: 0.6rem .5rem 1rem;
    h2 {
      font-size: .48rem;
      margin-bottom: .2rem;
      span {
       font-size: .48rem;
      }
    }
  `}
`
export const ApplySuccessContainer = styled(SuccessContainer)`
  margin-top: 30px;
  text-align: left;
  ${Media.phone`
    padding: 0;
  `}
`
export const ApplySuccessWrap = styled(ApplyWrap)`
  ${Media.phone`
    padding-top: .4rem;
  `}
`

export const StepContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    width: 500px;
    padding: ${spacing.base};
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    ${Media.phone`
      width: 100%;
    `}
  `}
`
export const ButtonMask = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  background: transparent;
  height: 100%;
`

export const ButtonContainer = styled(Flex)`
  ${({ theme: { spacing } }) => css`
    position: relative;
    ${Media.phone`
      button {
        width: 100%;
        border-radius: .4rem !important;
        height: .8rem !important;
        line-height: .8rem !important;
      }
    `}
  `}
`
export const EnWrap = styled.div`
  display: flex;
  p,
  span,
  input {
    font-weight: normal !important;
  }
  h2,
  h4 {
    font-weight: bold;
  }
`
export const ENBannerWrap = styled.div`
  width: 1100px;
  height: 100vh;
  min-height: 800px;
  /* padding: 200px 0px 0 360px; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  ${hideAtMobile};
  @media (max-width: 1440px) {
    width: 900px;
    padding: 160px 90px 0 260px;
  }
  > div {
    width: 620px;
  }
  h2 {
    font-size: 36px;
    line-height: 48px;
    font-weight: bold;
    color: #fff;
  }
  p {
    line-height: 24px;
    font-size: 18px;
    color: #fff;
    margin-bottom: 50px;
    font-weight: 400;
  }
  button {
    min-width: 300px;
    padding: 0 20px;
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 550;
    :hover {
      svg {
        transform: translateX(4px);
      }
    }
    svg {
      margin-left: 15px;
      color: white;
      width: 28px;
      height: 16px;
      transition: transform 0.3s ease-in-out;
    }
  }
  h4 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #fff;
    font-weight: bold;
  }
  ul {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    li {
      display: flex;
      font-size: 16px;
      margin: 20px 0 0 0;
      padding-left: 0;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 16px;
      }
      img{
        margin-right:16px;
        /* width: 13px;
        height:16px; */
        /* nth-child(1){
          width: :13px;
          height:16px;
        }
        nth-child(1){
          width: :13px;
          height:16px;
        }
        nth-child(1){
          width: :13px;
          height:16px;
        }
        nth-child(1){
          width: :13px;
          height:16px;
        }
        nth-child(1){
          width: :13px;
          height:16px;
        }
        nth-child(1){
          width: :13px;
          height:16px;
        } */
      }
      /* svg {
        height: 40px;
        min-width: 48px;
        transform: translateY(-2px);
        margin-right: 26px;
        margin-top: 6px;
        color: ${p => p.theme.color.secondary};
      } */
      &::before {
        display: none;
      }
    }
  }
`
export const EnSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  width: 820px;
  ${SuccessContainer} {
    h2 {
      color: #e95219;
    }
    input,
    textarea {
      ::placeholder {
        color: #999;
      }
    }
  }
`
export const Footer = styled.div`
  background: #0d1922;
  height: 60px;
  margin-top: -2px;
  ${hideAtMobile}
`
export const FooterWrapper = styled(LargeWrapper)`
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  > p {
    color: #666;
  }
  > div {
    padding: 0;
  }
`
export const Img = styled.img`
  z-index: -1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${Media.phone`
    max-width: 100vw;
 `}
`
export const DescBox = styled.div`
  h3 {
    color: ${p => p.theme.color.secondary};
    margin-bottom: 10px;
  }
  p {
    color: #fff;
  }
`
