import styled, { css } from 'styled-components'
import { Flex } from '@raysync/common/components'
import { transition, Media } from '@raysync/common/theme'

export const VideoWrap = styled(Flex)`
  height: 675px;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: white;
  text-align: center;
  align-items: center;
  text-shadow: rgba(0, 0, 0, 0.75) 3px 0 3px;
  button {
    position: relative;
  }
  img {
    position: absolute;
    width: 80px;
    height: 80px;
    z-index: 10;
    cursor: pointer;
    transform: translate(-40px, -40px);
  }
  > video {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    outline: none;
  }
  ${Media.phone`
    width: 80%;
    height: 3.6rem;
    margin: 0 auto;
    img[alt="poster"] {
      z-index: 1;
      width: 100%;
      height: 100%;
      transform: unset;
    }
  `}
`
export const VideoIcon = styled.img`
  width: 80px;
  height: 80px;
  z-index: 100;
  cursor: pointer;
  ${Media.phone`
    width: .8rem;
    height: .8rem;
  `}
`
export const ModalVideo = styled.video`
  width: 100%;
`

export const Poster = styled.img`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
`

export const PlayButton = styled.div`
  ${({ theme: { color } }) => css`
    cursor: pointer;
    width: 60px;
    height: 60px;
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid currentColor;
    transition: ${transition(['color'])};
    :hover {
      color: ${color.secondary};
    }
    ::after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      margin-right: -5px;
      border-width: 15px 0 15px 24px;
      border-style: solid;
      border-color: currentColor;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  `}
`
export const PauseButton = styled.div`
  ${({ theme: { color } }) => css`
    cursor: pointer;
    width: 60px;
    height: 60px;
    color: white;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid currentColor;
    transition: ${transition(['color'])};
    :hover {
      color: ${color.secondary};
    }
    ::after {
      display: block;
      content: '';
      width: 20px;
      height: 30px;
      border-width: 0 0 0 25px;
      border-style: double;
      border-color: currentColor;
    }
  `}
`
