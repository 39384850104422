import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { Billboard } from '@raysync/common/components'
import { MainBannerContainer } from './atoms'
const Wrapper = styled.a`
  width: 100%;
  height: 100%;
  display: block;
`

const ActivitiesBanner = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { eq: "activity_banner.png" }) {
            ...fluidImage
          }
        }
      `}
      render={({ banner }) => {
        return (
          <MainBannerContainer>
            <Wrapper href={'https://www.raysync.cn/news/post-id-942'}></Wrapper>
            <Billboard fluid={banner.childImageSharp.fluid} />
          </MainBannerContainer>
        )
      }}
    />
  )
}
export default ActivitiesBanner
