import { css } from 'styled-components'

const sizes = {
  desktop: 1200,
  tablet: 1024,
  phone: 768,
}

const Media = {
  phone: (...args) => css`
    @media (max-width: ${sizes.phone}px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${sizes.desktop}px) {
      ${css(...args)}
    }
  `,
}
export const Devices = {
  desktop: `(min-width: 1400px)`,
  tablet: `(max-width: 1400px)`,
  phone: `(max-width: ${sizes.phone}px)`,
}

export default Media
