import React from 'react'

const SvgScene6 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='scene6_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene6_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene6_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='scene6_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene6_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={20}
        height={20}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <rect x={6} y={6} width={18} height={18} rx={2} fill='url(#scene6_svg__a)' />
    <g transform='matrix(-1 0 0 1 44 0)' filter='url(#scene6_svg__b)'>
      <rect x={22} width={22} height={22} rx={2} fill='url(#scene6_svg__c)' />
      <rect x={22.5} y={0.5} width={21} height={21} rx={1.5} stroke='url(#scene6_svg__d)' />
    </g>
    <g filter='url(#scene6_svg__e)'>
      <path
        d='M3.508 9.4V4.6q0-.05-.01-.098-.009-.048-.028-.093-.019-.046-.046-.087-.027-.04-.062-.076-.035-.034-.076-.062-.04-.027-.086-.046-.046-.019-.094-.028-.049-.01-.098-.01t-.097.01q-.049.01-.094.028-.046.019-.087.046-.04.028-.075.062-.035.035-.063.076-.027.041-.046.087-.019.045-.028.093-.01.049-.01.098v4.8q0 .988 1.35 1.588 1.15.512 2.746.512 1.597 0 2.746-.512 1.35-.6 1.35-1.588V4.6q0-.05-.01-.098t-.028-.093q-.02-.046-.046-.087-.028-.04-.063-.076-.034-.034-.075-.062-.041-.027-.087-.046-.045-.019-.094-.028-.048-.01-.097-.01-.05 0-.098.01t-.094.028q-.045.019-.086.046-.04.028-.076.062-.035.035-.062.076-.027.041-.046.087-.019.045-.029.093-.01.049-.01.098v4.8q0 .338-.755.675-.956.425-2.34.425t-2.34-.425q-.756-.337-.756-.675z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M4.26 7.675Q3.5 7.338 3.5 7q0-.05-.01-.098t-.028-.093q-.019-.046-.046-.087-.028-.04-.062-.076-.035-.034-.076-.062-.041-.027-.087-.046-.045-.019-.093-.028Q3.049 6.5 3 6.5q-.05 0-.098.01t-.093.028q-.046.019-.087.046-.04.028-.076.062-.034.035-.062.076-.027.041-.046.087-.019.045-.028.093-.01.049-.01.098 0 .988 1.354 1.589 1.153.511 2.75.511t2.747-.512Q10.7 7.988 10.7 7q0-.05-.01-.098t-.028-.093q-.019-.046-.046-.087-.028-.04-.062-.076-.035-.034-.076-.062-.041-.027-.087-.046-.045-.019-.093-.028-.049-.01-.098-.01-.05 0-.098.01t-.093.028q-.046.019-.087.046-.04.028-.076.062-.034.035-.062.076-.027.041-.046.087-.019.045-.028.093-.01.049-.01.098 0 .338-.756.675-.956.425-2.34.425-1.385 0-2.344-.425z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M9.695 4.656q-.003-.028-.003-.056 0-.338-.756-.675Q7.98 3.5 6.596 3.5t-2.34.425Q3.5 4.262 3.5 4.6t.76.675q.959.425 2.344.425 1.384 0 2.34-.425.693-.309.75-.619zM10.7 4.6q0-.057-.013-.113-.073-.91-1.345-1.475Q8.192 2.5 6.596 2.5q-1.597 0-2.747.512Q2.5 3.612 2.5 4.6q0 .988 1.354 1.589 1.153.511 2.75.511t2.747-.512Q10.7 5.588 10.7 4.6zM12.308 17.4v-4.8q0-.05-.01-.098-.009-.048-.028-.093-.018-.046-.046-.087-.027-.04-.062-.076-.035-.034-.076-.062-.04-.027-.086-.046-.046-.019-.094-.028-.048-.01-.098-.01-.049 0-.097.01t-.094.028q-.045.019-.086.046-.041.028-.076.062-.035.035-.062.076-.028.041-.047.087-.018.045-.028.093-.01.049-.01.098v4.8q0 .988 1.35 1.588 1.15.512 2.746.512 1.597 0 2.747-.512 1.349-.6 1.349-1.588v-4.8q0-.05-.01-.098t-.028-.093q-.019-.046-.046-.087-.028-.04-.062-.076-.035-.034-.076-.062-.041-.027-.087-.046-.045-.019-.093-.028-.049-.01-.098-.01-.05 0-.098.01t-.093.028q-.046.019-.087.046-.04.028-.076.062-.034.035-.062.076-.027.041-.046.087-.019.045-.028.093-.01.049-.01.098v4.8q0 .338-.756.675-.956.425-2.34.425t-2.34-.425q-.756-.337-.756-.675z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M13.064 15.675q-.756-.337-.756-.675 0-.05-.01-.097-.009-.049-.028-.094-.018-.046-.046-.087-.027-.04-.062-.075-.035-.035-.076-.063-.04-.027-.086-.046-.046-.019-.094-.028-.048-.01-.098-.01-.049 0-.097.01t-.094.028q-.045.019-.086.046-.041.028-.076.063-.035.034-.062.075-.028.041-.047.087-.018.045-.028.094-.01.048-.01.097 0 .988 1.35 1.588 1.15.512 2.746.512 1.597 0 2.747-.512 1.348-.6 1.349-1.587 0-.1-.038-.192t-.108-.162q-.07-.07-.163-.109Q19.1 14.5 19 14.5q-.05 0-.098.01t-.093.028q-.046.019-.087.046-.04.028-.076.063-.034.034-.062.075-.027.041-.046.087-.019.045-.028.094-.01.048-.01.097 0 .338-.756.675-.956.425-2.34.425t-2.34-.425z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M18.15 11.012Q17 10.5 15.405 10.5q-1.597 0-2.746.512-1.35.6-1.35 1.588 0 .988 1.35 1.588 1.15.512 2.746.512 1.597 0 2.747-.512 1.349-.6 1.349-1.588 0-.988-1.35-1.588zm-5.086.913q.956-.425 2.34-.425t2.34.425q.756.337.756.675t-.756.675q-.956.425-2.34.425t-2.34-.425q-.756-.337-.756-.675t.756-.675zM17.7 5.4v2.4q0 .05.01.098t.028.094q.019.045.046.086.027.04.062.076.035.035.076.062.041.027.087.046.045.019.093.029.049.01.098.01.05 0 .097-.01.049-.01.094-.029.046-.019.087-.046.04-.027.075-.062.035-.035.063-.076.027-.041.046-.086.019-.046.028-.094.01-.049.01-.098V5.4q0-.87-.615-1.485Q17.47 3.3 16.6 3.3h-2.4q-.05 0-.098.01t-.093.028q-.046.02-.087.046-.04.028-.076.063-.035.034-.062.075-.027.041-.046.087-.019.045-.029.094-.01.048-.01.097 0 .05.01.098t.029.094q.019.045.046.086.027.04.062.076.035.035.076.062.041.027.087.046.045.019.093.029.049.01.098.01h2.4q.456 0 .778.321.322.322.322.778zM4.3 16.6v-2.4q0-.05-.01-.098-.009-.048-.028-.093-.019-.046-.046-.087-.027-.04-.062-.076-.035-.034-.076-.062-.041-.027-.086-.046-.046-.019-.094-.028-.049-.01-.098-.01-.05 0-.097.01-.049.01-.094.028-.046.019-.087.046-.04.028-.075.062-.035.035-.063.076-.027.041-.046.087-.019.045-.028.093-.01.049-.01.098v2.4q0 .87.615 1.485.615.615 1.485.615h2.4q.05 0 .098-.01t.094-.028q.045-.019.086-.046.04-.028.076-.063.035-.034.062-.075.027-.041.046-.087.019-.045.029-.094.01-.048.01-.097 0-.05-.01-.098t-.029-.093q-.019-.046-.046-.087-.027-.04-.062-.076-.035-.034-.076-.062-.041-.027-.086-.046-.046-.019-.094-.028-.049-.01-.098-.01H5.4q-.456 0-.778-.322T4.3 16.6z'
        fillRule='evenodd'
        fill='#FFF'
      />
    </g>
  </svg>
)

export default SvgScene6
