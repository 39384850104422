import React from 'react'

const CheckIcon = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      version='1.1'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      {...props}
    >
      <g>
        <g>
          <ellipse cx='9' cy='9' rx='9' ry='9' fill='#FD5C1F' fillOpacity='1' />
        </g>
        <g>
          <path
            d='M13.74741,6.664364Q13.86944,6.527075,13.93472,6.35538Q14,6.183686,14,6Q14,5.9015086,13.98078,5.80491Q13.96157,5.708311,13.92388,5.617317Q13.88619,5.526322,13.83147,5.44443Q13.77675,5.362537,13.70711,5.292893Q13.63746,5.223249,13.55557,5.16853Q13.47368,5.113812,13.38268,5.076121Q13.29169,5.03843,13.19509,5.019215Q13.09849,5,13,5Q12.89409,5,12.79052,5.022186Q12.68696,5.044372,12.590340000000001,5.08776Q12.49373,5.131148,12.408339999999999,5.193812Q12.32296,5.256476,12.25259,5.335636L12.252030000000001,5.33627L7.66667,10.4948L5.747494,8.33573L5.747409,8.33564Q5.677045,8.25648,5.591659,8.19381Q5.506274,8.13115,5.409656,8.08776Q5.313038,8.04437,5.209475,8.02219Q5.105913,8,5,8Q4.9015086,8,4.80491,8.019210000000001Q4.708311,8.03843,4.617317,8.07612Q4.526322,8.11381,4.44443,8.16853Q4.362537,8.22325,4.292893,8.29289Q4.223249,8.36254,4.16853,8.44443Q4.113812,8.52632,4.076121,8.61732Q4.03843,8.70831,4.019215,8.80491Q4,8.90151,4,9Q4,9.18369,4.065278,9.35538Q4.130557,9.52708,4.252591,9.66436L4.252676,9.66446L6.9192599999999995,12.66436Q6.95834,12.70833,7.0023,12.74741Q7.07592,12.81284,7.160880000000001,12.86266Q7.24585,12.912469999999999,7.338900000000001,12.944759999999999Q7.4319500000000005,12.977039999999999,7.52951,12.990549999999999Q7.62707,13.004059999999999,7.72539,12.99827Q7.82371,12.99249,7.91901,12.96764Q8.01432,12.942779999999999,8.10294,12.89981Q8.19157,12.85684,8.2701,12.79741Q8.34864,12.73798,8.41408,12.66436L13.74741,6.664364L13.74741,6.664364Z'
            fillRule='evenodd'
            fill='#FFFFFF'
            fillOpacity='1'
          />
        </g>
      </g>
    </svg>
  )
}

export default CheckIcon
