import { css } from 'styled-components'

const sizes = {
  desktop: 1660,
  tablet: 1024,
  phone: 750,
}

const Media = {
  phone: (...args) => css`
    @media (max-width: ${sizes.phone}px) {
      ${css(...args)}
    }
  `,
  book: (...args) => css`
    @media (max-width: ${sizes.desktop}px) and (min-width: ${sizes.phone}px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${sizes.desktop}px) {
      ${css(...args)}
    }
  `,
}

export default Media
