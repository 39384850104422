import React, { useRef, useState, useEffect } from 'react'
import { useMedia } from '@raysync/common/hooks'
import { Modal } from '@raysync/common/components'
import { VideoWrap, VideoIcon, ModalVideo, Poster } from './atoms'
import { LOCALES, LocaleService } from '@raysync/common/services'
import PlayIcon from './play.png'
import PosterZh from './video_zh.png'
import PosterEn from './video_en.png'

const defaultPoster = {
  [LOCALES.ZH]: PosterZh,
  [LOCALES.EN]: PosterEn,
  [LOCALES.JA]: PosterEn,
}[LocaleService.currentLocale]

const Video = ({ src, poster, preload = 'none', onPlayChange = () => {}, ...rest }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [notFirstPlaying, setNotFirstPlaying] = useState(false)
  const isMobile = useMedia()
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('play', function() {
        setIsPlaying(true)
        onPlayChange(true)
      })
      videoRef.current.addEventListener('pause', function() {
        setIsPlaying(false)
        onPlayChange(false)
      })
    }
  })

  const play = () => {
    videoRef.current && videoRef.current.play()
  }
  const pause = () => {
    videoRef.current && videoRef.current.pause()
  }

  if (isMobile) {
    return (
      <VideoWrap {...rest}>
        {!isPlaying && (
          <button
            onKeyPress={() => {
              play()
              setIsVisible(true)
              setNotFirstPlaying(true)
            }}
            onClick={() => {
              play()
              setIsVisible(true)
              setNotFirstPlaying(true)
            }}
          >
            <VideoIcon src={PlayIcon} alt='play' />
          </button>
        )}
        <Poster
          src={poster || defaultPoster}
          alt='poster'
          onClick={e => {
            e.preventDefault()
          }}
        />
        <Modal
          visible={isVisible}
          onClose={() => {
            setIsVisible(false)
            pause()
          }}
        >
          <ModalVideo controls preload={preload} disablePictureInPicture controlsList='nodownload' ref={videoRef}>
            <source type='video/mp4' src={src} />
            <track kind='captions' />
          </ModalVideo>
        </Modal>
      </VideoWrap>
    )
  } else {
    return (
      <VideoWrap {...rest}>
        {!isPlaying && (
          <button
            onKeyPress={() => {
              play()
              setNotFirstPlaying(true)
            }}
            onClick={() => {
              play()
              setNotFirstPlaying(true)
            }}
          >
            <img src={PlayIcon} alt='Take a tour of Raysync' id='Index-PlayVideo' />
          </button>
        )}
        <video
          controls={notFirstPlaying}
          preload={preload}
          disablePictureInPicture
          controlsList='nodownload'
          poster={poster}
          ref={videoRef}
        >
          <source type='video/mp4' src={src} />
          <track kind='captions' />
        </video>
      </VideoWrap>
    )
  }
}

export default Video
