import React from 'react'
import styled from 'styled-components'
import SolutionBanner from './index'
import { Media } from '@raysync/common/theme'
import FtpImg from './ftp-banner.png'
import HomeFtpImg from './home-ftp.png'
import FerryImg from './ferry-banner.png'
import HomeFerryImg from './home-ferry.png'
const Wrap = styled.div`
  > div {
    margin-top: ${p => (p.isHome ? '60px' : 'unset')};
    height: ${p => p.height + 'px'};
    img {
      height: ${p => p.height + 'px'};
    }
  }
  .solution-banner {
    background-position: right 220px top !important;
  }
  ${Media.phone`
    > div {
      height: 10.4rem;
      margin-top: ${p => (p.isHome ? '.9rem' : 'unset')};
    }
    .ftp-wrap {
      background-size: 11.42rem 6.5rem;
      background-position: calc(100% + 1.3rem) calc(100% + 0.5rem) !important;
    }
    .ferry-wrap {
      background-size: 11.35rem 6.9rem;
      background-position: calc(100% + 0.5rem) calc(100% + 0.5rem) !important;
    }
    .solution-banner {
      h2 {
       font-size: .6rem;
       font-weight: bold;
       text-align: center;
       padding-left: 0;
       padding-right: 0;
      }
      p {
        font-size: .24rem;
        margin-top: .2rem;
        text-align: center;
      }      
      button {
        margin-bottom: unset;
      }
      img {
        margin: 0 auto;
        height: 6.2rem !important;
        transform: translate(-0.5rem, 0rem) scale(1) !important;
      }
    }
  `};
`

export const FtpBanner = ({ height = 460, isHome }) => (
  <Wrap height={height} isHome={isHome}>
    <SolutionBanner type='ftp' bg={isHome ? HomeFtpImg : FtpImg} />
  </Wrap>
)
export const FerryBanner = ({ height = 460, isHome }) => (
  <Wrap height={height} isHome={isHome}>
    <SolutionBanner type='ferry' bg={isHome ? HomeFerryImg : FerryImg} />
  </Wrap>
)
