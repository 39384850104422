import React from 'react'
import intl from 'react-intl-universal'
import { useCountUp } from 'react-countup'
import { useInViewport } from '@raysync/common/hooks'
import { graphql, StaticQuery } from 'gatsby'
import { Billboard } from '@raysync/common/components'
import { BannerContainer, CounterWrap, Box, CountText, Divider } from './atoms'

function Zone() {
  const { countUp, start } = useCountUp({ end: 50, delay: 500 })
  const [, setTargetRef] = useInViewport({ onEnter: start })
  return (
    <Box ref={setTargetRef}>
      <CountText>{countUp}+</CountText>
      <Divider />
      <p>{intl.get('home.user.point1')}</p>
    </Box>
  )
}

function User() {
  const { countUp, start } = useCountUp({ end: 5, delay: 800 })
  const [, setTargetRef] = useInViewport({ onEnter: start })
  return (
    <Box ref={setTargetRef}>
      <CountText>{`${countUp}${intl.get('home.user.unit')}+`}</CountText>
      <Divider />
      <p>{intl.get('home.user.point2')}</p>
    </Box>
  )
}
function BusinessUser() {
  const { countUp, start } = useCountUp({ end: 2, delay: 800 })
  const [, setTargetRef] = useInViewport({ onEnter: start })
  return (
    <Box ref={setTargetRef}>
      <CountText>{`${countUp}${intl.get('home.user.unit')}+`}</CountText>
      <Divider />
      <p>{intl.get('home.user.point3')}</p>
    </Box>
  )
}

const CounterBanner = () => (
  <StaticQuery
    query={graphql`
      query {
        CounterBg: file(relativePath: { eq: "index/bg1.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ CounterBg }) => (
      <BannerContainer>
        <CounterWrap>
          <Zone />
          <User />
          <BusinessUser />
        </CounterWrap>
        <Billboard fluid={CounterBg.childImageSharp.fluid} alt='Counter background image' />
      </BannerContainer>
    )}
  />
)
export default CounterBanner
