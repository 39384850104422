import styled from 'styled-components'
import { BannerContainer, BannerDesc, BannerButton } from '../atoms'
import { Media } from '@raysync/common/theme'
export const MainBannerContainer = styled(BannerContainer)`
  background: white;
  padding-top: 60px;
  height: 580px;
  ${Media.phone`
    background-size: 3.3rem 3rem;
    background-position: 0% 60px;
    padding-bottom:60px;
    height:initial;
  `}
`
export const MainBannerButton = styled(BannerButton)`
  margin-top: 22px;
  ${Media.phone`
    display:block;
    margin: .5rem 0.54rem .8rem;
  `}
`
export const MainBannerDesc = styled(BannerDesc)`
  min-height: 52px;
  width: 560px;
`
