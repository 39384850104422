export * from './range'
export * from './sort'

export function isObjectEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      // 如果属性存在并且不为空，则返回 false
      return false
    }
  }
  return true
}
