import styled, { css } from 'styled-components'
import { transition, rotate, Media } from '../../theme'

const ButtonBase = css`
  cursor: pointer;
  text-decoration: none;
  font-size: ${p => p.theme.typography.textSmall};
  height: 36px;
  line-height: 36px;
  padding: 0 22px;
  position: relative;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
  white-space: nowrap;
  user-select: none;
  border-radius: 6px;
  background: ${p => p.disabled && `#ffd5c5 !important`};
  pointer-events: ${p => p.disabled && 'none !important'};
  border-radius: 20px;
  :active,
  :focus {
    outline: 0;
  }
  :not([disabled]):active {
    box-shadow: none;
  }
  :disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  ${p =>
    p.fullWidth &&
    css`
      width: 100% !important;
    `};
  ${p =>
    p.hasRadius &&
    css`
      min-width: 160px;
    `};
  &.btn-large {
    padding: 0 20px;
    min-width: 160px;
    height: 40px;
    line-height: 40px;
    font-size: ${p => p.theme.typography.h6};
    ${p => p.theme.Hack.en`min-width: 180px;`}
    ${Media.phone`
      font-size: ${p => p.theme.typography.textSmall};
      min-width: 2.9rem;
      height: .7rem;
      line-height: .7rem;
      border-radius: .35rem;
      padding: 0 15px;
    `}
  }
  &.btn-banner {
    padding: 0 20px;
    min-width: 160px;
    height: 40px;
    line-height: 40px;
    font-size: ${p => p.theme.typography.h6};
    ${p => p.theme.Hack.en`min-width: 180px;`}
    ${Media.phone`
      font-size: ${p => p.theme.typography.textSmall};
      min-width: 1.76rem;
      height: .53rem;
      border-radius: .35rem;
      padding: 0 0px;
      margin: auto;
      margin-left:.2rem;
    `}
  }
  &.btn-loading {
    svg {
      width: 1em;
      height: 1em;
      animation: ${rotate} 1s linear infinite;
      margin-right: 5px;
      path {
        fill: #fff;
      }
    }
  }
`
export const ContainedButton = styled.button`
  ${({ theme: { color } }) => css`
    ${ButtonBase};
    color: ${p => (p.color === 'default' ? 'white' : color.secondary)};
    background-size: 300% 100%;
    border: 0 solid transparent;
    background-color: ${p => (p.color === 'default' ? color.secondary : 'white')};
    transition: ${transition(['background-position', 'background-color'])};
    :hover {
      background-position: 100% 0;
      background-color: ${p => (p.color === 'default' ? '#dd501b' : '#ffdacc')};
    }
  `}
`
export const OutlinedButton = styled.button`
  ${({ theme: { color } }) => css`
    ${ButtonBase};
    background-image: none;
    color: ${p => (p.color === 'default' ? color.secondary : 'white')};
    background-color: transparent;
    border: 1px solid ${p => (p.color === 'default' ? color.secondary : 'white')};
    transition: ${transition(['background-color', 'color'])};
    :hover {
      color: ${p => (p.color === 'default' ? 'white' : color.secondary)};
      background-color: ${p => (p.color === 'default' ? color.secondary : 'white')};
    }
  `}
`
const PaginationStatus = ({ theme: { color }, isBan, active }) => css`
  cursor: ${isBan ? 'not-allowed' : 'pointer'};
  color: ${active ? 'white' : color.text};
  border: 1px solid ${active ? color.secondary : '#cccccc'};
  background-color: ${active ? color.secondary : 'transparent'};
  :hover {
    color: ${isBan ? color.text : 'white'};
    border: 1px solid ${isBan ? '#cccccc' : color.secondary};
    background-color: ${isBan ? 'transparent' : color.secondary};
    svg {
      color: ${isBan ? color.text : 'white'};
    }
  }
`

export const PaginationButton = styled.button`
  ${({ theme: { color, typography, spacing } }) => css`
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    margin: ${spacing.small};
    text-align: center;
    font-size: ${typography.text};
    ${PaginationStatus}
    margin: 5px;
    transition: ${transition(['color', 'border', 'background-color'])};
    svg {
      color: ${color.text};
    }
    :active,
    :focus {
      outline: 0;
    }
    user-select: none;
    ${Media.phone`
      min-width: .54rem;
      height: .54rem;
      font-size: .27rem;
    `}
  `}
`

export const CodeButton = styled.button`
  ${({ theme: { color } }) => css`
    ${ButtonBase};
    color: ${color.primary};
    height: 40px;
    line-height: 40px;
    width: 140px;
    border-radius: unset;
    background-size: 300% 100%;
    color: ${color.secondary} !important;
    border: 1px solid ${color.secondary};
    :disabled {
      cursor: not-allowed;
      color: #a9afc3 !important;
      border: 1px solid #d7dee2;
      background: white !important;
    }
  `}
`
