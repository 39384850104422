import React from 'react'
import { SelectWrap, SelectOption } from './atoms'
import { LocaleService, LOCALES } from '../../services'
import { color } from '../../theme'
const LanguageBar = () => {
  const hanldeLocaleChange = locale => {
    if (LocaleService.currentLocale !== locale) {
      LocaleService.changeLocale(locale)
    }
  }

  return (
    <SelectWrap>
      <SelectOption
        onClick={() => hanldeLocaleChange(LOCALES.EN)}
        style={{ color: LocaleService.currentLocale === LOCALES.EN ? color[LOCALES.EN].secondary : '' }}
      >
        English
      </SelectOption>
      <SelectOption
        onClick={() => hanldeLocaleChange(LOCALES.JA)}
        style={{
          color: LocaleService.currentLocale === LOCALES.JA ? color[LOCALES.JA].secondary : '',
          lineHeight: '24px',
        }}
      >
        日本語
      </SelectOption>
    </SelectWrap>
  )
}

export default LanguageBar
