import styled, { css } from 'styled-components'

export const SwipeNavBox = styled.div`
  width: 260px;
  height: 104px;
`

export const SpecialBoxImage = styled.img`
  width: 45px;
  height: 35px;
  margin-left: 194px;
  margin-top: -4px;
`

export const SwipeNavImage = styled.img`
  cursor: pointer;
  width: 240px; // 根据您的图片尺寸调整
  height: 88px; // 根据您的图片尺寸调整
  transition: all 0.3s ease;

  ${props =>
    props.active &&
    css`
      width: 260px;
      height: 100px;
      opacity: 1;
    `}
`

export const SpecialBox = styled.div`
  position: absolute;
  top: 0;
  p {
    position: absolute;
    right: 4px;
    top: -14px;
    font-family: Source Han Sans;
    font-size: 16px;
    font-weight: bold;
    line-height: 41px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: 0px;
    font-variation-settings: 'opsz' auto;
    color: rgba(255, 255, 255, 0.1);
  }
  h3 {
    position: absolute;
    right: 4px;
    top: -13px;
    font-family: Source Han Sans;
    font-size: 14px;
    font-weight: bold;
    line-height: 41px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: 0px;
    font-variation-settings: 'opsz' auto;
    color: #ffffff;
  }
`
