import React from 'react'
import styled from 'styled-components'
import { range } from '@raysync/common/utils'
import { SwipeNav } from '@raysync/common/components/swiper/atoms'
import { SpecialBox, SwipeNavBox, SpecialBoxImage, SwipeNavImage } from './atoms'
import newIcon from './new.png'
import { Wrapper } from '@raysync/common/components'
import videoSmallBanner from '@raysync/main/src/images/video_smallbanner.png'
import ferrySmallBanner from '@raysync/main/src/images/Ferry_smallBanner.png'
import ftpSmallBanner from '@raysync/main/src/images/ftp_smallBanner.png'
import collaborationSmallBanner from '@raysync/main/src/images/collaboration_smallBanner.png'
import redhatSmallBanner from '@raysync/main/src/images/redhat_smallBanner.png'

const navImages = [videoSmallBanner, ferrySmallBanner, ftpSmallBanner, collaborationSmallBanner, redhatSmallBanner]

const SwipeNavWrap = styled(Wrapper)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  gap: 25px;
  bottom: 20px;
  justify-content: center;
  z-index: 2;
`
const SwipeNavigation = ({ children, index, activedColorIndex, handleChangeIndex }) => {
  return (
    <SwipeNavWrap>
      {range(children.length).map(i => (
        <SwipeNavBox key={i}>
          <SwipeNav key={i} active={i === index} onClick={() => handleChangeIndex(i)}>
            {i === 1 && (
              <SpecialBox>
                <SpecialBoxImage src={newIcon} />
                <p>NEW</p>
                <h3>热！</h3>
              </SpecialBox>
            )}
            {i === 2 && (
              <SpecialBox>
                <SpecialBoxImage src={newIcon} />
                <p>NEW</p>
                <h3>热！</h3>
              </SpecialBox>
            )}
            {i === 3 && (
              <SpecialBox>
                <SpecialBoxImage src={newIcon} />
                <p>NEW</p>
                <h3>新！</h3>
              </SpecialBox>
            )}
            <SwipeNavImage src={navImages[i]} active={i === index} />
          </SwipeNav>
        </SwipeNavBox>
      ))}
    </SwipeNavWrap>
  )
}

export default SwipeNavigation
