import styled from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
import Bg from './bg.png'
import SupportBg from './support_bg.png'
import Step from './step.png'

export const BannerWrap = styled(Wrapper)`
  height: 100%;
  padding-top: 110px;
  color: ${p => p.theme.color.textDarkPlus};
  span {
    color: ${p => p.theme.color.secondary};
    font-size: 30px;
    line-height: 1;
  }
  h1 {
    font-weight: bold;
    line-height: 1.5;
    font-size: 48px;
    margin: 25px 0 25px;
  }
  p {
    line-height: 1.5;
    max-width: 580px;
    margin-bottom: 30px;
  }
  ${Media.phone`
    height: 9rem;
    padding: 1.1rem .5rem 0;
    span {
      font-size: .3rem;
    }
    h1 {
      font-size: .54rem;
      margin: .3rem 0;
    }
    p {
      margin-bottom: .3rem;
      max-width: 5.8rem;
    }
  `}
`
export const BannerContainer = styled.div`
  position: relative;
  height: 500px;
  overflow: hidden;
  z-index: 0;
  background-color: ${p => p.theme.color.panel};
  ${Media.phone`
    height: 9rem;
  `}
`
export const ExampleWrapper = styled(Wrapper)`
  padding-bottom: 90px;
  ${Media.phone`
    padding-bottom: 0.9rem;
  `}
`
export const SubTitle = styled.div`
  color: ${p => p.theme.color.secondary};
  font-size: 20px;
  ${Media.phone`
    font-size: .3rem;
  `}
`
export const SectionWrap = styled(Wrapper)`
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;
  color: ${p => p.theme.color.textDarkPlus};
  padding-right: 20px;
  div {
    max-width: 544px;
    h2 {
      margin: 22px 0 34px;
      font-size: 36px;
      font-weight: bold;
    }
    p {
      margin-bottom: 22px;
      line-height: 1.5;
    }
  }
  ${Media.phone`
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-top: .54rem;
    padding-bottom: .24rem;
    img {
      width: 100%;
    }
    div {
      h2 {
        margin: .22rem 0 .34rem;
        font-size: .4rem;
      }
      p {
        font-size: .24rem;
        margin-bottom: .3rem;
      }
    }
  `}
`
export const ApplySection = styled.div`
  background: url(${Bg});
  height: 1160px;
  padding: 100px 0;
  color: ${p => p.theme.color.textDarkPlus};
  > div {
    h2 {
      font-weight: bold;
    }
    > p {
      font-size: 18px;
      font-weight: bold;
      margin: 40px 0 28px;
    }
    ${SubTitle} {
      margin-bottom: 20px;
    }
  }
  ${Media.phone`
    background:#F4F4F4;
    height: unset;
    padding-top: .8rem;
    padding-bottom: .4rem;
    > div {
      h2 {
        font-size: .4rem;
      }
      > p {
        font-size: .3rem;
        margin: .26rem 0 .3rem;
      }
    }
  `}
`
export const SupportList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const SupportItem = styled.div`
  width: 280px;
  height: 340px;
  box-shadow: 0px 6px 30px 0px #eeeeee;
  border-radius: 10px;
  background: #fff;
  ${Media.phone`
    width: 3.2rem;
    height: 4.4rem;
    box-shadow: 0px 6px .3rem 0px #eeeeee;
    border-radius: .1rem;
    margin-bottom: .3rem;
  `}
`
export const SupportHeader = styled.div`
  position: relative;
  height: 120px;
  background: url(${SupportBg}) 100% 100% / 100% 100%;
  padding: 0 38px 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    border-radius: 0px 10px 0px 10px;
    background: ${p => p.theme.color.secondary};
  }
  ${Media.phone`
    height: 1.38rem;
    padding: 0 .54rem 0 .36rem;
    h3 {
      font-size: .36rem;
    }
    img {
      width: .48rem;
      height: .48rem;
    }
    span {
      width: .6rem;
      height: .3rem;
      border-radius: 0px .1rem 0px .1rem;
      font-size: .2rem;
      line-height: .3rem;
    }
  `}
`
export const SupportContent = styled.div`
  padding: 26px 30px;
  color: #636772;
  line-height: 1.6;
  ${Media.phone`
    padding: .24rem;
    font-size: .24rem;
  `}
`

export const ProcessWrapper = styled(Wrapper)`
  background: #ffffff;
  box-shadow: 0px 6px 30px 0px #eeeeee;
  border-radius: 20px;
  padding: 40px 60px 50px;
  button {
    display: block;
    margin: 40px auto 0;
  }
  ${Media.phone`
    padding: .4rem .5rem .5rem;
    width: 100%;
    button {
      margin-top: .1rem;
    }
  `}
`
export const ProcessList = styled.div`
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    flex-wrap:wrap;
  `}
`
export const ProcessItem = styled.div`
  text-align: center;
  font-size: 18px;
  p {
    line-height: 1;
    margin: 20px 0 14px;
    color: #636772;
  }
  h4 {
    font-weight: bold;
    color: ${p => p.theme.color.textDarkPlus};
  }
  ${Media.phone`
    width: 100%;
    display: flex;
    align-items: center;
    margin: .42rem 0;
    img {
      width: .88rem 
      height: 1.02rem;
    }
    p {
      font-size: .24rem;
      margin: 0 .4rem 0 .3rem;
    }
    h4 {
      font-size: .36rem;
    }
  `}
`
export const StepDiv = styled.div`
  width: 40px;
  height: 12px;
  background: url(${Step}) no-repeat;
  margin-top: 44px;
  ${Media.phone`
    margin: unset;
    margin-left: .04rem;
    transform: rotate(90deg) ;
  `}
`
export const ApplyWrap = styled.div`
  padding: 20px 60px;
  text-align: center;
  h2 {
    font-weight: bold;
  }
  h5 {
    text-align: left;
    margin-bottom: 14px;
  }
  p {
    margin: 14px 0 18px;
    color: #636772;
  }
  button {
    display: block;
    margin: 0 auto;
  }
  ${Media.phone`
    padding: .4rem .55rem;
    h2 {
      font-size: .36rem;
    }
    h5 {
      font-size: .24rem;
      margin-bottom: .2rem;
    }
    p {
      font-size: .24rem;
      margin-bottom: .4rem;
    }
    button {
      width: 100%;
      border-radius: 4px;
      height: .7rem;
      line-height: .7rem;
    }
  `}
`
export const ButtonGroup = styled.div`
  display: flex;
  button {
    margin-left: 14px;
    color: #a9afc3;
    height: 44px;
    line-height: 44px;
  }
  ${Media.phone`
    button {
      margin-left: .14rem;
      height: .7rem;
      line-height: .7rem;
      padding: 0 .18rem;
    }
  `}
`
export const TextAreaContainer = styled.div`
  padding-left: 14px;
  label {
    display: block;
    line-height: 1;
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left;
    color: ${p => p.theme.color.textDarkPlus};
  }
  ${Media.phone`
    padding-left: 0;
    label {
      font-size: .24rem;
      margin-bottom: .08rem;
    }
    textarea {
      height: 1.8rem;
      padding: 0.16rem;
      margin-bottom: .6rem;
    }
  `}
`

export const CodeWrap = styled.div`
  display: flex;
  width: 100%;
`
export const SuccessContent = styled.div`
  width: 380px;
  img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 18px auto;
  }
  h4 {
    font-weight: bold;
    text-align: center;
    font-size: 24px;
  }
  p {
    text-align: center;
    margin: 10px 0 26px;
  }
  button {
    display: block;
    margin: 0 auto;
  }
`
