import React from 'react'

const SvgScene5 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='scene5_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene5_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene5_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='scene5_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={20}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene5_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={12}
        height={12.62}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path
      d='M24 22a2 2 0 002 2h16a2 2 0 002-2V4a2 2 0 00-2-2H29.5L24 7.5V22z'
      fill='url(#scene5_svg__a)'
      transform='matrix(-1 0 0 1 48 0)'
    />
    <g transform='matrix(-1 0 0 1 40 0)' filter='url(#scene5_svg__b)'>
      <path d='M20 20a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2H25.5L20 5.5V20z' fill='url(#scene5_svg__c)' />
      <path
        d='M38 0H25.5L20 5.5V20a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2zM21 20q0 .414.293.707T22 21h16q.414 0 .707-.293T39 20V2q0-.414-.293-.707T38 1H26v5h-5v14zm4-18.086L21.914 5H25V1.914z'
        fillRule='evenodd'
        fill='url(#scene5_svg__d)'
      />
    </g>
    <g filter='url(#scene5_svg__e)'>
      <path
        d='M6 11.15h8q.074 0 .146-.015.073-.014.141-.042.068-.028.13-.07.061-.04.113-.093.053-.052.094-.113.04-.062.069-.13.028-.068.043-.14.014-.073.014-.147t-.014-.146q-.015-.073-.043-.141-.028-.068-.07-.13-.04-.061-.093-.113-.052-.053-.113-.094-.062-.04-.13-.069-.068-.028-.14-.043-.073-.014-.147-.014H6q-.074 0-.146.014-.073.015-.141.043-.068.028-.13.07-.061.04-.113.093-.053.052-.094.113-.04.062-.069.13-.028.068-.043.14-.014.073-.014.147t.014.146q.015.073.043.14.028.07.07.13.04.062.093.114.052.052.113.093.062.042.13.07.068.028.14.042.073.015.147.015z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M14.482 9.825l-2.86-2.4q-.101-.085-.225-.13-.125-.045-.257-.045-.033 0-.065.003-.149.013-.281.082-.132.069-.228.183-.085.101-.13.225-.046.125-.046.257 0 .033.003.065.013.149.082.281.069.133.183.229l2.86 2.4q.101.085.226.13.124.045.256.045.033 0 .065-.003.15-.013.281-.082.133-.069.229-.183.085-.101.13-.225.045-.125.045-.257 0-.033-.003-.065-.013-.149-.082-.281-.068-.133-.183-.229z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <g>
        <path
          d='M6 14.98h8q.074 0 .146-.014.073-.015.141-.043.068-.028.13-.07.061-.04.113-.093.053-.052.094-.113.04-.062.069-.13.028-.068.043-.14.014-.073.014-.147t-.014-.146q-.015-.073-.043-.141-.028-.068-.07-.13-.04-.061-.093-.113-.052-.053-.113-.094-.062-.04-.13-.069-.068-.028-.14-.043-.073-.014-.147-.014H6q-.074 0-.146.014-.073.015-.141.043-.068.028-.13.07-.061.04-.113.093-.053.052-.094.113-.04.062-.069.13-.028.068-.043.14-.014.073-.014.147t.014.146q.015.073.043.141.028.068.07.13.04.061.093.113.052.053.113.094.062.04.13.069.068.028.14.043.073.014.147.014z'
          fillRule='evenodd'
          fill='#FFF'
        />
        <path
          d='M9.342 16.045l-2.86-2.4q-.101-.085-.225-.13-.125-.045-.257-.045-.033 0-.065.003-.149.013-.281.081-.133.07-.229.184-.085.1-.13.225-.045.124-.045.257 0 .032.003.065.013.149.082.281.069.132.183.228l2.86 2.4q.101.085.225.13.125.046.257.046.033 0 .065-.003.149-.013.281-.082.133-.069.229-.183.085-.101.13-.226.045-.124.045-.256 0-.033-.003-.066-.013-.148-.082-.28-.069-.133-.183-.229z'
          fillRule='evenodd'
          fill='#FFF'
        />
      </g>
    </g>
  </svg>
)

export default SvgScene5
