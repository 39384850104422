import React from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { LgWrapper, Button } from '@raysync/common/components'
import { Navigation } from '@raysync/common/services'
import { Media } from '@raysync/common/theme'
import { BannerTitle, BannerDesc, BannerRight, BannerLeft, BannerImg } from '../atoms'
import { MainBannerContainer, MainBannerButton } from './atoms'
export const BannerWrap = styled(LgWrapper)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0;
  `}
`
const MainBanner = ({ type, imgSrc }) => (
  <MainBannerContainer>
    <BannerWrap>
      <BannerLeft>
        <BannerTitle>{intl.getHTML(`${type}.banner.title`)}</BannerTitle>
        <BannerDesc>{intl.getHTML(`${type}.banner.desc`)}</BannerDesc>
        <MainBannerButton
          rel='nofollow'
          onClick={() => {
            Navigation.toApply({ type: 'apply' })
          }}
        >
          {intl.get(`${type}.banner.button`)}
        </MainBannerButton>
      </BannerLeft>
      <BannerRight>{imgSrc && <BannerImg src={imgSrc} />}</BannerRight>
    </BannerWrap>
  </MainBannerContainer>
)
export default MainBanner
