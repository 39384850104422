import styled, { css } from 'styled-components'

function getWidthString(size) {
  if (!size) {
    throw new Error('size is a required parameter')
  }
  const width = `${Math.round((size / 12) * 10e7) / 10e5}%`
  return css`
    flex-basis: ${width};
    max-width: ${width};
  `
}
const Column = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  ${({ xs }) =>
    xs
      ? getWidthString(xs)
      : css`
          flex-basis: 100%;
          max-width: 100%;
        `};
  @media (min-width: 1280px) {
    ${({ lg }) => lg && getWidthString(lg)};
  }
`

export default Column
