import React from 'react'
import { useMedia } from '../hooks'
export const ShowAtMobile = ({ children }) => {
  const isMobile = useMedia()
  return <>{isMobile && children}</>
}
export const HideAtMobile = ({ children }) => {
  const isMobile = useMedia()
  return <>{!isMobile && children}</>
}
