import React from 'react'

const SvgScene2 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='scene2_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene2_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene2_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene2_svg__f'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene2_svg__g'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='scene2_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={16}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene2_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={10}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene2_svg__h'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={17}
        height={14}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
      <filter
        id='scene2_svg__i'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={8}
        height={5}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path
      d='M19 11h2q.099 0 .195.02.097.018.188.056.09.038.173.093.081.054.151.124.07.07.124.151.055.082.093.173.038.091.057.188.019.097.019.195v8q0 .099-.02.195-.018.097-.056.188-.038.09-.093.173-.054.081-.124.151-.07.07-.151.124-.082.055-.173.093-.091.038-.188.057Q21.1 21 21 21h-2v1a2 2 0 01-2 2H5a2 2 0 01-2-2v-1H1q-.098 0-.195-.02-.097-.018-.188-.056-.09-.038-.173-.093-.081-.054-.151-.124-.07-.07-.124-.151-.055-.082-.093-.173-.038-.091-.057-.188Q0 20.1 0 20v-8q0-.098.02-.195.018-.097.056-.188.038-.09.093-.173.054-.081.124-.151.07-.07.151-.124.082-.055.173-.093.091-.038.188-.057Q.902 11 1 11h2V4a2 2 0 012-2h8.5L19 7.5V11z'
      fillRule='evenodd'
      fill='url(#scene2_svg__a)'
    />
    <g filter='url(#scene2_svg__b)' fillRule='evenodd'>
      <path
        d='M19 22a2 2 0 002-2v-2H5v2a2 2 0 002 2h12zM5 10V2a2 2 0 012-2h8.5L21 5.5V10H5z'
        fill='url(#scene2_svg__c)'
      />
      <path
        d='M5 9v1h16V5.5L15.5 0H7a2 2 0 00-2 2v7zm1 0h14V5.914L15.086 1H7q-.414 0-.707.293T6 2v7zm15 11a2 2 0 01-2 2H7a2 2 0 01-2-2v-2h16v2zm-1-1v1q0 .414-.293.707T19 21H7q-.414 0-.707-.293T6 20v-1h14z'
        fill='url(#scene2_svg__d)'
      />
    </g>
    <g transform='matrix(-1 0 0 1 48 0)' filter='url(#scene2_svg__e)'>
      <rect x={24} y={9} width={22} height={10} rx={1} fill='url(#scene2_svg__f)' />
      <rect x={24.5} y={9.5} width={21} height={9} rx={0.5} stroke='url(#scene2_svg__g)' />
    </g>
    <g filter='url(#scene2_svg__h)'>
      <path
        d='M11.772 11.4H7.388v1.028h2.9l-2.908 3.7V17h4.392v-1.028H8.864l2.908-3.7V11.4zm2.132 0h-1.116V17h1.116v-5.6zm3.564 3.552c1.328 0 2.04-.696 2.04-1.772 0-1.084-.712-1.78-2.048-1.78h-2.284V17h1.116v-2.048h1.176zm-.028-2.524c.608 0 .924.252.924.752 0 .504-.316.752-.924.752h-1.148v-1.504h1.148z'
        fill='#FFF'
      />
    </g>
    <g filter='url(#scene2_svg__i)'>
      <rect x={7} y={4} width={4} height={1} rx={0.5} fill='#FFF' />
    </g>
  </svg>
)

export default SvgScene2
