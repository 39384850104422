import React from 'react'

const SvgToastError = props => (
  <svg viewBox='0 0 20 20' {...props}>
    <path
      d='M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm4.95 13.54l-1.41 1.41L10 11.41l-3.54 3.54-1.41-1.41L8.59 10 5.05 6.46l1.41-1.41L10 8.59l3.54-3.54 1.41 1.41L11.41 10l3.54 3.54zm0 0'
      fill='#eb5c27'
    />
  </svg>
)

export default SvgToastError
