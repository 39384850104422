import styled, { css } from 'styled-components'
import { Anchor } from '../atoms'
import { transition } from '../../theme'
import { LocaleService } from '../../services'

export const ContactBarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 80%;
  transform: translateY(-50%);
  right: 30px;
  cursor: pointer;
  z-index: 9999;
  @media (max-width: 768px) {
    display: none;
  }
`
export const PopoverContainer = styled.div`
  display: none;
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 180px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 8px 5px;
  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 10px auto;
  }
  > div {
    padding: 10px 0;
  }
`
export const PopoverText = styled.p`
  ${({ theme: { color, typography } }) => css`
    color: ${color.text};
    line-height: 1;
    font-size: ${typography.textThin};
    padding-top: ${p => p.top + 'px'};
    padding-bottom: ${p => p.bottom + 'px'};
    text-align: center;
  `}
`
export const NavAnchor = styled(Anchor)`
  ${({ theme: { typography } }) => css`
    display: block;
    width: ${() => (LocaleService.isForeign ? '192px' : '212px')};
    text-align: center;
    font-size: ${typography.textMini};
  `}
`

export const ContactItem = styled.div`
  ${({ theme: { color } }) => css`
    position: relative;
    display: flex;
    /* justify-content: ${p => (p.hasText ? 'space-between' : 'center')}; */
    justify-content: center;
    align-items: center;
    /* width: ${p => (p.hasText ? '280px' : '51px')}; */
    width: 51px;
    height: 51px;
    background: #ffffff;
    border: 1px solid #f3f6f7;
    border-radius: 6px;
    /* padding: ${p => (p.hasText ? '0px 25px 0px 20px' : '0')}; */
    transition: ${transition()};
    cursor: pointer;
    /* min-width: 280px; */
    overflow: hidden;

    a{
      font-size: 14px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
    .hover-container{
      width: ${LocaleService.isForeign ? '218px' : ''};
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-12px);
      svg{
        margin-right: 22px;
      }
    }
    :not(:first-child) {
      margin-top: 9px;
    }
    :nth-child(4) {
      background: #201f26;
      opacity: 0.6;
      border: none;
      svg {
        width: 24px;
        height: 12px;
      }
    }
    :hover {
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
      width: ${p => (p.hasText ? (LocaleService.isForeign ? '280px' : '300px') : '51px')};
      .hover-container{
        position: relative;
        transform: none;
        left: 0;
      }
      a {
        color: #fd5c1f !important;
      }
      svg path {
        fill: ${color.secondary};
      }
      ${PopoverContainer} {
        display: flex;
        justify-content: center;
      }
    }
  `}
`
