import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PaginationPageGroup from './pagination-group'
import PaginationButton from './pagination-button'
import { PaginationType } from './pagination-type'
import { Icon, IconType } from '../icon'
import Flex from '../flex'

const PaginationContainer = styled(Flex)`
  flex-wrap: wrap;
`
export const TargetTypeContext = createContext(PaginationType.Dynamic)

class Pagination extends Component {
  render() {
    const { currentPage, total, type, onChange } = this.props
    return (
      <TargetTypeContext.Provider value={{ type, onChange }}>
        <PaginationContainer>
          <PaginationButton type='previous' total={total} isBan={currentPage === 1}>
            <Icon type={IconType.ArrowLeft} />
          </PaginationButton>
          <PaginationPageGroup total={total} currentPage={currentPage} />
          <PaginationButton type='next' total={total} isBan={currentPage === total}>
            <Icon type={IconType.ArrowRight} />
          </PaginationButton>
        </PaginationContainer>
      </TargetTypeContext.Provider>
    )
  }
}
//TODO: jump to
Pagination.propTypes = {
  isNeedJumpTo: PropTypes.bool,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  type: PropTypes.oneOf(Object.values(PaginationType)),
}
Pagination.defaultProps = {
  isNeedJumpTo: false,
}
export default Pagination
