import { LOCALES } from '../services'

export const spacing = Object.freeze({
  small: '10px',
  base: '20px',
  large: '30px',
})

export const typography = {
  [LOCALES.ZH]: {
    h1: '48px',
    h2: '30px',
    h3: '24px',
    h4: '18px',
    text: '16px',
    textSmall: '14px',
    textThin: '12px',
    textMini: '10px',
  },
  [LOCALES.JA]: {
    h1: '48px',
    h2: '30px',
    h3: '24px',
    h4: '18px',
    text: '16px',
    textSmall: '14px',
    textThin: '12px',
    textMini: '10px',
  },
  [LOCALES.EN]: {
    h1: '70px',
    h2: '36px',
    h3: '26px',
    h4: '20px',
    text: '18px',
    textSmall: '16px',
    textThin: '16px',
    textMini: '14px',
  },
}

export const color = {
  [LOCALES.ZH]: {
    primary: '#182940',
    secondary: '#fd5c1f',
    text: '#8796aa',
    textDarkPlus: '#252A3A',
    textDark: '#333333',
    textMiddle: '#666666',
    textLight: '#999999',
    divider: '#343B51',
    panel: '#f5f7fb',
    line: '#d7dee2',
    base: '#FFFFFF',
    error: '#ff4d4f',
  },
  [LOCALES.JA]: {
    primary: '#182940',
    secondary: '#fd5c1f',
    text: '#8796aa',
    textDarkPlus: '#252A3A',
    textDark: '#333333',
    textMiddle: '#666666',
    textLight: '#999999',
    divider: '#343B51',
    panel: '#f5f7fb',
    line: '#d7dee2',
    base: '#FFFFFF',
    error: '#ff4d4f',
  },
  [LOCALES.EN]: {
    primary: '#182940',
    secondary: '#fd5c1f',
    text: '#5A6E78',
    textDarkPlus: '#252A3A',
    textDark: '#333333',
    textMiddle: '#666666',
    textLight: '#999999',
    divider: '#343B51',
    panel: '#f5f7fb',
    line: '#d7dee2',
    base: '#FFFFFF',
    error: '#ff4d4f',
  },
}

export const desktopTheme = {
  typography,
  color,
  spacing,
}

export const mobileTheme = {
  typography: {
    h1: '48px',
    h2: '30px',
    h3: '24px',
    h4: '18px',
    text: '16px',
    textSmall: '14px',
    textThin: '12px',
    textMini: '10px',
  },
  color: {
    primary: '#182940',
    secondary: '#fd5c1f',
    text: '#8796aa',
    textDarkPlus: '#252A3A',
    textDark: '#333333',
    textMiddle: '#666666',
    textLight: '#999999',
    divider: '#343B51',
    panel: '#f5f7fb',
    line: '#d7dee2',
    base: '#FFFFFF',
    error: '#ff4d4f',
  },
  spacing,
}
