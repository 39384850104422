import React from 'react'
import styled from 'styled-components'
import { color, transition, Media } from '../../theme'

const CloseWrap = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 8px;
  transform: rotate(90deg);
  path {
    stroke-width: 3;
    stroke-linecap: round;
    stroke: ${color.primary};
  }
  #mask {
    opacity: 1;
    stroke: url(#colorTone);
    transition: ${transition(['opacity'])};
  }
  svg {
    display: inline-block;
    color: ${color.primary};
  }
  ${Media.phone`
    display:grid;
  `}
`
class Close extends React.PureComponent {
  render() {
    return (
      <CloseWrap onClick={this.props.onClick}>
        <svg viewBox='0 0 30 30'>
          <defs>
            <linearGradient id='colorTone'>
              <stop offset='0' stopColor={color.primary} />
              <stop offset='1' stopColor={color.primaryLight} />
            </linearGradient>
          </defs>
          <path d='M 7,7 L 23,23 M 23,7 L 7,23' />
          <path id='mask' d='M 7,7 L 23,23 M 23,7 L 7,23' />
        </svg>
      </CloseWrap>
    )
  }
}

export default Close
