import React from 'react'
import intl from 'react-intl-universal'
import { Navigation } from '@raysync/common/services'
import { Billboard } from '@raysync/common/components'
import Hotline from '@raysync/common/components/hotline'

import { BannerContainer, BannerWrap, BannerTitle, BannerDesc, BannerButton, BannerLeft } from './atoms'
const IndustryBanner = ({ type, data }) => (
  <BannerContainer>
    <BannerWrap>
      <BannerLeft>
        <BannerTitle>{intl.get(`${type}.banner.title`)}</BannerTitle>
        <BannerDesc>{intl.getHTML(`${type}.banner.desc`)}</BannerDesc>
        <BannerButton
          onClick={() => {
            Navigation.toApply({ type: 'solution', pageType: type })
          }}
        >
          {intl.get(`${type}.banner.button`)}
        </BannerButton>
        <Hotline />
      </BannerLeft>
    </BannerWrap>
    {data && <Billboard fluid={data} />}
  </BannerContainer>
)
export default IndustryBanner
