import React from 'react'
import { Media } from '@raysync/common/theme'
import styled, { css } from 'styled-components'

export const Input = styled.input`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    font-size: ${typography.textSmall};
    border: 1px solid ${color.line};
    padding-left: 12px;
    display: block;
    width: 100%;
    ::placeholder {
      font-size: ${typography.textSmall};
      color: ${color.text};
      opacity: 1;
    }
    ${p =>
        p.isError &&
        css`
          border-color: red;
        `}
      :focus {
      outline: none;
    }
    height: 44px;
  `}
`
const TextInputWrap = styled.div`
  width: 100%;
  > span {
    display: block;
    min-height: 18px;
    line-height: 18px;
    color: ${p => p.theme.color.red};
    text-align: left;
    font-size: 12px;
    color: red;
  }
  ${Media.phone`
    input {
      height: .7rem;
      line-height: .7rem;
    }
    span {
     min-height: .3rem;
     line-height: 1.2;
     font-size: .18rem;
    }
  `}
`

export const TextInput = ({ errorMessage, ...inputProps }) => {
  const isError = Boolean(errorMessage)
  return (
    <TextInputWrap>
      <Input {...inputProps} isError={isError}></Input>
      <span>{isError ? errorMessage : null}</span>
    </TextInputWrap>
  )
}
