import { useState, useEffect } from 'react'
/* eslint-disable */
export default function useLazyImg({ imgUrl, errorUrl, target }) {
  const [imgSrc, setImgSrc] = useState('')
  const onError = () => setImgSrc(errorUrl)
  useEffect(() => {
    if ('IntersectionObserver' in window && target && target.current instanceof Element) {
      if (imgUrl !== imgSrc) {
        const lazyImageObserver = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              setImgSrc(imgUrl)
              lazyImageObserver.unobserve(entry.target)
            }
          })
        })
        lazyImageObserver.observe(target.current)
      } else {
        setImgSrc(imgUrl)
      }
    }
  }, [imgSrc, imgUrl])
  return { imgSrc, onError }
}
