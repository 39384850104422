import { navigate as gatsbyNavigate } from 'gatsby'
import { LocaleService, LOCALES } from './locale-service'

class Navigation {
  static getLink(action, params) {
    return `/${action}${Navigation.serialize(params)}`
  }
  static to({ link, isInternal = true, ...rest }) {
    if (typeof window === 'undefined') return
    const currentLocale = LocaleService.getCurrentLocale()
    const currentLink = LocaleService.isForeign && currentLocale !== LOCALES.EN ? `/${currentLocale}${link}` : link
    if (isInternal) {
      gatsbyNavigate(currentLink, { state: { ...rest } })
    } else {
      window.location.href = currentLink
    }
  }
  static toApply(params) {
    const ApplyPageMap = {
      A: 'apply/',
      B: 'apply1',
    }
    const link = Navigation.getLink(ApplyPageMap['A'])
    Navigation.to({ link: '/apply/', ...params })
  }
  static toSolution(action, params) {
    if (!action) return
    const link = Navigation.getLink(`solutions/${action}`, params)
    Navigation.to({ link, ...params })
  }
  static toProduct(action, params) {
    if (!action) return
    const link = Navigation.getLink(`products/${action}`, params)
    Navigation.to({ link, ...params })
  }
  static getParam(key) {
    if (typeof window === 'undefined') return
    if (!key) return
    const url = new URL(window.location)
    var params = new URLSearchParams(url.search)
    return params.get(key)
  }

  static serialize(obj) {
    if (typeof obj !== 'object') return ''
    return Reflect.ownKeys(obj).reduce((acc, key) => {
      return !!acc ? `${acc}&${key}=${encodeURIComponent(obj[key])}` : `?${key}=${encodeURIComponent(obj[key])}`
    }, '')
  }
}

export default Navigation
