import PropTypes from 'prop-types'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  flex-grow: ${p => p.grow};
  flex-basis: ${p => p.basis};
  flex-shrink: ${p => p.shrink};
  flex-direction: ${p => p.direction};
  align-items: ${p => (p.direction === 'row' ? p.valign : p.halign)};
  justify-content: ${p => (p.direction === 'row' ? p.halign : p.valign)};
`

Flex.propTypes = {
  grow: PropTypes.number,
  basis: PropTypes.string,
  shrink: PropTypes.number,
  halign: PropTypes.string,
  valign: PropTypes.string,
  direction: PropTypes.string,
}

Flex.defaultProps = {
  grow: 0,
  shrink: 1,
  basis: 'auto',
  direction: 'row',
  halign: 'center',
  valign: 'center',
}

export default Flex
