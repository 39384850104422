import React from 'react'

const SvgSkype = props => (
  <svg viewBox='0 0 20 20' {...props}>
    <path
      d='M19.05 11.29c.05-.41.09-.82.09-1.24C19.14 5 15.03.91 9.96.91c-.5 0-1 .04-1.48.12A5.482 5.482 0 00.88 8.6c-.08.47-.11.95-.11 1.44 0 5.05 4.11 9.14 9.18 9.14.57 0 1.13-.06 1.67-.15.84.52 1.83.83 2.9.83 3.03 0 5.48-2.45 5.48-5.48 0-1.15-.35-2.21-.95-3.09zm-8.24 5.49c-2.92.15-4.28-.49-5.53-1.67-1.4-1.31-.84-2.81.3-2.88 1.14-.08 1.82 1.29 2.43 1.67.61.38 2.91 1.24 4.13-.15 1.33-1.52-.88-2.3-2.5-2.54-2.32-.35-5.24-1.6-5.01-4.06.23-2.46 2.09-3.73 4.06-3.91 2.5-.23 4.13.38 5.42 1.48 1.49 1.27.68 2.69-.27 2.81-.94.11-2.01-2.09-4.09-2.13-2.15-.03-3.6 2.24-.95 2.89 2.65.64 5.5.91 6.52 3.34 1.03 2.42-1.59 5-4.51 5.15zm0 0'
      fill='#8896a8'
    />
  </svg>
)

export default SvgSkype
