import React from 'react'
import intl from 'react-intl-universal'
import SEO from './seo'
import Helmet from 'react-helmet'
import Header from '../components/header'
import { EnHeader } from '../components/header'
import Footer from '../components/footer'
import { EnFooter } from '../components/footer'
import ContactBar from '../components/contact-bar'
import GlobalStyle from '../theme/global-style'
import styled, { ThemeProvider } from 'styled-components'
import Scripts from './scripts'
import { mobileTheme, Media, typography, color, spacing, Hack } from '../theme'
import { useMedia } from '../hooks'
import { LOCALES } from '../services/locale-service'
import MobileConcact from './contact-bar/mobile-concact-bar'
import Disclaimer from './disclaimer'
import { ShowAtMobile } from './phone'
const Main = styled.main`
  padding-top: ${p => (p.filled ? '0' : '60px')};
  min-height: calc(100vh - 366px);
  ${Media.phone`
    padding-top: ${p => (p.filled ? '0' : '.9rem')};
  `}
`
const H1 = styled.h1`
  text-indent: -9999;
  visibility: hidden;
  height: 0;
  width: 0;
`
const isInMain = process.env.GATSBY_PACKAGE_NAME === 'main'

const Layout = ({
  children,
  location,
  footerStyle,
  isWithoutFooter,
  isWithoutHeader,
  isWithoutConcactBar,
  friendLinks,
  pageType,
  path,
  scaled,
  filled,
  blog,
  showH1 = true,
  isTransparent = false,
  showBanner = false,
}) => {
  const isMobile = useMedia()
  const locale = intl.getInitOptions().currentLocale
  const theme = isMobile ? mobileTheme : { typography: typography[locale], color: color[locale], spacing }

  const getFooter = () => {
    if (locale !== LOCALES.ZH) {
      return <EnFooter friendLinks={friendLinks} showBanner={showBanner} location={location} pageType={pageType} />
    }
    return <Footer blog={blog} friendLinks={friendLinks} style={footerStyle} pageType={pageType} />
  }

  const lang = {
    [LOCALES.ZH]: 'zh',
    [LOCALES.EN]: 'en',
    [LOCALES.JA]: 'ja',
  }[locale]

  return (
    <ThemeProvider theme={{ ...theme, Hack: new Hack(locale) }}>
      <Scripts pageType={pageType} path={path || location.pathname} />
      <Helmet
        htmlAttributes={{
          lang,
        }}
      >
        <meta name='msvalidate.01' content='CD87AF6F79D15C287D6191524408F1FA' />
        <meta name='viewport' content={`width=device-width, initial-scale=${scaled ? 0 : 1}`} />
        {/* {locale !== LOCALES.ZH && <link rel='preload' href='/Roboto.ttf' as='font' type='font/ttf' crossorigin />} */}
      </Helmet>
      {pageType && (
        <SEO
          title={intl.get(`${pageType}.seo.title`)}
          keywords={intl.get(`${pageType}.seo.keywords`)}
          description={intl.get(`${pageType}.seo.desc`)}
        />
      )}
      {isInMain && showH1 && intl.get(`${pageType}.seo.h1`) && <H1>{intl.get(`${pageType}.seo.h1`)}</H1>}
      {!isWithoutHeader && <Header location={location} pageType={pageType} />}
      <Main type={pageType} filled={filled}>
        {children}
      </Main>
      {!isWithoutFooter && getFooter()}
      {locale !== LOCALES.ZH && <Disclaimer />}
      {!isWithoutConcactBar ? (
        <>
          <ContactBar />
          <ShowAtMobile>
            <MobileConcact />
          </ShowAtMobile>
        </>
      ) : null}

      <GlobalStyle></GlobalStyle>
    </ThemeProvider>
  )
}

export default Layout
