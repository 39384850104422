import pay from './pay'
import apply from './apply'
import email from './email'
const API = {
  pay,
  apply,
  email,
}

export default API
