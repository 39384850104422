import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { color } from '../theme'
import { H1, Flex, Button } from './index'
import intl from 'react-intl-universal'
const NotFoundContainer = styled(Flex)`
  margin-top: 70px;
  position: relative;
  min-height: 60vh;
`

const NotFoundContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  h1 {
    font-size: 30px;
    font-weight: 500;
    color: ${color.primary};
    margin-bottom: 20px;
  }
`

class NotFound extends React.PureComponent {
  render() {
    return (
      <NotFoundContainer>
        <NotFoundContent>
          <H1>{intl.get('app.notFound.msg')}</H1>
          <Link to='/'>
            <Button variant='outlined' color='primary'>
              {intl.get('app.notFound.button')}
            </Button>
          </Link>
        </NotFoundContent>
      </NotFoundContainer>
    )
  }
}

export default NotFound
