import styled, { css } from 'styled-components'
import { Flex } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
export const TitleWrap = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  padding-top: ${p => p.top + 'px'};
  padding-bottom: ${p => p.bottom + 'px'};

  ${p => p.theme.Hack.desktopEn`
    h3 {
      font-size: 36px;
      font-weight: 500;
    }
  `}

  ${Media.phone`
    padding-top: ${p => p.top / 100 + 'rem'};
    padding-bottom: ${p => p.bottom / 100 + 'rem'};
    h3 {
      line-height: 1.2;
    }
  `}
`
export const IconWrap = styled.div`
  width: 24px;
  height: 10px;
`

export const TitleIconWrap = styled.div`
  ${({ theme: { color } }) => css`
    width: 30px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 7px;
    span {
      margin-top: 3px;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-left: 10px solid ${color.secondary};
    }
    ${Media.phone`
      width: .4rem;
      height: .16rem;
      span{
        margin-top:0px;
        border-left-width: .15rem;
        transform: scale(0.8);
        :last-child{
          margin-left: -4px;
        }
      }
    `}
  `}
`
