import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Layout as CommonLayout } from '@raysync/common/components'
class Layout extends React.PureComponent {
  render() {
    if (process.env.GATSBY_LOCALE === 'zh-CN') {
      return (
        <StaticQuery
          query={graphql`
            query {
              links: allContentfulFriendLink(sort: { fields: createdAt }) {
                edges {
                  node {
                    name
                    link
                  }
                }
              }
              blog: allContentfulNews(
                filter: { slug: { nin: "null" }, isBlog: { eq: true } }
                limit: 6
                sort: { fields: legacyDate, order: DESC }
              ) {
                nodes {
                  title
                  slug
                }
              }
            }
          `}
          render={({ links: { edges }, blog }) => {
            const friendLinks = edges.map(({ node: { name, link } }) => ({ name, link }))
            return <CommonLayout friendLinks={friendLinks} blog={blog.nodes} {...this.props} />
          }}
        />
      )
    }
    return <CommonLayout {...this.props} />
  }
}

export default Layout
