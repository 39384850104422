import React from 'react'
import intl from 'react-intl-universal'
import { graphql, StaticQuery } from 'gatsby'
import { Navigation } from '@raysync/common/services'
import { Billboard, Button } from '@raysync/common/components'
import { BannerContainer, InviteTextContainer, ButtonContainer, BannerWrapNet } from './atoms'

class InviteBanner extends React.PureComponent {
  render() {
    const { type, variant = 'normal' } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            InviteBg: file(relativePath: { eq: "index/bg2.png" }) {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={({ InviteBg }) => (
          <BannerContainer>
            <BannerWrapNet variant={variant}>
              <InviteTextContainer>
                <h2>{intl.get(`${type}.invite.title`)}</h2>
              </InviteTextContainer>
              <ButtonContainer style={{ margin: '10px 0 0 0' }}>
                <Button
                  size='large'
                  onClick={() => Navigation.toApply({ type: 'apply', pageType: type })}
                  color='white'
                >
                  {intl.get(`${type}.invite.button1`)}
                </Button>
                {variant === 'inquire' && (
                  <Button
                    size='large'
                    onClick={() => Navigation.toApply({ type: 'prompt', pageType: type })}
                    variant='outlined'
                    color='white'
                  >
                    {intl.get(`${type}.invite.button2`)}
                  </Button>
                )}
              </ButtonContainer>
            </BannerWrapNet>
            <Billboard fluid={InviteBg.childImageSharp.fluid} alt='Invite background image' />
          </BannerContainer>
        )}
      />
    )
  }
}

export default InviteBanner
