import { useState, useCallback, useEffect, useRef } from 'react'

export function useTimer(defaultText) {
  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const timer = useRef(null)
  useEffect(() => {
    if (time > 0) {
      timer.current = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    } else {
      setIsRunning(false)
    }
    return () => {
      clearTimeout(timer.current)
    }
  }, [time])

  const startTimer = useCallback(time => {
    setIsRunning(true)
    setTime(time)
  }, [])

  const stopTimer = useCallback(() => {
    setTime(0)
    setIsRunning(false)
    clearTimeout(timer.current)
  }, [])
  const timerText = time === 0 ? defaultText : time + 's'

  return { timerText, isRunning, startTimer, stopTimer }
}
