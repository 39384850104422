import React from 'react'

const SvgYoutube = props => (
  <svg viewBox='0 0 25 25' {...props}>
    <path
      d='M12.5 24.5c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12zm6.2-15.8c-.3-.7-.7-.8-1.6-.9-.8-.1-2.9-.1-4.6-.1-1.8 0-3.8 0-4.6.1-.8.1-1.2.2-1.6.9-.4.7-.6 1.8-.6 3.8 0 2 .2 3.2.6 3.8.4.7.8.8 1.6.8.8.1 2.9.1 4.6.1 1.8 0 3.8 0 4.6-.1.8-.1 1.2-.2 1.6-.8.4-.7.6-1.8.6-3.8s-.2-3.2-.6-3.8zM11.1 10l3.9 2.5-3.9 2.5v-5z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#c1c7d6'
    />
  </svg>
)

export default SvgYoutube
