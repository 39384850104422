import { css } from 'styled-components'
import { color } from './variables'
export const Select = css`
  * {
    box-sizing: border-box;
  }
  .rc-select {
    font-size: 12px;
    width: 100%;
    position: relative;
  }

  .rc-select-selection-placeholder {
    position: relative;
    left: -4px;
    color: ${p => p.theme.color.text} !important;
    font-size: ${p => p.theme.typography.textSmall} !important;
  }
  .rc-select-disabled,
  .rc-select-disabled input {
    cursor: not-allowed;
  }
  .rc-select-disabled {
    .rc-select-selector {
      opacity: 0.3;
    }
  }
  .rc-select-show-arrow {
    &.rc-select-loading {
      .rc-select-arrow-icon {
        &::after {
          box-sizing: border-box;
          width: 14px;
          height: 14px;
          border-radius: 100%;
          border: 2px solid #999;
          border-top-color: transparent;
          border-bottom-color: transparent;
          transform: none;
          margin-top: 4px;
          animation: rcSelectLoadingIcon 0.5s infinite;
        }
      }
    }
    &.rc-select-multiple {
      .rc-select-selector {
        padding-right: 20px;
      }
    }
    .rc-select-arrow {
      pointer-events: none;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
    .rc-select-arrow-icon {
      &::after {
        content: '';
        border: 6px solid transparent;
        width: 0;
        height: 0;
        display: inline-block;
        border-top-color: ${p => p.theme.color.text};
        transform: translateY(5px);
      }
    }
  }
  .rc-select-selector .rc-select-selection-search {
    width: 14px !important;
  }
  .rc-select-single {
    .rc-select-selector {
      display: flex;
      position: relative;
      .rc-select-selection-search {
        width: 100%;
      }
      .rc-select-selection-search-input {
        width: 100%;
      }
    }
    &:not(.rc-select-customize-input) {
      .rc-select-selector {
        padding: 9px 5px;
        border: 1px solid #dae0e5;
        .rc-select-selection-search-input {
          border: none;
          outline: none;
        }
      }
    }
  }
  .rc-select-single .rc-select-selector .rc-select-selection-item,
  .rc-select-single .rc-select-selector .rc-select-selection-placeholder {
    font-size: 14px;
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 1;
  }
  /* Multiple */
  .rc-select-multiple {
    .rc-select-selector {
      display: flex;
      flex-wrap: wrap;
      padding: 9px 5px;
      border: 1px solid #dae0e5;
      .rc-select-selection-item {
        display: flex;
        align-items: center;
        color: ${color.primary};
        background: #f5f7fb;
        border-radius: 4px;
        margin-right: 2px;
        padding: 0 8px;
        .rc-select-selection-item-remove {
          margin-left: 3px;
        }
      }
      .rc-select-selection-item-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .rc-select-selection-search {
        position: relative;
      }
      .rc-select-selection-search-mirror {
        position: absolute;
        z-index: 999;
        white-space: nowrap;
        position: none;
        left: 0;
        top: 0;
        visibility: hidden;
      }
      .rc-select-selection-search-input {
        border: none;
        outline: none;
        color: grey;
        width: 100%;
      }
    }
  }
  .rc-select-multiple .rc-select-selector .rc-select-selection-search-input,
  .rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
    padding: 3px;
    font-family: system-ui;
  }
  .rc-select-allow-clear {
    &.rc-select-multiple {
      .rc-select-selector {
        padding-right: 20px;
      }
    }
    .rc-select-clear {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }

  /* Dropdown */
  .rc-select-dropdown {
    border: 1px solid #dae0e5;
    position: absolute;
    background: #fff;
    width: auto !important;
    z-index: 10;
  }
  .rc-select-dropdown-hidden {
    display: none;
  }
  .rc-select-item {
    font-size: 14px;
    line-height: 1.5;
    padding: 4px 16px;
  }
  .rc-select-item-group {
    color: ${p => p.theme.color.text};
    font-weight: bold;
    font-size: 80%;
  }
  /* Option */
  .rc-select-item-option {
    position: relative;
    padding-right: 20px;

    .rc-select-item-option-state {
      position: absolute;
      right: 12px;
      top: 4px;
      pointer-events: none;
      color: ${p => p.theme.color.secondary};
    }
  }
  .rc-select-item-option-grouped {
    padding-left: 24px;
  }
  .rc-select-item-option-active {
    background: #f5f7fb;
    /* background: #ffeae3; */
  }
  .rc-select-item-option-disabled {
    color: #999;
  }
  .rc-select-item-empty {
    text-align: center;
    color: #999;
  }
  .rc-select-selection__choice-zoom {
    transition: all 0.3s;
  }
  .rc-select-selection__choice-zoom-appear {
    opacity: 0;
    transform: scale(0.5);
    &.rc-select-selection__choice-zoom-appear-active {
      opacity: 1;
      transform: scale(1);
    }
  }
  .rc-select-selection__choice-zoom-leave {
    opacity: 1;
    transform: scale(1);
    &.rc-select-selection__choice-zoom-leave-active {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  .rc-select-dropdown-slide-up-enter,
  .rc-select-dropdown-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }
  .rc-select-dropdown-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
    &.rc-select-dropdown-slide-up-leave-active {
      &.rc-select-dropdown-placement-bottomLeft {
        animation-name: rcSelectDropdownSlideUpOut;
        animation-play-state: running;
      }
      &.rc-select-dropdown-placement-topLeft {
        animation-name: rcSelectDropdownSlideDownOut;
        animation-play-state: running;
      }
    }
  }
  .rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
  .rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpIn;
    animation-play-state: running;
  }
  .rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
  .rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownIn;
    animation-play-state: running;
  }

  @keyframes rcSelectDropdownSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
  }
  @keyframes rcSelectDropdownSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
  }
  @keyframes rcSelectLoadingIcon {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
