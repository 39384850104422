import React from 'react'

const SvgScene1 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='scene1_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene1_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene1_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='scene1_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={20}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene1_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={18}
        height={17}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
      <filter
        id='scene1_svg__f'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={8}
        height={5}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path
      d='M24 22a2 2 0 002 2h16a2 2 0 002-2V4a2 2 0 00-2-2H29.5L24 7.5V22z'
      fill='url(#scene1_svg__a)'
      transform='matrix(-1 0 0 1 48 0)'
    />
    <g transform='matrix(-1 0 0 1 40 0)' filter='url(#scene1_svg__b)'>
      <path d='M20 20a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2H25.5L20 5.5V20z' fill='url(#scene1_svg__c)' />
      <path
        d='M38 0H25.5L20 5.5V20a2 2 0 002 2h16a2 2 0 002-2V2a2 2 0 00-2-2zM21 20q0 .414.293.707T22 21h16q.414 0 .707-.293T39 20V2q0-.414-.293-.707T38 1H26v5h-5v14zm4-18.086L21.914 5H25V1.914z'
        fillRule='evenodd'
        fill='url(#scene1_svg__d)'
      />
    </g>
    <g filter='url(#scene1_svg__e)'>
      <path
        d='M6.66 14.44c1.66 0 2.55-.87 2.55-2.215C9.21 10.87 8.32 10 6.65 10H3.795v7H5.19v-2.56h1.47zm-.035-3.155c.76 0 1.155.315 1.155.94 0 .63-.395.94-1.155.94H5.19v-1.88h1.435zM10.4 10v7h3.03c1.68 0 2.635-.66 2.635-2.005 0-.755-.525-1.355-1.165-1.57a1.561 1.561 0 001.015-1.485c0-1.335-1.025-1.94-2.655-1.94H10.4zm2.96 4.09c.77 0 1.255.18 1.255.835 0 .67-.485.85-1.255.85l-1.565-.005v-1.68h1.565zm-.14-2.86c.69 0 1.24.15 1.24.81 0 .68-.54.825-1.235.825h-1.43V11.23h1.425z'
        fill='#FFF'
      />
    </g>
    <g filter='url(#scene1_svg__f)'>
      <rect x={4} y={4} width={4} height={1} rx={0.5} fill='#FFF' />
    </g>
  </svg>
)

export default SvgScene1
