import React from 'react'

import Partner from './partner'
import { Container, FooterWrapper } from './atoms'
import PostCards from './post-cards'
import FriendLinks from './friend-links'

import { Divider } from '../atoms'

class Footer extends React.PureComponent {
  render() {
    const { info, friendLinks, style, pageType, blog } = this.props
    return (
      <Container style={style}>
        <FooterWrapper>
          <PostCards info={info} blog={blog} />
          <Partner />
          <Divider />
          {friendLinks && pageType === 'home' && <FriendLinks friendLinks={friendLinks} />}
        </FooterWrapper>
      </Container>
    )
  }
}

export default Footer
