import React from 'react'
import {
  MobileMenuContainer,
  ListWrap,
  ListItem,
  ListItemLink,
  ListItemBlock,
  ExpandTitle,
  MultiContent,
  ExpandItem,
  Mask,
} from './en-atoms'

class MobileMenu extends React.Component {
  listRef = React.createRef()
  state = {
    expands: [],
    itemHeights: [],
  }
  componentDidMount() {
    let liList = this.listRef.current.children
    let heights = []
    for (let i = 0; i < liList.length; i++) {
      heights.push(liList[i].scrollHeight)
    }
    this.setState({ expands: new Array(this.props.list.length).fill(false), itemHeights: heights })
  }

  componentDidUpdate() {
    const body = document.querySelector('body')
    if (this.props.isExpand) {
      body.style.overflow = 'hidden'
      body.style.height = '1px'
    } else {
      body.style.overflow = 'auto'
      body.style.height = 'initial'
    }
  }

  preventDefault = e => {
    e.preventDefault()
  }

  toggleExpands = index => {
    let arr = this.state.expands
    arr.splice(index, 1, !arr[index])
    this.setState({ expands: arr })
  }

  handleActive = ({ children, hasMulti }, pathname) => {
    let isActive = false
    if (hasMulti) {
      children.forEach(c => {
        c.items.some(i => i.link === pathname) && (isActive = true)
      })
    } else {
      isActive = children.some(i => i.link === pathname)
    }
    return isActive
  }

  render() {
    let { list, isExpand, pathname = [], closeExpand } = this.props

    let { expands, itemHeights } = this.state
    return (
      <MobileMenuContainer>
        <ListWrap ref={this.listRef} isExpand={isExpand}>
          {list.map((l, index) => (
            <ListItem key={l.text} maxHeight={itemHeights[index]} isExpand={expands[index]}>
              {l.children ? (
                <ListItemBlock
                  isActive={this.handleActive(l, pathname)}
                  showarrow={!!l.children}
                  isExpand={expands[index]}
                  onClick={() => {
                    this.toggleExpands(index)
                  }}
                >
                  {l.text}
                </ListItemBlock>
              ) : (
                <ListItemLink to={l.link} isActive={pathname === l.link}>
                  {l.text}
                </ListItemLink>
              )}

              {l.children && (
                <>
                  {l.children.map(i =>
                    i.title ? (
                      <MultiContent key={i.title}>
                        <ExpandTitle>{i.title}</ExpandTitle>
                        {i.items.map(k => (
                          <ExpandItem key={k.text} to={k.link} isActive={pathname === k.link}>
                            {k.text}
                          </ExpandItem>
                        ))}
                      </MultiContent>
                    ) : (
                      <ExpandItem key={i.text} to={i.link} isActive={pathname === i.link}>
                        {i.text}
                      </ExpandItem>
                    ),
                  )}
                </>
              )}
            </ListItem>
          ))}
        </ListWrap>
        <Mask isExpand={isExpand} onClick={closeExpand} />
      </MobileMenuContainer>
    )
  }
}

export default MobileMenu
