import React from 'react'

const SvgIndustry8 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry8_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry8_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry8_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry8_svg__e'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry8_svg__f'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <filter
        id='industry8_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={20}
        height={14}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry8_svg__g'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={10}
        height={10}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path
      d='M0 9v10q0 .098.01.196t.028.194q.02.096.048.19.029.095.066.185.038.091.084.178.046.086.101.168.055.082.117.158.062.076.132.145.07.07.145.132.076.062.158.117.082.055.168.1.087.047.178.085.09.037.184.066.094.028.19.048.097.019.195.028.098.01.196.01h13q.098 0 .196-.01t.194-.028q.096-.02.19-.048.095-.029.185-.066.091-.038.178-.084.086-.046.168-.101.082-.055.158-.117.076-.062.145-.132.07-.07.132-.145.062-.076.117-.158.055-.082.1-.168.047-.087.085-.178.037-.09.066-.184.028-.095.048-.19.019-.097.028-.195.01-.098.01-.196v-.5l3 1.5V8l-3 1.5V9q0-.098-.01-.196t-.028-.194q-.02-.097-.048-.19-.029-.095-.066-.185-.038-.091-.084-.178-.046-.086-.101-.168-.055-.082-.117-.158-.062-.076-.132-.145-.07-.07-.145-.132-.076-.062-.158-.117-.082-.055-.168-.1-.087-.047-.178-.085-.09-.037-.184-.066-.095-.028-.19-.048-.097-.019-.195-.028Q15.098 7 15 7H2q-.098 0-.196.01t-.194.028q-.097.02-.19.048-.095.029-.185.066-.091.038-.178.084-.086.046-.168.101-.082.055-.158.117-.076.062-.145.132-.07.07-.132.145-.062.076-.117.158-.055.082-.1.168-.047.087-.085.178-.037.09-.066.184-.028.094-.048.19-.019.097-.028.195Q0 8.902 0 9z'
      fillRule='evenodd'
      fill='url(#industry8_svg__a)'
    />
    <g filter='url(#industry8_svg__b)' fillRule='evenodd'>
      <path
        d='M4 12v10q0 .098.01.196t.028.194q.02.096.048.19.029.095.066.185.038.091.084.178.046.086.101.168.055.082.117.158.062.076.132.145.07.07.145.132.076.062.158.117.082.055.168.1.087.047.178.085.09.037.184.066.094.028.19.048.097.019.195.028.098.01.196.01h13q.098 0 .196-.01t.194-.028q.096-.02.19-.048.095-.029.185-.066.091-.038.178-.084.086-.046.168-.101.082-.055.158-.117.076-.062.145-.132.07-.07.132-.145.062-.076.117-.158.055-.082.1-.168.047-.087.085-.178.037-.09.066-.184.028-.095.048-.19.019-.097.028-.195.01-.098.01-.196v-1.25L24 22V12l-3 1.25V12q0-.098-.01-.196t-.028-.194q-.02-.097-.048-.19-.029-.095-.066-.185-.038-.091-.084-.178-.046-.086-.101-.168-.055-.082-.117-.158-.062-.076-.132-.145-.07-.07-.145-.132-.076-.062-.158-.117-.082-.055-.168-.1-.087-.047-.178-.085-.09-.037-.184-.066-.095-.028-.19-.048-.097-.019-.195-.028Q19.098 10 19 10H6q-.098 0-.196.01t-.194.028q-.097.02-.19.048-.095.029-.185.066-.091.038-.178.084-.086.046-.168.101-.082.055-.158.117-.076.062-.145.132-.07.07-.132.145-.062.076-.117.158-.055.082-.1.168-.047.087-.085.178-.037.09-.066.184-.028.094-.048.19-.019.097-.028.195Q4 11.902 4 12z'
        fill='url(#industry8_svg__c)'
      />
      <path
        d='M21 12.167V12q0-.098-.01-.196t-.028-.194q-.02-.097-.048-.19-.029-.095-.066-.185-.038-.091-.084-.178-.046-.086-.101-.168-.055-.082-.117-.158-.062-.076-.132-.145-.07-.07-.145-.132-.076-.062-.158-.117-.082-.055-.168-.1-.087-.047-.178-.085-.09-.037-.184-.066-.095-.028-.19-.048-.097-.019-.195-.028Q19.098 10 19 10H6q-.098 0-.196.01t-.194.028q-.097.02-.19.048-.095.029-.185.066-.091.038-.178.084-.086.046-.168.101-.082.055-.158.117-.076.062-.145.132-.07.07-.132.145-.062.076-.117.158-.055.082-.1.168-.047.087-.085.178-.037.09-.066.184-.028.094-.048.19-.019.097-.028.195Q4 11.902 4 12v10q0 .098.01.196t.028.194q.02.096.048.19.029.095.066.185.038.091.084.178.046.086.101.168.055.082.117.158.062.076.132.145.07.07.145.132.076.062.158.117.082.055.168.1.087.047.178.085.09.037.184.066.094.028.19.048.097.019.195.028.098.01.196.01h13q.098 0 .196-.01t.194-.028q.096-.02.19-.048.095-.029.185-.066.091-.038.178-.084.086-.046.168-.101.082-.055.158-.117.076-.062.145-.132.07-.07.132-.145.062-.076.117-.158.055-.082.1-.168.047-.087.085-.178.037-.09.066-.184.028-.095.048-.19.019-.097.028-.195.01-.098.01-.196v-1.25l1 .417 1 .416L24 22V12l-1 .417-1 .416-1 .417v-1.083zm2 1.333l-3 1.25V12q0-.414-.293-.707T19 11H6q-.414 0-.707.293T5 12v10q0 .414.293.707T6 23h13q.414 0 .707-.293T20 22v-2.75l3 1.25v-7z'
        fill='url(#industry8_svg__d)'
      />
    </g>
    <circle cx={13} cy={3} fill='url(#industry8_svg__e)' r={3} />
    <circle cx={6} cy={4} fill='url(#industry8_svg__f)' r={2} />
    <g transform='rotate(90 16 14)' filter='url(#industry8_svg__g)'>
      <path d='M19 14l2.598 4.5h-5.196L19 14z' fill='#FFF' />
    </g>
  </svg>
)

export default SvgIndustry8
