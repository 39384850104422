import React from 'react'

const SvgLinkedin = props => (
  <svg viewBox='0 0 25 25' {...props}>
    <path
      d='M12.3.6C5.7.6.3 6 .3 12.6s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zM8.9 18.3H6.2v-9h2.7v9zM7.5 8.2c-.9 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4S9 6 9 6.8c0 .8-.6 1.4-1.5 1.4zm11.9 10.1h-2.7v-5c0-1.2-.4-2-1.4-2-.8 0-1.2.5-1.4 1.1-.1.2-.1.4-.1.7v5.2h-2.7v-6.1c0-1.1 0-2.1-.1-2.9h2.4l.1 1.2h.1c.4-.6 1.3-1.4 2.7-1.4 1.8 0 3.1 1.2 3.1 3.9v5.3z'
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#c1c7d6'
    />
  </svg>
)

export default SvgLinkedin
