import React, { useState, useEffect } from 'react'
import Checkbox from './checkbox'

export const GroupContext = React.createContext(null)

const CheckboxGroup = ({ options, onChange, defaultValue = [], checkboxStyle }) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (onChange) {
      onChange(
        value.sort((a, b) => {
          const indexA = options.findIndex(opt => opt.value === a)
          const indexB = options.findIndex(opt => opt.value === b)
          return indexA - indexB
        }),
      )
    }
  }, [value, onChange, options])

  const registerValue = v => {
    setValue([...value, v])
  }

  const cancelValue = v => {
    setValue(value.filter(item => item !== v))
  }

  const group = {
    registerValue,
    cancelValue,
  }

  return (
    <GroupContext.Provider value={group}>
      {options.length > 0 &&
        options.map(option => (
          <Checkbox
            key={option.value}
            style={checkboxStyle}
            value={option.value}
            checked={value.includes(option.value)}
          >
            {option.label}
          </Checkbox>
        ))}
    </GroupContext.Provider>
  )
}

export default CheckboxGroup
