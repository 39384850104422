import React from 'react'
import intl from 'react-intl-universal'
import { useScroll } from '../../hooks'
import { LOCALES } from '../../config'
import Flex from '../flex'
import { ShowAtMobile } from '../phone'
import Link from '../link'
import Button from '../button'
import { LocaleService } from '../../services'
import { Icon } from '../index'
import {
  Navbar,
  Brand,
  IconWrap,
  StyledWrapper,
  LinkWrap,
  DropdownWrap,
  DropdownMenu,
  ItemWrap,
  Telphone,
  RightBox,
  MenuItem,
} from './atoms'
import MobileMenu from './mobile-menu'
import Logo from '../svg/home/logo'
import PhoneIcon from './images/phone.png'
import SolutionMenu from './solution-menu'
import ProductIcon1 from './images/product1.png'
import ProductIcon2 from './images/product2.png'
import ProductIcon3 from './images/product3.png'
import ProductIcon4 from './images/product4.png'
import ProductIcon5 from './images/product5.png'
import ProductIcon6 from './images/product6.png'
import ProductIcon7 from './images/product7.png'

function ScrollHandle({ handler }) {
  useScroll(handler)
  return null
}

const currentPackageName = process.env.GATSBY_PACKAGE_NAME
class Header extends React.PureComponent {
  state = {
    isTransparent: false,
    navigationList: [
      {
        link: '/',
        text: intl.get('app.header.home'),
      },
      {
        text: intl.get('app.header.products'),
        children: [
          { link: '/products/file/', text: intl.get('app.footer.product1'), img: ProductIcon1 },
          { link: '/solutions/raysync-ferry/', text: '跨网文件交换系统', img: ProductIcon2 },
          { link: '/solutions/ftp-alternative-upgrade/', text: 'FTP替代升级', img: ProductIcon3 },
          { link: '/products/sdk/', text: intl.get('app.footer.product2'), img: ProductIcon4 },
          { link: '/products/vpn/', text: intl.get('app.footer.product3'), img: ProductIcon5 },
          { link: '/products/ftp/', text: intl.get('app.footer.product4'), img: ProductIcon6 },
        ],
      },
      {
        hasMulti: true,
        text: intl.get('app.header.solution'),
        children: [
          {
            title: intl.get('home.solution.tab1'),
            items: [
              { link: '/solutions/large-file-transfer/', text: intl.get('app.footer.scenes5') },
              { link: '/solutions/data-transfer/', text: intl.get('app.footer.scenes6') },
              { link: '/solutions/multi-transfer/', text: intl.get('app.footer.scenes7') },
              { link: '/solutions/data-sync/', text: intl.get('app.footer.scenes4') },
              { link: '/solutions/file-exchange/', text: intl.get('solution-file-exchange.banner.title') },
              { link: '/solutions/data-migration/', text: intl.get('app.footer.scenes3') },
              { link: '/solutions/data-collaboration/', text: intl.get('app.footer.scenes1') },
              { link: '/solutions/data-distribution/', text: intl.get('app.footer.scenes2') },
              // { link: '/solutions/file-management/', text: intl.get('solution8.seo.h1') },
            ],
          },
          {
            title: intl.get('home.solution.tab2'),
            items: [
              { link: '/solutions/industry-government/', text: intl.get('app.footer.solution1') },
              { link: '/solutions/industry-ad/', text: intl.get('app.footer.solution2') },
              { link: '/solutions/industry-financing/', text: intl.get('app.footer.solution3') },
              { link: '/solutions/industry-gene/', text: intl.get('app.footer.solution4') },
              { link: '/solutions/industry-car/', text: intl.get('app.footer.solution5') },
              { link: '/solutions/industry-manufacturing/', text: intl.get('app.footer.solution6') },
              { link: '/solutions/industry-internet/', text: intl.get('app.footer.solution7') },
              { link: '/solutions/industry-moive/', text: intl.get('app.footer.solution8') },
            ],
          },
        ],
      },
      {
        link: '/pricing/',
        text: intl.get('app.header.pricing'),
      },
      {
        link: '/example/',
        text: intl.get('app.header.example'),
      },
      {
        link: '/news/',
        text: intl.get('app.footer.entry2'),
        packageName: 'news',
      },
      {
        link: '/partners/',
        text: intl.get('app.header.agent'),
      },
      {
        link: '/about-us/',
        text: intl.get('app.header.about'),
      },
      {
        link: '/support/document/index',
        text: intl.get('app.header.support'),
        packageName: 'support',
      },
    ],
  }

  handleScroll = e => {
    this.setState({ isTransparent: e.target.scrollingElement.scrollTop <= 10 })
  }

  toggleExpand = () => {
    this.setState({ isExpand: !this.state.isExpand })
  }

  handleActive = ({ children, hasMulti }, pathname) => {
    let isActive = false
    if (hasMulti) {
      children.forEach(c => {
        c.items.some(i => new RegExp(`${i.link}$`).test(pathname)) && (isActive = true)
      })
    } else {
      isActive = children.some(i => new RegExp(`${i.link}$`).test(pathname))
    }
    return isActive
  }

  render() {
    const { navigationList, isTransparent, isExpand } = this.state
    const { location, pageType } = this.props
    const isApplyPage = pageType === 'apply'
    let pathname = /\/$/.test(location.pathname) ? location.pathname : location.pathname + '/'
    return (
      <React.Fragment>
        <Navbar isTransparent={isTransparent}>
          {pageType === 'home' && <ScrollHandle handler={this.handleScroll} />}
          <StyledWrapper>
            <div>
              <Brand href='/' target={isApplyPage ? '_blank' : ''}>
                <Logo />
                {/* <img src={Logo} alt='镭速' /> */}
              </Brand>
              {!isApplyPage && (
                <Flex halign='space-between' grow={2} className='hide-at-mobile'>
                  <LinkWrap>
                    {navigationList.map((l, i) =>
                      l.children ? (
                        <DropdownMenu key={i} hasMulti={l.hasMulti} isActive={this.handleActive(l, pathname)}>
                          <span style={{ fontSize: '16px' }}>{l.text}</span>
                          <DropdownWrap hasMulti={l.hasMulti}>
                            {!l.hasMulti ? (
                              l.children.map((k, j) => (
                                <MenuItem
                                  key={j + 10}
                                  to={k.link}
                                  target='_blank'
                                  isInternal={currentPackageName === 'main'}
                                  isActive={new RegExp(`${k.link}$`).test(pathname)}
                                >
                                  <img src={k.img} alt={k.text} /> {k.text}
                                </MenuItem>
                              ))
                            ) : (
                              <SolutionMenu />
                            )}
                          </DropdownWrap>
                        </DropdownMenu>
                      ) : l.packageName ? (
                        <Link
                          key={i}
                          to={l.link}
                          style={{ fontSize: '16px' }}
                          isInternal={currentPackageName === l.packageName}
                          isActive={pathname.split('/').includes(l.packageName)}
                        >
                          {l.text}
                        </Link>
                      ) : (
                        <Link
                          key={i}
                          to={l.link}
                          style={{ fontSize: '16px' }}
                          isInternal={currentPackageName === 'main'}
                          isActive={
                            l.link === '/' ? /^(\/ja)?\/$/.test(pathname) : new RegExp(`${l.link}$`).test(pathname)
                          }
                        >
                          {l.text}
                        </Link>
                      ),
                    )}
                  </LinkWrap>
                </Flex>
              )}
            </div>
            <RightBox>
              <Flex className={isApplyPage ? '' : 'hide-at-mobile'}>
                <Telphone href={`tel:${intl.get('app.contact.telephone.value')}`}>
                  <img src={PhoneIcon} alt='phone' />
                  {intl.get('app.contact.telephone.value')}
                </Telphone>

                {!isApplyPage && (
                  <Button as='a' id='FreeTrial' href='/apply/' target='_blank'>
                    {intl.get('app.header.apply')}
                  </Button>
                )}
              </Flex>
              {!isApplyPage && (
                <IconWrap onClick={() => this.toggleExpand()}>
                  <Icon type={isExpand ? 'close' : 'menu'}></Icon>
                </IconWrap>
              )}
            </RightBox>
          </StyledWrapper>
          <ShowAtMobile>
            <MobileMenu isExpand={isExpand} list={navigationList} pathname={pathname}></MobileMenu>
          </ShowAtMobile>
        </Navbar>
      </React.Fragment>
    )
  }
}

export default Header
