import React from 'react'
import intl from 'react-intl-universal'
import { EnContainer, FooterWrapper, Copyright } from './atoms'
import EnPostCards from './en-post-cards'
import { Divider } from '../atoms'
import Sns from './new-sns'
import ApplyBanner from './apply-banner'
import SmbBanner from './smb-banner'
class EnFooter extends React.PureComponent {
  render() {
    const locale = intl.getInitOptions().currentLocale
    const { info, pageType, style, showBanner } = this.props
    return (
      <>
        {showBanner && (pageType === 'smb' ? <SmbBanner locale={locale} /> : <ApplyBanner locale={locale} />)}
        <EnContainer style={{ ...style }} locale={locale} showBanner={showBanner} pageType={pageType}>
          <FooterWrapper>
            <EnPostCards info={info} />
            <Divider />
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Sns />
              <Copyright>Copyright © 2024 Raysync.io. All Rights Reserved.</Copyright>
            </div>
          </FooterWrapper>
        </EnContainer>
      </>
    )
  }
}

export default EnFooter
