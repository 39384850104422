import styled, { css } from 'styled-components'
import Link from '../link'
import { transition, hideAtMobile, Media } from '../../theme'

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eff2f4;
  border: none;
  min-width: 1400px;
  padding: 0 40px;
  > div {
    display: flex;
    align-items: center;
  }
  .hide-at-mobile {
    ${hideAtMobile}
  }
  ${Media.phone`
    justify-content: space-between;
    min-width: unset;
    padding: 0 .3rem;
  `}
`
const commonLink = css`
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 0 15px;
  line-height: 60px;
  color: ${p => (p.isActive ? p.theme.color.secondary : '#636772')};
  font-size: ${p => p.theme.typography.textSmall};
  span {
    color: ${p => (p.isActive ? p.theme.color.secondary : '#fff')};
  }
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 45px;
    transform: translateX(-50%);
    width: 28px;
    height: 1px;
    background-color: ${p => p.theme.color.secondary};
  }
  :hover {
    color: ${p => p.theme.color.secondary};
    span {
      color: ${p => p.theme.color.secondary};
    }
    &::after {
      display: inline-block;
    }
  }
`

export const Navbar = styled.header`
  display: flex;
  align-content: center;
  position: fixed;
  z-index: 4800;
  top: 0;
  width: 100%;
  height: 60px;
  min-width: 100px;
  background-color: #1c1f2b;
  border-bottom: 1px solid #1c1f2b;
  ${Media.phone`
    height: .9rem;
  `}
`

export const Brand = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-right: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  svg {
    height: 30px;
  }
  ${Media.phone`
    height: .6rem;
    svg {
      height: .5rem;
    }
  `}
`

export const Logo = styled.img``

export const LinkWrap = styled.div`
  > a {
    ${commonLink};
    color: #fff;
  }
`

export const DropdownWrap = styled.div`
  ${({ theme: { color, typography, Hack } }) => css`
    display: none;
    position: absolute;
    top: 61px;
    left: ${p => (p.hasMulti ? '540px' : '50%')};
    text-align: left;
    background: white;
    transform: translateX(-50%);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: ${p => (p.hasMulti ? '0' : '10px')};
    ${Media.phone`
      top: .9rem;
    `}
  `}
`
export const MenuItem = styled(Link)`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  min-width: 200px;
  img {
    display: block;
    width: 24px;
    margin-right: 10px;
  }
  font-size: 14px;
  color: #252a3a;
  :hover {
    background: #f5f7fb;
  }
`
export const DropdownMenu = styled.div`
  ${commonLink};
  cursor: default;
  color: #fff;
  :hover {
    ${DropdownWrap} {
      display: ${p => (p.hasMulti ? 'flex' : 'block')};
      /* padding: ${p => (p.hasMulti ? '' : '10px 0px 10px 25px')}; */
    }
  }
`
export const ItemWrap = styled.div`
  display: inline-block;
  padding: 0 48px 20px 32px;
  span {
    display: inline-block;
    padding: 22px 0 14px 0;
    border-bottom: 1px solid #e6ebef;
    margin-bottom: 4px;
    line-height: 1;
    font-size: 16px;
    color: #25252a;
  }
`
export const Telphone = styled.a`
  ${({ theme: { color, typography } }) => css`
    color: ${color.secondary};
    font-size: ${typography.text};
    font-weight: bold;
    img {
      margin: -2px 5px 0 0;
    }
  `}
  ${Media.phone`
    font-size: .28rem;
  `}
`
export const IconWrap = styled.div`
  ${({ theme: { color, Hack } }) => css`
    display: none;
    ${Media.phone`
      display: flex;
      svg {
        width: 0.5rem;
        height: 0.5rem;
        path {
          color: #fff;
        }
      }
    `}
  `}
`

export const MobileMenuContainer = styled.div`
  position: fixed;
  overflow: auto;
  overscroll-behavior: contain;
  -ms-scroll-chaining: contain;
  left: 0;
  right: 0;
  top: 60px;
  z-index: 6000;
  background: white;
  height: ${p => (p.isExpand ? '100vh' : '0px')};
  transition: ${transition('height')};
  ${Media.phone`
      top: .9rem;
  `}
`
export const ListWrap = styled.div`
  max-width: 100vw;
  padding: 0 0 3rem 0;
`
export const ListItemLink = styled(Link)`
  display: block;
  margin: 0 0.4rem;
  line-height: 63px;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #ebebeb;
  font-size: 18px;
  font-weight: 500;
`
export const ListItemBlock = styled.div`
  display: block;
  margin: 0 0.4rem;
  line-height: 63px;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #ebebeb;
  font-size: 18px;
  font-weight: 500;
  color: ${p => (p.isActive ? p.theme.color.secondary : p.theme.color.primary)};
  ::after {
    content: '';
    position: absolute;
    right: 0.22rem;
    top: 50%;
    transform: translateY(-2px);
    width: 0px;
    height: 0px;
    display: inline-block;
    border-width: 6px;
    border-style: solid;
    border-color: #e6e6e6 transparent transparent;
    border-image: initial;
  }
`

export const ListItem = styled.div`
  list-style: none;
  max-height: ${p => (p.isExpand ? p.maxHeight + 'px' : '64px')};

  transition: max-height 0.5s ease-in;
  overflow: hidden;
`
export const ExpandContent = styled.div``
export const ExpandItem = styled(Link)`
  ${({ theme: { color } }) => css`
    background: ${color.panel};
    display: block;
    padding: 0 0.4rem;
    font-size: 16px;
    line-height: 1rem;
    color: ${color.primary};
  `}
`

export const ExpandTitle = styled.p`
  ${({ theme: { color } }) => css`
    font-size: 15px;
    padding: 0 0.4rem;
    line-height: 1;
    color: ${color.text};
  `}
`
export const MultiContent = styled.div`
  ${({ theme: { color } }) => css`
    padding: 0.4rem 0 0.2rem;
    border-bottom: 1px solid #ebebeb;
    background: ${color.panel};
  `}
`
export const RightBox = styled.div`
  #FreeTrial {
    min-width: 140px;
    margin-left: 40px;
  }
  ${Media.phone`
    margin-left: .4rem;
  `}
`
