import React from 'react'

const SvgIndustry7 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry7_svg__a'>
        <stop offset='0%' stopColor='#2789FF' />
        <stop offset='100%' stopColor='#1F6DCC' />
      </linearGradient>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry7_svg__b'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry7_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry7_svg__e'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='industry7_svg__c'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={22}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry7_svg__f'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={18}
        height={18}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <circle cx={10} cy={14} fill='url(#industry7_svg__a)' r={10} />
    <circle cx={10} cy={14} fill='url(#industry7_svg__b)' r={10} />
    <g filter='url(#industry7_svg__c)'>
      <circle cx={13} cy={11} fill='url(#industry7_svg__d)' r={11} />
      <circle cx={13} cy={11} stroke='url(#industry7_svg__e)' r={10.5} />
    </g>
    <g filter='url(#industry7_svg__f)'>
      <path
        d='M7.697 5.697Q5.5 7.893 5.5 11q0 3.107 2.197 5.303Q9.893 18.5 13 18.5q3.107 0 5.303-2.197Q20.5 14.107 20.5 11q0-3.107-2.197-5.303Q16.107 3.5 13 3.5q-3.107 0-5.303 2.197zm.707 9.9Q6.5 13.691 6.5 11t1.904-4.596Q10.308 4.5 13 4.5t4.596 1.904Q19.5 8.308 19.5 11t-1.904 4.596Q15.692 17.5 13 17.5t-4.596-1.904z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M6 11.5h14q.05 0 .098-.01t.093-.028q.046-.019.087-.046.04-.028.076-.062.034-.035.062-.076.027-.041.046-.087.019-.045.028-.093.01-.049.01-.098 0-.05-.01-.098t-.028-.093q-.019-.046-.046-.087-.028-.04-.062-.076-.035-.034-.076-.062-.041-.027-.087-.046-.045-.019-.093-.028-.049-.01-.098-.01H6q-.05 0-.098.01t-.093.028q-.046.019-.087.046-.04.028-.076.062-.034.035-.062.076-.027.041-.046.087-.019.045-.028.093-.01.049-.01.098 0 .05.01.098t.028.093q.019.046.046.087.028.04.062.076.035.034.076.062.041.027.087.046.045.019.093.028.049.01.098.01z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M10.556 5.865Q9.7 8.005 9.7 11q0 2.996.856 5.135Q11.502 18.5 13 18.5t2.444-2.365q.856-2.14.856-5.135 0-2.996-.856-5.135Q14.498 3.5 13 3.5t-2.444 2.365zm.928 9.9Q10.7 13.802 10.7 11t.784-4.764Q12.18 4.5 13 4.5q.821 0 1.516 1.736.784 1.96.784 4.764 0 2.803-.784 4.764Q13.82 17.5 13 17.5q-.821 0-1.516-1.736z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M8.404 5.797Q10.308 7.7 13 7.7t4.596-1.903q.07-.071.162-.109.092-.038.192-.038t.19.038q.093.038.163.108t.108.163q.039.091.039.19 0 .1-.039.192-.038.092-.108.162Q16.106 8.7 13 8.7q-3.107 0-5.303-2.197-.075-.074-.113-.172-.034-.087-.034-.181 0-.1.038-.191.038-.092.109-.163.07-.07.162-.108.092-.038.191-.038.083 0 .161.026.11.038.193.12zM13 13.3q-3.107 0-5.303 2.197-.07.07-.109.162-.038.092-.038.191 0 .1.038.192.038.091.109.162.07.07.162.108.092.038.191.038.1 0 .191-.038.092-.038.163-.108Q10.307 14.3 13 14.3q2.692 0 4.596 1.903.07.071.162.11.092.037.192.037t.19-.038q.093-.038.163-.108t.108-.162q.039-.092.039-.192t-.039-.191q-.038-.092-.108-.162Q16.106 13.3 13 13.3z'
        fillRule='evenodd'
        fill='#FFF'
      />
    </g>
  </svg>
)

export default SvgIndustry7
