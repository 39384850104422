import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Column from './column'

const BASE = 8

const Row = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: ${({ alignItems = 'flex-start' }) => alignItems};
  ${({ spacing = 0 }) => css`
    width: calc(100% + ${2 * spacing * BASE}px);
    margin: -${spacing * BASE}px;
  `};
  ${Column} {
    padding: ${({ spacing = 0 }) => spacing * BASE}px;
  }
`

Row.propTypes = {
  spacing: PropTypes.number,
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
}

export default Row
