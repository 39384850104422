import React from 'react'

const SvgGt = props => (
  <svg className='gt_svg__icon' viewBox='0 0 1024 1024' width={200} height={200} {...props}>
    <defs>
      <style>
        {
          '@font-face{font-family:feedback-iconfont;src:url(//at.alicdn.com/t/font_1031158_u69w8yhxdu.woff2?t=1630033759944) format(&quot;woff2&quot;),url(//at.alicdn.com/t/font_1031158_u69w8yhxdu.woff?t=1630033759944) format(&quot;woff&quot;),url(//at.alicdn.com/t/font_1031158_u69w8yhxdu.ttf?t=1630033759944) format(&quot;truetype&quot;)}'
        }
      </style>
    </defs>
    <path
      d='M761.6 489.6l-432-435.2c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l416 416-416 425.6c-9.6 9.6-9.6 25.6 0 35.2s25.6 9.6 35.2 0l432-441.6c9.6-9.6 9.6-25.6 0-35.2z'
      data-spm-anchor-id='a313x.7781069.0.i2'
      className='gt_svg__selected'
      fill='#fff'
    />
  </svg>
)

export default SvgGt
