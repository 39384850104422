import { createGlobalStyle, css } from 'styled-components'
import { Select } from './rc-style'
import { Media } from '../theme'
import './font.css'
import { LocaleService, LOCALES } from '../services'
const GlobalStyle = createGlobalStyle`
  html {
    font-family:  ${() =>
      LocaleService.getCurrentLocale() === LOCALES.ZH ? 'Source Han Sans CN' : 'Roboto'} !important;
    text-rendering: optimizeLegibility;
    -ms-overflow-style: scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 1400px;
    ${Media.phone`
      min-width: initial;
      font-size: calc(100vw/7.5);
    `}
    /* 以750px的设计稿为准，1rem=100px */
  }
  body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,ol,ul{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-weight: normal;
    box-sizing: border-box;

  }
  body{
    /* 移动端页面多出很多空白Hack */
    ${Media.phone`
      font-size: small;
    `}
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  h1,h2,h3,h4,h5,h6{
    font-family:'Source Han Sans CN';  
    ${LocaleService.isForeign &&
      css`
        font-family: 'Roboto-Medium';
        font-weight: normal !important;
      `}
  }
  .rc-select-dropdown.select-dark {
    background: #3B404E;
    box-shadow: 0px 4px 10px 0px #1C2230;
    border-radius: 5px 5px 5px 5px;
    border:none;
    z-index: 1000;
    > div > div {
       &::-webkit-scrollbar {
      width: 8px;
      position: fixed;
      display: block;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
      position: fixed;
      width: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #575C67;
      border-radius: 4px;
      position: fixed;
      width: 20px;
      padding-right: 10px;
    }
    }
    .rc-select-item {
      color: #fff;
      :hover {
        background: #454A57;
      }
    }
    .rc-select-item-option-active,.rc-select-item-option-selected {
      background: #454A57;
    }
  }
  .ripple {
    position: relative;
    overflow: hidden;
  }
  .ripple::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, black 20%, transparent 21%);
    background-repeat: no-repeat;
    transform: scale(5, 5);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  .ripple:active::after {
    transform: scale(0, 0);
    opacity: 0.1;
    transition: 0s;
  }
  #newBridge #nb_icon_wrap{
    display:none;
  }
  #newBridge .nb-show{
    display:none !important;
  }
  html #aff-im-root {
    display:none !important;
    visibility:hidden !important;
    opacity:0 !important;
  }
  ${Select}
`

export default GlobalStyle
