import styled, { css } from 'styled-components'
import { Media } from '../theme'
import transition from '../theme/transition'

const commonTitle = css`
  margin: 0;
  line-height: 1;
  color: ${p => p.theme.color.primary};
  padding-bottom: ${p => p.bottom && p.bottom + 'px'};
  padding-top: ${p => p.top && p.top + 'px'};
  padding-left: ${p => p.left && p.left + 'px'};
  padding-right: ${p => p.right && p.right + 'px'};
`

export const H1 = styled.h1`
  ${commonTitle};
  font-weight: normal;
  font-size: ${p => p.theme.typography.h1};
`
export const H2 = styled.h2`
  ${commonTitle};
  font-size: ${p => p.theme.typography.h2};
`
export const H3 = styled.h3`
  ${commonTitle};
  font-size: ${p => p.theme.typography.h3};
  ${Media.phone`
    font-size: ${p => p.theme.typography.h4};
  `}
`
export const Text = styled.p`
  ${commonTitle};
  font-size: ${p => p.theme.typography.h3};
  font-weight: bold;
  ${Media.phone`
    font-size: ${p => p.theme.typography.h4};
  `}
`
export const H4 = styled.h4`
  ${commonTitle};
  font-size: ${p => p.theme.typography.h4};
`
export const H5 = styled.h5`
  ${commonTitle};
  font-size: ${p => p.theme.typography.text};
`
export const Paragraph = styled.p`
  color: ${p => p.theme.color.text};
  font-size: ${p => p.theme.typography.text};
`
export const Prompt = styled.span`
  color: ${p => p.theme.color.textLight};
  font-size: ${p => p.theme.typography.textSmall};
`
export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: ${p => p.theme.color.divider};
`
export const Anchor = styled.a`
  ${({ theme: { color } }) => css`
    cursor: pointer;
    color: ${color.text};
    text-decoration: none;
    transition: ${transition('color')};
    :hover {
      color: ${color.secondary};
    }
  `}
`
export const Section = styled.section`
  padding: 100px 0;
`
export const LightSection = styled(Section)`
  background-color: ${p => p.theme.color.panel};
`
export const SectionTitle = styled(H2)`
  text-align: center;
  margin: 0 0 60px;
`
export const FormItemStyled = styled.div`
  display: flex;
  label {
    position: relative;
    text-align: right;
    margin-right: 14px;
    line-height: 44px;
    display: block;
    font-size: 16px;
    color: ${p => p.theme.color.textDarkPlus};
    flex: 0 0 82px;
    span {
      color: red;
    }
  }
  ${Media.phone`
    flex-wrap: wrap;
    label {
      line-height: .4rem;
      font-size: .24rem;
      text-align: left;
    }
  `}
`

export const Input = styled.input`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    font-size: ${typography.textSmall};
    border: 1px solid ${color.line};
    padding-left: 12px;
    margin-bottom: 14px;
    width: 100%;
    ::placeholder {
      font-size: ${typography.textSmall};
      color: ${color.text};
      opacity: 1;
    }
    ${p =>
        p.isError &&
        css`
          border-color: red;
        `}
      :focus {
      outline: none;
    }
    height: 44px;
    ${Media.phone`
      height: .7rem;
      line-height: .7rem;
      margin-bottom: .2rem;
    `}
  `}
`

export const TextArea = styled.textarea`
  ${({ theme: { color, typography } }) => css`
    color: ${color.primary};
    font-size: ${typography.textSmall};
    border: 1px solid ${color.line};
    padding-left: 12px;
    width: 100%;
    ::placeholder {
      font-size: ${typography.textSmall};
      color: ${color.text};
      opacity: 1;
    }
    ${p =>
        p.isError &&
        css`
          border-color: red;
        `}
      :focus {
      outline: none;
    }
    margin-bottom: 30px;
    padding-top: 12px;
    line-height: 24px;
    height: 130px;
    display: block;
    resize: none;
  `}
`
