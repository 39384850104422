import styled from 'styled-components'
import { typography, color, Media } from '@raysync/common/theme'
import { Wrapper, H2 } from '@raysync/common/components'
import { Button } from '@raysync/common/components'

export const BannerWrap = styled(Wrapper)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Media.phone`
    padding: 0 .5rem;
  `}
`
export const BannerContainer = styled.div`
  position: relative;
  height: 400px;
  overflow: hidden;
  z-index: 0;
  background-color: ${color.panel};
  ${Media.phone`
    height: 7rem;
  `}
`

export const BannerLeft = styled.div`
  max-height: 670px;
`

export const BannerTitle = styled(H2)`
  color: white;
  padding-bottom: 20px;

  ${p => p.theme.Hack.desktopEn`
    font-size: 36px;
    font-weight: 500;
 `}

  ${Media.phone`
    padding-bottom: .4rem;
  `}
`
export const BannerDesc = styled.p`
  max-width: 670px;
  color: white;
  font-size: ${typography.textSmall};
  line-height: 26px;
  ${Media.phone`
    line-height: .4rem;
    font-size: .24rem;
  `}
`
export const BannerButton = styled(Button)`
  margin-top: 30px;

  ${p => p.theme.Hack.desktopEn`
    min-width: 120px;
    min-height: 44px;
    font-size: 20px;
 `}

  ${Media.phone`
    margin-top: .3rem;
  `}
`
