import Img from 'gatsby-image'
import styled from 'styled-components'
import { Media } from '../theme'
import NewMedia from '../theme/new-media'
const Billboard = styled(Img)`
  z-index: -1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  ${Media.phone`
    max-width: 100vw;
 `}
  ${NewMedia.phone`
    // height: 10.2rem;
 `}
`

export default Billboard
