import React from 'react'

import { Anchor } from '../atoms'
import { PartnerWrap } from './atoms'
import intl from 'react-intl-universal'
import PoliceIcon from './images/police.png'

class Partner extends React.PureComponent {
  render() {
    return (
      <PartnerWrap halign='space-between'>
        <p>
          <img src={PoliceIcon} alt='' style={{ marginRight: 5, verticalAlign: 'top' }} />© {new Date().getFullYear()}
          <Anchor href='https://www.raysync.cn' rel='nofollow'>
            {intl.get('app.company')}
          </Anchor>
          <Anchor target='_blank' href='https://beian.miit.gov.cn/ ' rel='nofollow'>
            粤ICP备15007801号-2
          </Anchor>
        </p>
      </PartnerWrap>
    )
  }
}

export default Partner
