import React from 'react'

const SvgPlay = props => (
  <svg width={24} height={25} viewBox='0 0 24 25' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 24.5c6.627 0 12-5.373 12-12S18.627.5 12 .5 0 5.873 0 12.5s5.373 12 12 12zm-1.47-7.328l6.115-3.824a1 1 0 000-1.696L10.53 7.828A1 1 0 009 8.676v7.648a1 1 0 001.53.848z'
      fill='#fff'
    />
  </svg>
)

export default SvgPlay
