import http from './base.js'
import { getCookie } from './utils'
import { Navigation } from '../../services'
const referrerTypeMap = {
  GADI: '2022 GADI汽车峰会',
}
export default {
  add(params) {
    const referrerType = Navigation.getParam('referrerType')
    return http.post('/raysync_apply/add', {
      ...params,
      referrer: referrerTypeMap[referrerType] || getCookie('referrer'),
    })
  },
  addAgentInfo(params) {
    return http.post('/raysync_proxy/add', params)
  },
  addFreeTrial(params) {
    return http.post('/license_recharge/spring_trial', params)
  },
  getSmsCode(params) {
    return http.get('/license_recharge/spring_trial_send_sms', { params })
  },
  /**
   *
   * @param {*} params
   * type 验证码类型，1，短信验证码，2，语音验证码
   * phone 手机号
   */
  sendVerificationCode(params) {
    return http.post('/raysync_proxy/verification/code', params)
  },
  /**
   * 
   * @param {*} params 
   * {
   * "company": "测试公司",
      "jobTitle": "产品经理",
      "name": "李四",
      "phone": "1234345435",
      "email": aaa@aaa.com,
      "codeType": 1,
      "verificationCode": 123456,
      "area": 1,
      "message": "this is a message"
    }
   */
  addPartnerInfo(params) {
    return http.post('/raysync_proxy/add/v2', params)
  },
}
