import styled, { css } from 'styled-components'
import { H2, H3, Wrapper } from '@raysync/common/components'
import { BannerButton } from '../atoms'

export const MainBannerContainer = styled.div`
  position: relative;
  margin-top: 60px;
  height: 520px;
`

export const BannerWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;
`

export const MainBannerButton = styled(BannerButton)`
  margin-top: 10px;
`

export const BannerLeft = styled.div`
  color: white;
`
export const BannerRight = styled.div`
  position: relative;
  width: 700px;
  height: 350px;
  img {
    position: absolute;
    width: 800px;
    right: 0px;
  }
`

export const BannerTitle = styled(H2)`
  ${({ theme: { color } }) => css`
    font-size: 40px;
    color: ${color.secondary};
    line-height: 36px;
    span {
      font-weight: 700;
    }
  `}
`
export const BannerSubTitle = styled(H3)`
  ${({ theme: { color } }) => css`
    font-size: 24px;
    font-weight: 500;
    color: ${color.primary};
    line-height: 36px;
    margin: 18px 0 18px;
  `}
`
export const BannerDesc = styled.p`
  ${({ theme: { color, typography } }) => css`
    font-size: ${typography.text};
    font-weight: 500;
    color: ${color.primary};
    margin-bottom: 14px;
  `}
`
export const Divider = styled.div`
  background: white;
  height: 3px;
  width: 40px;
`
export const Affix = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`
