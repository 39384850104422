import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import IconType, { IconMap } from './type'
import { color } from '../../theme'

const IconSvg = styled.svg`
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  color: ${color.textLight};
`

class Icon extends Component {
  render() {
    const { type } = this.props
    return (
      <IconSvg aria-hidden='true' viewBox='0 0 1024 1024'>
        <path d={IconMap.get(type)} />
      </IconSvg>
    )
  }
}

Icon.propTypes = {
  type: PropTypes.oneOf(Object.values(IconType)),
}

export default Icon
