import React from 'react'
import intl from 'react-intl-universal'
import { Navigation } from '@raysync/common/services'
import Button from '../button'
import { LargeWrapper } from '../wrapper.js'
import styled from 'styled-components'
import { transition } from '../../theme'
import frialBg from './images/frial-bg.png'
import applyBg from './images/try-bg.png'
import NewMedia from '../../theme/new-media'
import { useMedia } from '@raysync/common/hooks'

export const TrialContainer = styled(LargeWrapper)`
  /* background-image: ${p => (p.isMedia ? `url(${frialBg})` : `url(${applyBg})`)}; */
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  /* top: 60px; */
  z-index: 1;
  /* margin-top: 10px; */
  /* border-radius: 30px; */
  transition: ${transition()};
  :hover {
    box-shadow: 0px 18px 52px -32px ${p => p.theme.color.secondary};
  }

  width: 100% !important;
  max-width: 100% !important;
  ${NewMedia.book`
    margin-left:0;
  `}
  ${NewMedia.phone`
    height:3.6rem;
    padding:0;
    align-items: flex-start;
  `}
`
export const TrialTitle = styled.p`
  font-size: 40px;
  margin-bottom: 8px;
  font-weight: bold;
  ${NewMedia.phone`
    font-size:.4rem;
    margin:.66rem auto 0;
    text-align: center;
  `}
`
export const TrialDesc = styled.p`
  font-size: 18px;
  ${NewMedia.phone`
    font-size:.24rem;
    width:5.84rem;
    text-align: center;
    margin: 0.2rem  auto 0;
  `}
`
export const TrialContent = styled.div`
  min-width: 800px;
  max-width: 1308px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${NewMedia.phone`
    min-width: 0;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    .phone-flex-start{
      width: 100%;
    }
  `}
`
export const TrialButton = styled(Button)`
  width: ${({ locale }) => (locale === 'ja' ? '' : '200px')};
  height: 40px;
  border-radius: 25px;
  :hover {
    box-shadow: 0px 10px 16px 4px rgb(253 92 31 / 30%);
  }
  ${NewMedia.phone`
    width: ${({ locale }) => (locale === 'ja' ? '4rem' : '3rem')};
    height: .6rem;
    margin-top:.55rem;
    font-size:.27rem;
    align-self: center;
    line-height:.27rem;
  `}
`
export const Img = styled.img`
  z-index: -1;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
const ApplyBanner = ({ locale }) => {
  const isMedia = useMedia()
  return (
    <TrialContainer isMedia={isMedia}>
      <TrialContent>
        <div className='phone-flex-start'>
          <TrialTitle>{intl.get('home.apply.banner.title')}</TrialTitle>
          <TrialDesc>
            {/* Raysync offers high-speed file transfer solutions and free technical support for enterprise users! */}
            {/* Raysync offers free file transfer solutions and technical support for enterprise users! */}
            {intl.get('home.apply.banner.desc')}
          </TrialDesc>
        </div>
        <TrialButton
          locale={locale}
          id='Index-GFT-2'
          color='white'
          rel='nofollow'
          onClick={() => {
            Navigation.toApply({ type: 'apply' })
          }}
        >
          {intl.get('home.apply.banner.button')}
        </TrialButton>
      </TrialContent>
      <Img src={isMedia ? frialBg : applyBg} alt='apply banner' />
    </TrialContainer>
  )
}
export default ApplyBanner
