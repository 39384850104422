import React from 'react'
import Toast from './toast'

let seed = 0
const now = Date.now()
const getUUID = () => `${now}_${seed++}`

class Manager extends React.PureComponent {
  state = { oven: [] }
  render() {
    const { oven } = this.state
    return oven.map(dough => (
      <Toast duration={dough.duration} key={dough.key} type={dough.type} onClose={this.remove.bind(this, dough.key)}>
        {dough.content}
      </Toast>
    ))
  }
  add(content, duration = 3000, type = 'info') {
    // TODO: length limit & update content by id
    this.setState(prevState => ({
      oven: [...prevState.oven, { key: getUUID(), content, duration, type }],
    }))
  }
  remove(key) {
    this.setState(prevState => ({
      oven: prevState.oven.filter(dough => dough.key !== key),
    }))
  }
}

export default Manager
