import { stripUnit } from 'polished'
import styled, { css } from 'styled-components'

import { fadeIn, fadeOut, scaleIn, scaleOut, Media } from '../../theme'

export const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  touch-action: none;
  transform: scale(1);
  background-color: rgba(0, 0, 0, 0.4);
  &.entering {
    animation: ${fadeIn} 300ms;
  }
  &.exiting {
    animation: ${fadeOut} 300ms;
  }
`
export const Wrap = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  ${p => (p.top ? `top:${p.top};` : `top: 0;bottom: 0;`)}
  z-index: 8;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &.entering {
    animation: ${scaleIn} 300ms;
  }
  &.exiting {
    animation: ${scaleOut} 300ms;
  }
`
export const Content = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    overflow-y: auto;
    @media (min-width: 500px) {
      min-width: ${props => props.minWidth};
    }
    flex-direction: column;
    margin: ${spacing.base};
    background: white;
    border-radius: 6px;
    box-shadow: 0px 0px 16px 0px rgba(161, 179, 173, 0.35);
    border-radius: 10px;
    max-height: calc(100% - ${2 * stripUnit(spacing.base)}px);
  `}
`
export const Spotlight = styled.div`
  ${({ theme: { color, typography, spacing } }) => css`
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: ${spacing.small} ${spacing.base} ${spacing.base};
    ${Media.phone`
      padding: 10px;
    `}
  `}
`
export const Header = styled.div`
  ${({ theme: { color, spacing } }) => css`
    display: flex;
    justify-content: space-between;
    > h6 {
      margin: 0;
      height: 2.5em;
      line-height: 2.5em;
      color: ${color.primary};
      font-weight: 500;
      font-size: 14px;
      padding-left: ${spacing.base};
      word-wrap: break-word;
    }
  `}
`
