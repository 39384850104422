import React from 'react'
import { TitleIconWrap } from './atoms'

const TitleIcon = () => (
  <TitleIconWrap>
    <span />
    <span />
  </TitleIconWrap>
)

export default TitleIcon
