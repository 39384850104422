import React from 'react'

const SvgIndustry5 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <filter
        id='industry5_svg__a'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={20}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry5_svg__c'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={22}
        height={20}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={6} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='industry5_svg__f'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={7}
        height={7}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
      <filter
        id='industry5_svg__g'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={7}
        height={7}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
      <filter
        id='industry5_svg__h'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={14}
        height={8}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='industry5_svg__b'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry5_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='industry5_svg__e'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
    </defs>
    <g filter='url(#industry5_svg__a)'>
      <path
        d='M3.644 17.224A1.649 1.649 0 012 15.553v-5.165a3.33 3.33 0 011.83-2.976l.37-.188 1.274-5.506A2.2 2.2 0 017.616 0h10.826a2.19 2.19 0 012.142 1.741L21.8 7.224l.37.188A3.33 3.33 0 0124 10.388v5.165c0 .918-.741 1.67-1.644 1.67h-1.1v.553c0 1.224-.984 2.224-2.2 2.224-1.216 0-2.2-1-2.2-2.224v-.552h-7.7v.552C9.156 19 8.172 20 6.956 20c-1.216 0-2.2-1-2.2-2.224v-.552H3.644z'
        fill='url(#industry5_svg__b)'
      />
    </g>
    <g filter='url(#industry5_svg__c)'>
      <path
        d='M1.644 21.224A1.649 1.649 0 010 19.553v-5.165a3.33 3.33 0 011.83-2.976l.37-.188 1.274-5.506A2.2 2.2 0 015.616 4h10.826a2.19 2.19 0 012.142 1.741l1.216 5.483.37.188A3.33 3.33 0 0122 14.388v5.165c0 .918-.741 1.67-1.644 1.67h-1.1v.553c0 1.224-.984 2.224-2.2 2.224-1.216 0-2.2-1-2.2-2.224v-.552h-7.7v.552C7.156 23 6.172 24 4.956 24c-1.216 0-2.2-1-2.2-2.224v-.552H1.644z'
        fill='url(#industry5_svg__d)'
      />
      <path
        d='M1.83 11.412l.37-.188 1.274-5.506A2.2 2.2 0 015.616 4h10.826a2.19 2.19 0 012.142 1.741l1.216 5.483.37.188A3.33 3.33 0 0122 14.388v5.165c0 .918-.741 1.67-1.644 1.67h-1.1v.553c0 1.224-.984 2.224-2.2 2.224-1.216 0-2.2-1-2.2-2.224v-.552h-7.7v.552C7.156 23 6.172 24 4.956 24c-1.216 0-2.2-1-2.2-2.224v-.552H1.644A1.649 1.649 0 010 19.553v-5.165a3.33 3.33 0 011.83-2.976zm-.186 8.812h1.112q.098 0 .195.019.096.019.187.057.091.037.173.092.082.055.152.124.07.07.124.152.055.082.093.173.037.09.057.187.019.097.019.196v.552q0 .508.355.868.352.356.845.356t.844-.356q.356-.36.356-.867v-.553q0-.099.019-.196.02-.096.057-.187.038-.091.092-.173.055-.082.125-.152.07-.07.151-.124.082-.055.173-.092.091-.038.188-.057.096-.02.195-.02h7.7q.098 0 .195.02.097.019.188.057.09.037.172.092.082.055.152.124.07.07.124.152.055.082.093.173.037.09.057.187.019.097.019.196v.552q0 .508.355.868.352.356.845.356t.844-.356q.356-.36.356-.867v-.553q0-.099.019-.196.02-.096.057-.187.038-.091.092-.173.055-.082.125-.152.07-.07.151-.124.082-.055.173-.092.091-.038.188-.057.096-.02.195-.02h1.1q.258 0 .448-.192.196-.2.196-.478v-5.165q0-.662-.346-1.225-.345-.562-.936-.86l-.37-.188q-.1-.05-.185-.12-.085-.071-.153-.159-.068-.087-.115-.188-.047-.1-.071-.208l-1.218-5.489q-.09-.421-.419-.687Q16.862 5 16.442 5H5.616q-.416 0-.742.26-.33.264-.426.683l-1.274 5.506q-.024.107-.072.206-.047.099-.114.185-.068.086-.153.156-.084.07-.182.12l-.374.19q-.588.295-.933.857Q1 13.726 1 14.388v5.165q0 .287.192.482.185.189.452.189z'
        fillRule='evenodd'
        fill='url(#industry5_svg__e)'
      />
    </g>
    <g filter='url(#industry5_svg__f)'>
      <path d='M6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 100 3z' fillRule='evenodd' fill='#FFF' />
    </g>
    <g filter='url(#industry5_svg__g)'>
      <path d='M15.5 18a1.5 1.5 0 100-3 1.5 1.5 0 100 3z' fillRule='evenodd' fill='#FFF' />
    </g>
    <g filter='url(#industry5_svg__h)' fill='#FFF'>
      <path d='M6 12h10l-.821-3.304C15.074 8.292 14.674 8 14.2 8H7.8c-.474 0-.874.292-.979.696L6 12z' />
      <path
        d='M6.881 7.79q-.419.3-.544.78l-.001.006-.821 3.303q-.012.048-.014.097-.003.05.005.098.007.049.024.095.016.047.042.089.025.042.058.078.033.037.073.066.04.03.084.05.045.021.092.033.06.015.121.015h10q.05 0 .098-.01t.093-.028q.046-.019.087-.046.04-.028.076-.062.034-.035.062-.076.027-.041.046-.087.019-.045.028-.093.01-.049.01-.098 0-.061-.015-.12l-.82-3.304q-.127-.486-.546-.786-.405-.29-.919-.29H7.8q-.514 0-.919.29zm.425 1.03l-.667 2.68h8.722l-.667-2.683Q14.61 8.5 14.2 8.5H7.8q-.41 0-.494.32z'
        fillRule='evenodd'
      />
    </g>
  </svg>
)

export default SvgIndustry5
