import React from 'react'
import intl from 'react-intl-universal'
import { StaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Billboard, H3, Button, LargeWrapper, Swiper, H1 } from '@raysync/common/components'
import { useMedia } from '@raysync/common/hooks'
export const BannerContainer = styled.div`
  position: relative;
  height: 500px;
  overflow: hidden;
  z-index: 0;
  background-color: ${p => p.theme.color.panel};
  ${Media.phone`
    height: 9rem;
  `}
`
export const BannerWrap = styled(LargeWrapper)`
  height: 100%;
  padding-top: 40px;
  color: ${p => p.theme.color.textDarkPlus};
  h1 {
    font-weight: bold;
    line-height: 1.5;
    font-size: 48px;
    margin: 25px 0 25px;
  }
  p {
    line-height: 1.5;
    max-width: 580px;
    margin-bottom: 30px;
    color: rgb(90, 110, 120);
    font-size: 18px;
  }
  ${Media.phone`
    height: 9rem;
    padding: 1.1rem .5rem 0;
    h1 {
      font-size: .54rem;
      margin: .3rem 0;
    }
    p {
      margin-bottom: .3rem;
      max-width: 5.8rem;
    }
  `}
`

export const BannerTitle = styled(H3)`
  font-size: 32px;
  font-weight: 500;
  padding-bottom: 14px;
`
export const BannerDesc = styled.p`
  ${({ theme: { color, typography } }) => css`
    font-size: ${typography.textThin};
    line-height: 22px;
    margin-top: 18px;
    color: ${color.text};
  `}
`

export const AgentBanner = props => {
  const isMobile = useMedia()
  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { regex: "/agent/banner_3.png/" }) {
            ...fluidImage
          }
          mobileBanner: file(relativePath: { regex: "/agent/banner_3_m.png/" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        const bannerImage = isMobile ? data.mobileBanner.childImageSharp : data.banner.childImageSharp
        return (
          <BannerContainer>
            <BannerWrap>
              <H1>{intl.get(`agent.banner.title`)}</H1>
              <p>{intl.getHTML(`agent.banner.desc1`)}</p>
              <p>{intl.getHTML(`agent.banner.desc2`)}</p>
            </BannerWrap>
            <Billboard fluid={bannerImage.fluid} />
          </BannerContainer>
        )
      }}
    />
  )
}

const Banner2Container = styled(BannerContainer)`
  padding-top: 137px;
`
const Banner2Wrap = styled(BannerWrap)`
  max-width: 542px;
`
const Banner2Title = styled(BannerTitle)`
  font-size: 40px;
  font-size: 500;
  color: #fff;
`
const Banner2Desc = styled(BannerDesc)`
  color: #fff;
  font-size: ${p => p.theme.typography.textSmall};
  margin-bottom: 20px;
  ${Media.phone`
    margin-bottom: 0.6rem;
  `}
`

export const AgentBanner2 = ({ data }) => (
  <Banner2Container>
    <Banner2Wrap>
      <Banner2Title>{intl.get(`agent.banner.title`)}</Banner2Title>
      <Banner2Desc>{intl.getHTML(`agent.banner2.desc`)}</Banner2Desc>
      <Button as='a' href='#apply-btn'>
        {intl.get(`agent.apply`)}
      </Button>
    </Banner2Wrap>
    {data && <Billboard fluid={data} />}
  </Banner2Container>
)
