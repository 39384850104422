import React, { useState } from 'react'
import intl from 'react-intl-universal'
import styled from 'styled-components'

import { Formik } from 'formik'
import { LocaleService } from '@raysync/common/services'
import API from '@raysync/common/services/api'
import { EnPhoneCode } from '../../config/constant'
import { Modal, H2, Button, Toast, Select, Option, Input } from '../../components'
import ConcactIcon from '../svg/contact'

export const ConcactWrap = styled.div`
  z-index: 9;
  position: fixed;
  width: 1.2rem;
  height: 1.2rem;
  right: 0.35rem;
  bottom: 1.26rem;
  border-radius: 50%;
  background: ${p => p.theme.color.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 0.5rem;
    height: 0.5rem;
  }
`
export const ApplyContainer = styled.div`
  width: 6rem;
  padding: 0 0.2rem 0.4rem;
  h2 {
    text-align: center;
    margin-bottom: 0.4rem;
  }
  .rc-select-selector {
    height: 0.7rem;
  }
`
export const FormItem = styled.div`
  margin-bottom: 14px;
  input {
    display: block;
  }
`
export const ButtonWrapper = styled.div`
  button {
    width: 100%;
    height: 0.8rem !important;
    display: block;
    margin-top: 0.4rem;
  }
`
const PhoneWrap = styled.div`
  display: flex;
  input {
    margin-bottom: 0;
  }
`

const userTypeMap = Object.freeze({
  enterprise: '企业用户',
  college: '高校组织',
  social: '社会组织',
  personal: '个人用户',
})

const currentArea = LocaleService.isForeign ? 2 : 1

const MobileConcact = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [userType, setUserType] = useState('enterprise')
  const initialValues = {
    name: '',
    phone: '',
    concation: '',
    company: '',
    mobileAreaCode: LocaleService.isForeign ? 1 : 86,
    userType: 'enterprise',
  }
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    let validate = values.name && values.phone && values.concation
    if (values.userType !== 'personal') {
      validate = validate && values.company
    }
    if (!validate) {
      Toast.error(intl.get('apply.submit.tips'))
      setSubmitting(false)
      return
    }
    const data = {
      ...values,
      company: values.userType === 'personal' ? '' : values.company,
      userType: userTypeMap[values.userType],
      area: currentArea,
      type: '移动端弹窗申请试用',
    }

    API.apply
      .add(data)
      .then(response => {
        let data = response.data
        if (data.code === 0) {
          // this.reportBingEvent()
          Toast.info(intl.get('apply.submit.success'))
          resetForm()
          setIsVisible(false)
          setUserType('enterprise')
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  const userTypeInfo = {
    enterprise: { placeholder: intl.get('apply.placeholder.userType2') },
    college: { placeholder: intl.get('apply.placeholder.userType3') },
    social: { placeholder: intl.get('apply.placeholder.userType4') },
  }[userType]
  return (
    <>
      <ConcactWrap
        onClick={() => {
          setIsVisible(true)
        }}
      >
        <ConcactIcon></ConcactIcon>
      </ConcactWrap>
      <Modal
        visible={isVisible}
        onClose={() => {
          setIsVisible(false)
        }}
      >
        <ApplyContainer>
          <H2>{intl.get('apply.title1')}</H2>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, errors, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <FormItem>
                  <Select
                    name='userType'
                    onChange={value => {
                      setFieldValue('userType', value)
                      setUserType(value)
                    }}
                    onBlur={handleBlur}
                    value={values.userType}
                  >
                    <Option value='personal' label={intl.get('apply.userType.option1')}>
                      {intl.get('apply.userType.option1')}
                    </Option>
                    <Option value='enterprise' label={intl.get('apply.userType.option2')}>
                      {intl.get('apply.userType.option2')}
                    </Option>
                    <Option value='college' label={intl.get('apply.userType.option3')}>
                      {intl.get('apply.userType.option3')}
                    </Option>
                    <Option value='social' label={intl.get('apply.userType.option4')}>
                      {intl.get('apply.userType.option4')}
                    </Option>
                  </Select>
                </FormItem>

                {userType !== 'personal' && (
                  <FormItem>
                    <Input
                      placeholder={userTypeInfo.placeholder}
                      name='company'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                    />
                  </FormItem>
                )}
                <FormItem>
                  <Input
                    placeholder={intl.get('apply.placeholder.name')}
                    name='name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </FormItem>
                <FormItem>
                  <PhoneWrap>
                    {LocaleService.isForeign && (
                      <Select
                        name='mobileAreaCode'
                        dropdownMatchSelectWidth={false}
                        labelInValue
                        style={{ width: '160px' }}
                        onSelect={event => {
                          setFieldValue('mobileAreaCode', event.value)
                        }}
                        value={[{ key: values.mobileAreaCode, label: '+' + values.mobileAreaCode }]}
                      >
                        {Object.entries(EnPhoneCode).map(([key, value]) => (
                          <Option value={key} key={key} style={{ width: '230px' }}>{`${value}(+${key})`}</Option>
                        ))}
                      </Select>
                    )}

                    <Input
                      placeholder={intl.get('apply.placeholder.phone')}
                      name='phone'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      isError={errors.phone}
                    />
                  </PhoneWrap>
                </FormItem>
                <FormItem>
                  <Input
                    placeholder={intl.get('apply.placeholder.concation')}
                    name='concation'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.concation}
                  />
                </FormItem>

                {/* 提交按钮 */}
                <ButtonWrapper>
                  <Button type='submit' id='modal-apply' size='large' loading={isSubmitting}>
                    {intl.get('apply.submit')}
                  </Button>
                </ButtonWrapper>
              </form>
            )}
          </Formik>
        </ApplyContainer>
      </Modal>
    </>
  )
}
export default MobileConcact
