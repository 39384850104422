import React from 'react'

import { Row, Column } from '../grid'
import { useMedia } from '../../hooks'
import { LocaleService } from '../../services'
import QrcodeIcon from './images/qrcode.png'
import intl from 'react-intl-universal'
import { TitleLink, Title, NavAnchor, NavSpan, NavLink, PostCardsContainer, QRCodeWrap, DeusColumn } from './atoms'

const currentPackageName = process.env.GATSBY_PACKAGE_NAME
const isInMain = currentPackageName === 'main'
const isInNews = currentPackageName === 'news'
const isInSupport = currentPackageName === 'support'

const PostCards = ({ blog }) => {
  const isMoblie = useMedia()
  return (
    <PostCardsContainer>
      <Row>
        {/* products */}
        <DeusColumn lg='1.76' xs='6'>
          <Title>{intl.get('app.header.products')}</Title>
          <NavLink isInternal={isInMain} to='/products/file/'>
            {intl.get('app.footer.product1')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/raysync-ferry/'>
            跨网文件交换系统
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/ftp-alternative-upgrade/'>
            FTP替代升级
          </NavLink>
          <NavLink isInternal={isInMain} to='/products/sdk/'>
            {intl.get('app.footer.product2')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/products/vpn/'>
            {intl.get('app.footer.product3')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/products/ftp/'>
            {intl.get('app.footer.product4')}
          </NavLink>
        </DeusColumn>
        {/* app-solution-scenes */}
        <DeusColumn lg='1.88' xs='6'>
          <Title>{intl.get('app.footer.scenes.title')}</Title>
          <NavLink isInternal={isInMain} to='/solutions/large-file-transfer/'>
            {intl.get('app.footer.scenes5')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/data-transfer/'>
            {intl.get('app.footer.scenes6')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/multi-transfer/'>
            {intl.get('app.footer.scenes7')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/data-sync/'>
            {intl.get('app.footer.scenes4')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/file-exchange/'>
            {intl.get('solution-file-exchange.banner.title')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/data-migration/'>
            {intl.get('app.footer.scenes3')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/data-collaboration/'>
            {intl.get('app.footer.scenes1')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/data-distribution/'>
            {intl.get('app.footer.scenes2')}
          </NavLink>

          {/* <NavLink isInternal={isInMain} to='/solutions/file-management/'>
            {intl.get('solution8.seo.h1')}
          </NavLink> */}
        </DeusColumn>
        {/* Industry Solutions */}
        <DeusColumn lg='1.88' xs='6'>
          <Title>{intl.get('app.footer.solution.title')}</Title>
          <NavLink isInternal={isInMain} to='/solutions/industry-government/'>
            政务与科研院所
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-ad/'>
            {intl.get('app.footer.solution2')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-financing/'>
            {intl.get('app.footer.solution3')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-gene/'>
            {intl.get('app.footer.solution4')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-car/'>
            {intl.get('app.footer.solution5')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-manufacturing/'>
            {intl.get('app.footer.solution6')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-internet/'>
            {intl.get('app.footer.solution7')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/solutions/industry-moive/'>
            影视制作
          </NavLink>
        </DeusColumn>
        {/* Fast-entry */}
        <DeusColumn lg='1.88' xs='6'>
          <Title>{intl.get('app.footer.entry.title')}</Title>
          <NavLink isInternal={isInMain} to='/about-us'>
            {intl.get('app.footer.entry1')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/example/'>
            {intl.get('app.footer.entry3')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/pricing/'>
            {intl.get('app.footer.entry4')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/partners/'>
            {intl.get('app.header.agent')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/term-of-service/'>
            {intl.get('app.footer.entry6')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/calculator/'>
            {intl.get('app.footer.calculator')}
          </NavLink>
        </DeusColumn>
        <DeusColumn lg='3.23'>
          <TitleLink href='https://www.raysync.cn/blog' target='_blank'>
            {intl.get('app.footer.blog')}
          </TitleLink>
          {blog.map(item => (
            <NavLink key={item.slug} isInternal={isInNews} to={`/blog/${item.slug}`}>
              {item.title}
            </NavLink>
          ))}
        </DeusColumn>
        <DeusColumn lg='1.24' xs='4'>
          {!LocaleService.isForeign && (
            <QRCodeWrap>
              <img alt={intl.get('app.footer.qrcode.text')} src={QrcodeIcon}></img>
              <p>{intl.get('app.footer.qrcode.text')}</p>
            </QRCodeWrap>
          )}
        </DeusColumn>
      </Row>
      <Row style={{ paddingTop: '26px' }}>
        {/* Net-products */}
        <DeusColumn lg='1.76' xs='6'>
          <Title>{intl.get('app.footer.net.product.title')}</Title>
          <NavLink isInternal={isInMain} to='/network/point/'>
            {intl.get('app.footer.net.product1')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/network/ipv6/'>
            {intl.get('app.footer.net.product2')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/network/sd-wan/'>
            {intl.get('app.footer.net.product3')}
          </NavLink>
        </DeusColumn>

        {/* Net-solutions */}
        <DeusColumn lg='1.88' xs='6'>
          <Title>{intl.get('app.footer.net.solution.title')}</Title>
          <NavLink isInternal={isInMain} to='/network/hybrid-cloud/'>
            {intl.get('app.footer.net.solution1')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/network/enterprise-interconnection/'>
            {intl.get('app.footer.net.solution2')}
          </NavLink>
          <NavLink isInternal={isInMain} to='/network/one-kilometer/'>
            {intl.get('app.footer.net.solution3')}
          </NavLink>
        </DeusColumn>

        {/* Support & Serivce */}
        <DeusColumn lg='1.88' xs='6'>
          <Title>{intl.get('app.footer.support.title')}</Title>
          <NavLink isInternal={isInSupport} to='/support/document/white-paper/'>
            {intl.get('app.footer.support1')}
          </NavLink>
          <NavLink isInternal={isInSupport} to='/support/document/product/Install-faq/'>
            {intl.get('app.footer.support2')}
          </NavLink>
          <NavLink isInternal={isInSupport} to='/support/document/video/raysync/'>
            {intl.get('app.footer.support3')}
          </NavLink>
        </DeusColumn>
        {/* Concact-us */}
        <Column lg='1.88' xs='8'>
          <Title>{intl.get('app.footer.contact')}</Title>
          <NavSpan rel='noopener noreferrer dofollow' href={`tel:${intl.get('app.contact.telephone.value')}`}>
            {`${intl.get('app.footer.contact.consulting')}: ${intl.get('app.contact.telephone.value')}`}
          </NavSpan>
          <NavAnchor rel='noopener noreferrer dofollow' href={`mailto:${intl.get('app.contact.email.value')}`}>
            {`${intl.get('app.footer.contact.email')}: ${intl.get('app.contact.email.value')}`}
          </NavAnchor>
        </Column>
        {isMoblie && (
          <Column lg='0' xs='0'>
            <QRCodeWrap>
              <img alt={intl.get('app.footer.qrcode.text')} src={QrcodeIcon}></img>
              <p>{intl.get('app.footer.qrcode.text')}</p>
            </QRCodeWrap>
          </Column>
        )}
      </Row>
    </PostCardsContainer>
  )
}

export default PostCards
