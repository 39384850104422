import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Wrapper } from '@raysync/common/components'
export const BannerContainer = styled.div`
  height: 200px;
  ${Media.phone`
    height: unset;
  `}
  ${p => p.theme.Hack.mobileForeign`
    height: 3rem;
  `}
`
export const BannerWrap = styled(Wrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  /* padding: ${p => (p.variant === 'normal' ? '0 100px' : '0 80px')}; */
  ${Media.phone`
    flex-wrap:wrap;
    justify-content: center;
    padding: .6rem 0 .7rem ;
  `}
  ${p => p.theme.Hack.en`padding:0 !important;`};
`

export const InviteTextContainer = styled.div`
  ${({ theme: { typography, Hack } }) => css`
    line-height: 1;
    color: white;
    span {
      display: inline-block;
      font-size: 36px;
      font-weight: 500;
    }
    p {
      margin-top: 25px;
      font-size: ${typography.h4};
    }
    ${Media.phone`
      width: 100%;
      text-align:center;
      span {
        font-size: .48rem;
      
        text-align: center;
        line-height: 1.5;
      }
      p{
        font-size: .24rem;
        margin-top: .18rem;
      }
    `}
    ${Hack.mobileForeign`
      h2 {
        min-height: .6rem;
        line-height: 1.2;
      }
      p {
        line-height: 1.2;
      }
    `}
  `}
`

export const ButtonContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    button {
      :nth-child(2) {
        margin-left: ${spacing.base};
      }
    }
    ${Media.phone`
      display:flex;
      margin-top: .3rem;
    `}
  `}
`
