import React, { useState, useCallback } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { useMedia } from '@raysync/common/hooks'
import { range } from '../../utils'
import {
  SwipeNavWrap,
  SwipeNav,
  SwiperContainer,
  EnSwipeNavWrap,
  EnSwipeNav,
  TouchBarNavWrap,
  TouchBarNav,
  ArrowSwiperWrapper,
  ArrowButton,
  DotNavWrap,
  DotNav,
} from './atoms'

import GtIcon from '@raysync/common/components/svg/gt'
import SwipeNavigation from '@raysync/main/src/components/SwipeNavigation/SwipeNavigation'

export const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Swiper = ({ children, activedColorIndex, swiperNavType = 'default', interval = 8000, onChangeIndex }) => {
  const [index, setIndex] = useState(0)
  const isMobile = useMedia()
  const [isFocused, setIsFocused] = useState(false) // 确保状态被声明

  const handleChangeIndex = useCallback(
    newIndex => {
      if (onChangeIndex && !isFocused) {
        // 只有在未聚焦时才切换
        onChangeIndex(newIndex)
      }
      setIndex(newIndex)
    },
    [onChangeIndex, isFocused], // 添加 isFocused 依赖
  )

  const SwiperNav = {
    default: (
      <SwipeNavWrap>
        {range(children.length).map(i => (
          <SwipeNav
            key={i}
            active={i === index}
            activedColor={activedColorIndex === i}
            onClick={() => handleChangeIndex(i)}
          />
        ))}
      </SwipeNavWrap>
    ),
    defaultHome: !isMobile && (
      <SwipeNavigation
        children={children}
        index={index}
        activedColorIndex={activedColorIndex}
        handleChangeIndex={handleChangeIndex}
      />
    ),
    defaultEn: (
      <EnSwipeNavWrap>
        {range(children.length).map(i => (
          <EnSwipeNav
            key={i}
            active={i === index}
            activedColor={activedColorIndex === i}
            onClick={() => handleChangeIndex(i)}
          />
        ))}
      </EnSwipeNavWrap>
    ),
    touch: (
      <TouchBarNavWrap activedIndex={index}>
        {range(children.length).map(i => (
          <TouchBarNav
            key={i}
            active={i === index}
            activedColor={activedColorIndex === i}
            onClick={() => handleChangeIndex(i)}
          />
        ))}
      </TouchBarNavWrap>
    ),
    dot: (
      <DotNavWrap className='swiper-dot'>
        {range(children.length).map(i => (
          <DotNav
            key={i}
            active={i === index}
            activedColor={activedColorIndex === i}
            onClick={() => handleChangeIndex(i)}
          />
        ))}
      </DotNavWrap>
    ),
  }

  return (
    <SwiperContainer
      onMouseEnter={() => setIsFocused(true)} // 鼠标进入时设置为聚焦
      onMouseLeave={() => setIsFocused(false)} // 鼠标离开时取消聚焦
    >
      <AutoPlaySwipeableViews
        index={index}
        interval={isFocused ? 800000 : interval} // 仅在聚焦时切换
        enableMouseEvents
        onChangeIndex={handleChangeIndex}
      >
        {children}
      </AutoPlaySwipeableViews>
      {SwiperNav[swiperNavType]}
    </SwiperContainer>
  )
}

export default Swiper

export const ArrowSwiper = ({ children }) => {
  const [index, setIndex] = React.useState(0)
  return (
    <ArrowSwiperWrapper>
      <ArrowButton
        active={index > 0}
        isLeft
        onClick={() => {
          if (index > 0) {
            setIndex(index - 1)
          }
        }}
      >
        <GtIcon />
      </ArrowButton>
      <SwipeableViews index={index}>{children}</SwipeableViews>
      <ArrowButton
        active={index < children.length - 1}
        onClick={() => {
          if (index < children.length - 1) {
            setIndex(index + 1)
          }
        }}
      >
        <GtIcon />
      </ArrowButton>
    </ArrowSwiperWrapper>
  )
}
