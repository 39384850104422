import React, { PureComponent } from 'react'
import { FriendLinksContainer, NavAnchor } from './atoms'
import intl from 'react-intl-universal'

class FriendLinks extends PureComponent {
  render() {
    const { friendLinks } = this.props
    return (
      <FriendLinksContainer>
        <p>{`${intl.get('app.footer.friendLink')}`}</p>
        <div>
          {friendLinks &&
            friendLinks.map(({ name, link }, index) => (
              <NavAnchor key={index} href={link} target='_blank' rel='noopener noreferrer'>
                {name}
              </NavAnchor>
            ))}
        </div>
      </FriendLinksContainer>
    )
  }
}

export default FriendLinks
