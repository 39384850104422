import styled, { css } from 'styled-components'
import Flex from '../flex'
import Link from '../link'
import Wrapper from '../wrapper'
import { Column } from '../grid'
import { Anchor } from '../atoms'
import { hideAtMobile, Media } from '../../theme'
import { Icon } from '../icon'
import NewMedia from '../../theme/new-media'
export const Container = styled.footer`
  ${({ theme: { spacing } }) => css`
    position: relative;
    padding: 60px 0 ${spacing.base} 0;
    background-color: #13151d;
    ${NewMedia.book`
      padding-left:2rem;
    `}
    ${Media.phone`
      padding: 0 0 0.2rem 0;
    `}
  `}
`

export const PartnerWrap = styled(Flex)`
  ${({ theme: { typography, spacing, Hack } }) => css`
    font-size: ${typography.textThin};
    margin-bottom: 8px;
    font-weight: 500;
    > p {
      color: #8796aa;
      margin: 0;
      ${Media.phone`
      margin: 10px 0 0px 0;
    `}
    }
    a {
      color: #8796aa;
      margin-left: ${spacing.small};
    }
    ${Hack.foreign`
      display: none;
    `}
    ${Hack.desktopEn`
      font-size: ${typography.textMini};
    `}
  `}
`
export const PostCardsContainer = styled.div`
  ${({ theme: { typography } }) => css`
    padding-bottom: 78px;
    font-size: ${typography.textSmall};
    ${Media.phone`
      padding-bottom: 0.44rem;
    `}
  `}
`

export const DeusColumn = styled(Column)`
  ${hideAtMobile}
`

export const FooterWrapper = styled(Wrapper)`
  max-width: 1400px;
  width: 1400px;
`

export const Title = styled.p`
  ${({ theme: { typography } }) => css`
    color: white;
    line-height: 1;
    margin-top: initial;
    font-size: ${typography.textSmall};
    font-weight: bold;
    margin-bottom: 14px;
    margin-top: ${p => (p.top ? p.top + 'px' : 0)};
    ${NewMedia.phone`
      font-size:${({ locale, phoneFontSize }) => (locale !== 'zh-CN' ? phoneFontSize / 100 + 'rem' : '')}
    `}
  `}
`

export const TitleLink = Title.withComponent('a')

export const QRCodeWrap = styled.div`
  ${({ theme: { typography, spacing } }) => css`
    text-align: center;
    img {
      width: 140px;
      height: 140px;
    }
    p {
      margin-top: ${spacing.base};
      font-weight: 500;
      font-size: ${typography.textThin};
      color: #8796aa;
    }
    ${Media.phone`
      width: 1.4rem;
      img{
        width: 1.4rem;
        height:1.4rem;
      }
      p{
        margin-top: 0.2rem;
        font-size: ${typography.textMini};
      }
    `}
  `}
`
export const FriendLinksContainer = styled.div`
  ${({ theme: { typography, spacing, Hack } }) => css`
    color: #8796aa;
    font-size: 12px;
    margin-top: 17px;
    line-height: 1.5;
    a {
      display: inline-block;
      line-height: 1.5;
      margin-right: ${spacing.small};
    }
    p {
      display: inline-block;
      margin-right: 2px;
      margin-bottom: 4px;
    }

    ${Hack.desktopEn`
      font-size: ${typography.textMini};
    `}

    ${hideAtMobile}
  `}
`
export const ContactPhone = styled(Flex)`
  ${({ theme: { color, typography, spacing } }) => css`
    font-size: ${typography.textSmall};
    justify-content: flex-start;
    a {
      color: ${color.primary};
      margin-left: ${spacing.small};
    }
  `}
`
const commonLink = css`
  display: block;
  line-height: 2;
  color: #8796aa;
  font-weight: 500;
  font-size: ${p => p.theme.typography.textThin};
  ${Media.phone`
    /* color: #7e7e7e; */
    font-weight: 400;
  `}
  ${p => p.theme.Hack.desktopEn`
      font-size: ${p => p.theme.typography.textMini};
  `}
`

export const NavAnchor = styled(Anchor)`
  ${({ theme: { color } }) => css`
    ${commonLink}
    display:flex;
    align-items: center;
  `}
`
export const NavSpan = styled.span`
  ${commonLink}
  display:flex;
  align-items: center;
`
export const NavLink = styled(Link)`
  ${commonLink}
`
export const SnsIcon = styled(Icon)``
export const SnsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  svg {
    display: none;
  }
  a {
    width: 120px;
  }
  ${Media.phone`
    width: 2rem;
  `}
`

export const SubscribeForm = styled.form`
  padding-top: 8px;
  display: flex;
  input {
    border: 1px solid #666666;
    border-radius: 6px;
    width: 160px;
    height: 40px;
    color: white;
    ::placeholder {
      color: #7e7e7e;
    }
  }
  button {
    margin-left: 10px;
    padding: 0 10px;
    height: 40px;
  }
`

export const SubscribedText = styled.p`
  color: #8796aa;
  font-size: 14px;
  font-weight: 500;
`

export const SelectWrap = styled.div`
  width: 160px;
  ${NewMedia.phone`
    width:auto;
  `}
`
export const SelectOption = styled.a`
  display: block;
  color: #7e7e7e;
  cursor: pointer;
  line-height: 24px;
  font-size: 14px;
  /* in en-footer */
  :hover {
    color: ${p => p.theme.color.secondary};
  }
  ${NewMedia.phone`
    font-size:0.24rem;
    line-height: normal;
  `}
`

export const SnsContainer = styled.div`
  padding-top: 20px;
  p {
    color: #7e7e7e;
  }
  svg {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 10px;
    path {
      fill: rgb(193, 199, 214);
    }
    &:hover path {
      fill: unset;
    }
  }
`

export const LinkWrap = styled.div`
  margin-top: 15px;

  a {
    margin-right: 25px;
  }
`
export const EnContainer = styled(Container)`
  background-color: #0d1922;
  padding-top: ${({ showBanner, pageType }) => {
    if (!showBanner) return ''
    if (pageType === 'smb') return '130px'
    return '50px'
  }};

  ${NewMedia.phone`
   padding-top: ${({ showBanner, pageType }) => {
     if (!showBanner) return ''
     if (pageType === 'smb') return 'calc(1.48rem - 26px)'
     return 'calc(0.4rem - 26px)'
   }};
  `}
`
export const Copyright = styled.div`
  color: #7e7e7e;
  padding-top: 20px;
  margin-left: 278px;
  ${Media.phone`
    font-size: .24rem;
    padding-top: .2rem;
     margin-left: 0;
  `}
`
