import { css } from 'styled-components'
import { spacing } from './variables'
import { LOCALES } from '../services'
import Media from './media'

export const hideAtMobile = css`
  ${Media.phone`
    display: none;
  `}
`
export const showAtMobile = css`
  display: none;
  ${Media.phone`
    display: block;
  `}
`

export const fullWidthAtMobile = css`
  @media (max-width: 768px) {
    width: auto;
    margin: 0 ${spacing.base};
  }
`

export const hideScrollbar = css`
  ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`

export const textEllipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const multiLineTextEllipsis = line => {
  return css`
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: ${line};
    -webkit-box-orient: vertical;
  `
}
export function placeholder(color) {
  return css`
    ::-moz-placeholder {
      color: ${color};
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: ${color};
    }
    ::-webkit-input-placeholder {
      color: ${color};
    }
  `
}

export class Hack {
  currentLocale = ''

  constructor(locale) {
    this.currentLocale = locale || process.env.GATSBY_LOCALE
  }

  desktop = (...args) => {
    return css`
      ${Media.desktop`
        ${css(...args)}
      `}
    `
  }

  en = (...args) => {
    if (this.currentLocale === LOCALES.EN) {
      return css(...args)
    }
    return css``
  }

  desktopEn = (...args) => {
    if (this.currentLocale === LOCALES.EN) {
      return css`
        ${Media.desktop`
          ${css(...args)}
        `}
      `
    }
    return css``
  }

  mobileEn = (...args) => {
    if (this.currentLocale === LOCALES.EN) {
      return css`
        ${Media.phone`
          ${css(...args)}
        `}
      `
    }
    return css``
  }

  ja = (...args) => {
    if (this.currentLocale === LOCALES.JA) {
      return css(...args)
    }
    return css``
  }

  foreign = (...args) => {
    if (this.currentLocale !== LOCALES.ZH) {
      return css(...args)
    }
    return css``
  }

  desktopForeign = (...args) => {
    if (this.currentLocale !== LOCALES.ZH) {
      return css`
        ${Media.desktop`
          ${css(...args)}
        `}
      `
    }
    return css``
  }

  mobileForeign = (...args) => {
    if (this.currentLocale !== LOCALES.ZH) {
      return css`
        ${Media.phone`
          ${css(...args)}
        `}
      `
    }
    return css``
  }
}
