import React from 'react'

const SvgScene4 = props => (
  <svg fill='none' width={24} height={24} viewBox='0 0 24 24' {...props}>
    <defs>
      <linearGradient x1={1} y1={0} x2={0} y2={1} id='scene4_svg__a'>
        <stop offset='0%' stopColor='#FDA41F' />
        <stop offset='50.714%' stopColor='#FD5C1F' />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene4_svg__c'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='99.286%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <linearGradient x1={0.5} y1={0} x2={0.5} y2={1} id='scene4_svg__d'>
        <stop offset='0%' stopColor='#FFECE5' stopOpacity={0.6} />
        <stop offset='100%' stopColor='#FFECE5' stopOpacity={0.3} />
      </linearGradient>
      <filter
        id='scene4_svg__b'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur in='BackgroundImage' stdDeviation={3} />
        <feComposite in2='SourceAlpha' operator='in' result='effect1_foregroundBlur' />
        <feBlend in='SourceGraphic' in2='effect1_foregroundBlur' result='shape' />
      </filter>
      <filter
        id='scene4_svg__e'
        filterUnits='objectBoundingBox'
        colorInterpolationFilters='sRGB'
        x={-2}
        y={-1}
        width={19.2}
        height={17.222}
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values='0 0 0 0 0.9921568632125854 0 0 0 0 0.3607843220233917 0 0 0 0 0.12156862765550613 0 0 0 0.30000001192092896 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
      </filter>
    </defs>
    <path
      d='M0 14C0 8.477 4.477 4 10 4h4c5.523 0 10 4.477 10 10v8a2 2 0 01-2 2H2a2 2 0 01-2-2z'
      fill='url(#scene4_svg__a)'
    />
    <g filter='url(#scene4_svg__b)'>
      <circle cx={12} cy={12} fill='url(#scene4_svg__c)' r={12} />
      <circle cx={12} cy={12} stroke='url(#scene4_svg__d)' r={11.5} />
    </g>
    <g filter='url(#scene4_svg__e)'>
      <path
        d='M4.4 15.48h6.456q.074 0 .146-.015.073-.014.14-.042.07-.029.13-.07.062-.04.114-.093.052-.052.094-.114.04-.061.069-.13.028-.067.042-.14.015-.072.015-.146t-.015-.147q-.014-.072-.042-.14-.028-.068-.07-.13-.04-.061-.093-.114-.052-.052-.113-.093-.062-.04-.13-.07-.068-.027-.14-.042-.073-.014-.147-.014H5.15v-3.945h4.946q.074 0 .146-.014.073-.014.14-.043.07-.028.13-.07.062-.04.114-.092.052-.053.094-.114.04-.061.069-.13.028-.068.042-.14.015-.073.015-.147V6.14h3.434q.074 0 .146-.015.073-.014.14-.042.07-.029.13-.07.062-.04.114-.093.053-.052.094-.114.04-.061.069-.13.028-.068.043-.14.014-.072.014-.146t-.014-.147q-.015-.072-.043-.14-.028-.069-.07-.13-.04-.061-.093-.114-.052-.052-.113-.093-.062-.041-.13-.07-.068-.028-.14-.042-.073-.014-.147-.014h-4.184q-.074 0-.146.014-.073.014-.141.043-.068.028-.13.07-.061.04-.113.092-.053.053-.094.114-.04.061-.069.13-.028.068-.043.14-.014.073-.014.147v3.146H4.4q-.074 0-.146.015-.073.014-.141.042-.068.029-.13.07-.061.04-.113.093-.053.052-.094.114-.04.061-.069.13-.028.068-.043.14-.014.072-.014.146v5.445q0 .074.014.146.015.073.043.14.028.069.07.13.04.062.093.114.052.052.113.093.062.041.13.07.068.028.14.042.073.015.147.015z'
        fillRule='evenodd'
        fill='#FFF'
      />
      <path
        d='M19.6 8.528h-5.192q-.074 0-.146.014-.073.015-.141.043-.068.028-.13.07-.061.04-.113.093-.053.052-.094.113-.04.062-.069.13-.028.068-.043.14-.014.073-.014.147t.014.146q.015.073.043.14.028.07.07.13.04.062.093.114.052.052.113.093.062.041.13.07.068.028.14.042.073.015.147.015h4.442v3.78h-4.186q-.074 0-.146.015-.073.015-.141.043-.068.028-.13.07-.061.04-.113.093-.053.052-.094.113-.04.062-.069.13-.028.068-.043.14-.014.073-.014.147v3.302H10.48q-.074 0-.146.015-.073.014-.141.042-.068.029-.13.07-.061.04-.113.093-.053.052-.094.113-.04.062-.069.13-.028.068-.043.14-.014.073-.014.147t.014.146q.015.073.043.141.028.068.07.13.04.061.093.113.052.053.113.094.062.04.13.069.068.028.14.043.073.014.147.014h4.184q.074 0 .146-.014.073-.015.141-.043.068-.028.13-.07.061-.04.113-.093.053-.052.094-.113.04-.062.069-.13.028-.068.043-.14.014-.073.014-.147V15.31H19.6q.074 0 .146-.014.073-.015.141-.043.068-.028.13-.07.061-.04.113-.093.053-.052.094-.113.04-.062.069-.13.028-.068.043-.14.014-.073.014-.147V9.278q0-.074-.014-.146-.015-.073-.043-.141-.028-.068-.07-.13-.04-.061-.093-.113-.052-.053-.113-.094-.062-.04-.13-.07-.068-.027-.14-.042-.073-.014-.147-.014z'
        fillRule='evenodd'
        fill='#FFF'
      />
    </g>
  </svg>
)

export default SvgScene4
