import http from './base.js'
import { getCookie } from './utils'

export default {
  wechat(params) {
    return http.post('/license_recharge/wxpay_recharge', { ...params, referrer: getCookie('referrer') })
  },
  alipay(params) {
    return http.post('/license_recharge/alipay_recharge', { ...params, referrer: getCookie('referrer') })
  },
  paypal(params) {
    return http.post('/license_recharge/paypal_recharge', { ...params, referrer: getCookie('referrer') })
  },
  getFreeLicense(params) {
    return http.post('/license_recharge/free_trail_recharge', { ...params, referrer: getCookie('referrer') })
  },
  smbPaypal(params) {
    return http.post('/license_recharge/smb_paypal_recharge', { ...params, referrer: getCookie('referrer') })
  },
  status({ id }) {
    return http.get(`/license_recharge/get_pay_status/${id}`)
  },
  getPacketConfig(params) {
    return http.get('/base_dd/license_packet_config', { params })
  },
  getFreeLicenseConfig(params) {
    return http.get('/base_dd/license_packet_config_temp', { params })
  },
  getSMBLicenseConfig(params) {
    return http.get('base_dd/smb_license_packet_config', { params })
  },
  smbStatus({ id }) {
    return http.get(`/license_recharge/smb_get_pay_status/${id}`)
  },
}
