import React from 'react'

const SvgNewEmail = props => (
  <svg {...props}>
    <path
      fillRule='evenodd'
      fill='#8996A8'
      d='M21 22H1a1 1 0 01-1-1.001V7.792c0-.368.202.068.526-.881a1.005 1.005 0 011.025.046L3 7.915V.999A.999.999 0 014 0h13.999A1 1 0 0119 .999v6.916l1.449-.958a1 1 0 011.024-.046c.325.949.527.513.527.881v13.207A1 1 0 0121 22zM17 2H5v7.235l6 3.963 6-3.963V2zm2.999 7.651l-8.448 5.579a.999.999 0 01-1.102 0l-8.45-5.579v10.348h18V9.651zM8 9.108a.999.999 0 110-2h2.338a1 1 0 010 2H8zM7 5.58c0-1.075.447-1.01 1-1.01h6c.553 0 1-.065 1 1.01 0 .03-.447 1-1 1H8c-.553 0-1-.97-1-1z'
    />
  </svg>
)

export default SvgNewEmail
