import React from 'react'
import styled, { css } from 'styled-components'
import { Media } from '../../theme'
const FormItemWrap = styled.div`
  position: relative;
  display: flex;
`
const Label = styled.label`
  ${({ theme: { color, typography, Hack } }) => css`
    position:relative;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 44px;
    color: ${color.text};
    font-size: ${typography.textSmall};
    min-width: ${p => p.labelWidth || '90px'};
    ${Hack.en`
      min-width: 150px;
    `}
    ${Hack.ja`
      min-width:160px;
    `}
    ${p => !p.hasContent && 'width: 0;'}
    ::before {
      content: '${p => (p.required ? '*' : '')}';
      display: inline-block;
      width: 7px;
      margin-right: 2px;
      color: ${color.secondary};
      font-size: 12px;
      line-height: 1;
    }
    ${Media.phone`
      min-width: ${p => p.labelWidth || '1.6rem'};
    `}
    ${Hack.mobileForeign`
      font-size: .24rem;
      min-width: 2.2rem;
    `}
`}
`
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  > div {
    display: flex;

    ${Media.phone`
      ${p =>
        p.hasTips &&
        css`
          flex-wrap: wrap;
        `}
    `}
  }
`

const ErrorMessage = styled.p`
  ${({ theme: { typography } }) => css`
    color: #ff4d4f;
    min-height: 18px;
    line-height: 18px;
    text-align: left;
    margin-left: ${p => p.errorLeft};
    font-size: ${typography.textSmall};
  `}
`
const FormItem = props => {
  let {
    required,
    labelStyle,
    labelWidth,
    label,
    children,
    message,
    errorLeft,
    tips,
    contentStyle,
    ...otherProps
  } = props
  return (
    <FormItemWrap {...otherProps}>
      <Label style={labelStyle} labelWidth={labelWidth} required={required} hasContent={!!label}>
        {label}
      </Label>
      <InputWrapper hasMessage={required} hasTips={!!tips}>
        <div style={contentStyle}>{children}</div>
        <ErrorMessage errorLeft={errorLeft}>{message}</ErrorMessage>
      </InputWrapper>
    </FormItemWrap>
  )
}
export default FormItem
