import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Navigation } from '@raysync/common/services'
import { Billboard, H2, Button } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
import { useMedia } from '@raysync/common/hooks'
import RedHatImg from './red-hat.png'
const Wrapper = styled.div`
  margin-top: 60px;
  position: relative;
  height: 600px;
  overflow: hidden;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Media.phone`
    height: 10.4rem;
    display: flex;
    margin-top: 0.9rem;
    justify-content: flex-start;
    padding-left: 1.08rem;
    padding-right: 1rem;
  `}
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    display: flex;
    list-style: none;
    margin-bottom: 38px;
    color: #636772;
    li {
      font-size: 18px;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  img {
    display: block;
  }
  h2 {
    font-weight: bold;
    font-size: 40px;
    margin: 18px 0;
    color: #1a2337;
    line-height: 1.5;
  }
  ${Media.phone`
    align-items: flex-start;
    ul {
      display: block;
      margin-bottom: .38rem;
     li {
       font-size: .3rem;
       margin-bottom: .1rem;
     }
    }
    img {
      height: .42rem;
    }
    h2 {
      font-size: .48rem;
      margin: .26rem 0;
    }
  `}
`
const RedHatBanner = () => {
  const isMobile = useMedia()
  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { eq: "red_hat.png" }) {
            ...fluidImage
          }
          mBanner: file(relativePath: { eq: "red_hat_m.png" }) {
            ...fluidImage
          }
        }
      `}
      render={({ banner, mBanner }) => {
        return (
          <Wrapper>
            <Container>
              <img src={RedHatImg} alt='Red Hat' />
              <H2>红帽技术认证 • 最佳方案 整合合作伙伴奖</H2>
              <ul>
                <li>• 支持在OpenShift上运行 </li>
                <li>• 完全容器化</li>
                <li>• 经过认证的独立应用程序</li>
              </ul>
              <Button
                size='large'
                onClick={() => {
                  Navigation.to({ link: 'https://www.raysync.cn/news/post-id-1482/', isInternal: false })
                }}
              >
                了解更多
              </Button>
            </Container>
            <Billboard fluid={isMobile ? mBanner.childImageSharp.fluid : banner.childImageSharp.fluid} />
          </Wrapper>
        )
      }}
    />
  )
}
export default RedHatBanner
