import React, { PureComponent } from 'react'
import { SnsContainer, NavAnchor } from './atoms'
import intl from 'react-intl-universal'
import FacebookIcon from '../svg/enHome/facebook'
import TwitterIcon from '../svg/enHome/twitter'
import YoutubeIcon from '../svg/enHome/youtube'
import InstagramIcon from '../svg/enHome/instagram'
import LinkedinIcon from '../svg/enHome/linkedin'
const contacts = [
  {
    text: 'Facebook',
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/Raysync-1505841156187794',
  },
  {
    text: 'Twitter',
    icon: <TwitterIcon />,
    link: 'https://twitter.com/raysync',
  },
  {
    text: 'Youtube',
    icon: <YoutubeIcon />,
    link: 'https://www.youtube.com/channel/UCxng8SZfQ6HhbY7utuALVVw/',
  },
  {
    text: 'Instagram',
    icon: <InstagramIcon />,
    link: 'https://www.instagram.com/raysynctech/',
  },
  {
    text: 'Linkedin',
    icon: <LinkedinIcon />,
    link: 'https://www.linkedin.com/company/rasysync',
  },
]
class Sns extends PureComponent {
  render() {
    return (
      <SnsContainer>
        <div style={{ display: 'flex' }}>
          <p>{`${intl.get('app.footer.follow-us')}：`}</p>
          {contacts.map((l, i) => (
            <NavAnchor key={i} rel='nofollow' href={l.link} target='_blank'>
              {l.icon}
            </NavAnchor>
          ))}
        </div>
      </SnsContainer>
    )
  }
}

export default Sns
