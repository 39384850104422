import styled, { css } from 'styled-components'
import { Media } from '@raysync/common/theme'
import { Wrapper, H2 } from '@raysync/common/components'
import { Button } from '@raysync/common/components'

export const BannerWrap = styled(Wrapper)`
  height: 100%;
  max-width: 1400px;
  width: 1400px;
  display: flex;
  margin-top: 120px;
  /* left: ${props => (props.type === 'collaboration' ? '360px' : '260px')}; */
  /* top: 120px; */
  .ftp-ul {
    position: absolute;
    top: 0;
    right: 60px;
    width: 270px;
    height: 288px;
    padding: 20px  0 0 40px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.8); 
    border-radius: 10px;
    li {
      margin-top: 10px;
      display: flex;
      justify-content: left;
      overflow: hidden;
      font-family: Source Han Sans;
      font-size: 16px;
      font-weight: normal;
      align-items: center;
      letter-spacing: 0em;
      font-variation-settings: 'opsz' auto;
      color: #252a3a;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        position: static;
      }
    }
  }
  ${Media.phone`
    flex-wrap: wrap;
    padding: 0;
    align-content: space-between;
  `}
`
export const BannerContainer = styled.div`
  ${({ theme: { color } }) => css`
    position: relative;
    height: 460px;
    overflow: hidden;
    background-color: ${color.panel};
    ${Media.phone`
      height: 10.4rem;
   `}
  `}
`

export const BannerLeft = styled.div`
  max-height: 640px;
  ul {
    width: 700px;
    height: 94px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    li {
      display: flex;
      justify-content: left;
      overflow: hidden;
      font-family: Source Han Sans;
      font-size: 18px;
      font-weight: normal;
      align-items: center;
      letter-spacing: 0em;
      font-variation-settings: 'opsz' auto;
      color: #252a3a;
      margin-right: 40px;
      margin-top: 20px;
      img {
        width: 24px;
        height: 24px !important;
        margin-right: 10px;
        position: static;
      }
    }
    li:nth-child(5) {
      margin-right: 0;
    }
    .third-item {
      margin-right: 88px;
    }
  }
  ${Media.phone`
    width: 100%;
    Button {
      margin-left: 0.2rem;
    }
    ul{
      width: 82%;
      height: 1.4rem;
      margin: auto;
      margin-top: 0.4rem;
      justify-content: left;
      li{
        all: unset;
        overflow: visible;
        font-size: 0.22rem;
        width: 40%;
        font-weight: normal;
        height: 0.3rem;
        margin-right: 0.3rem;
        img {
          transform: none!important;
          width: 0.24rem;
          height: 0.24rem;
          position: static;
          margin: auto;
          margin-right: 0.1rem;
        }
        &:nth-child(4) {
          width: auto;
          margin-right: 0rem;
        }
        &:nth-child(5) {
          width: auto;
        }
      }
    }
  `}
`
export const BannerRight = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${p => (p.right ? p.right + 'px' : 0)};
  overflow: hidden;
  svg,
  img {
    height: ${p => (p.isProduct ? '360px' : '400px')};
  }

  &.solution6-banner svg {
    width: 684px;
  }
  &.network1-banner svg {
    height: 360px;
    width: 430px;
  }
  &.network3-banner svg {
    height: 360px;
    width: 430px;
  }
  ${Media.phone`
    width: 100%;
    svg,img {
      width: 7.3rem !important;
      height: 5.3rem !important;
      transform: translate(-1.3rem,0.1rem) scale(1.2);
    }
  `}
`

export const BannerTitle = styled(H2)`
  ${({ theme: { Hack } }) => css`
    line-height: 1.4;
    padding-bottom: 20px;
    font-size: 40px;
    max-width: 570px;
    span {
      font-weight: 500;
    }

    ${Media.phone`
    margin: 0rem auto 0;
    height: 1.3rem;
    /* max-width: 100%; */
    /* padding: 0.4rem 0.54rem; */
    font-size: 0.24rem;
    line-height: 0.3rem;
  `}
  `}
`
export const BannerDesc = styled.p`
  ${({ theme: { color, typography, Hack } }) => css`
    margin-top: 30px;
    width: 700px;
    font-size: ${typography.text};
    /* span {
      display: inline-block;
      width: 100%;
    } */
    .highlight {
      color: ${p => p.theme.color.secondary};
    }
    font-family: Source Han Sans;
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    font-variation-settings: 'opsz' auto;
    color: #252a3a;
    &.zh-CN-solution2 {
      white-space: nowrap;
    }
    &.zh-CN-network1 {
      width: 490px;
    }
    &.zh-CN-network4 {
      width: 545px;
    }
    &.zh-CN-network5 {
      width: 575px;
    }
    &.zh-CN-network6 {
      width: 490px;
    }

    ${Hack.desktopEn`
      font-size: ${typography.text};
    `}

    ${Media.phone`
      margin: 0.2rem auto;
      max-width: 100%;
      height: 1.7rem;
      padding: 0 0.54rem;
      font-size: ${typography.textThin};
      line-height: 0.18rem;
      text-align: center;
      span {
        text-align: center;
        font-family: 思源黑体;
        font-size: 0.29rem;
        font-weight: normal;
        line-height: normal;
        text-align: center;
        letter-spacing: 0px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.zh-CN-solution2 {
        white-space: initial;
      }
    `}
  `}
`
export const BannerButton = styled(Button)`
  ${({ theme: { Hack, typography } }) => css`
    margin-top: 20px;

    min-width: 140px;
    min-height: 36px;
    font-size: ${typography.text};

    ${Hack.desktopEn`
      min-width: 120px;
      min-height: 44px;
      font-size: 20px;
    `}

    ${Media.phone`
      min-height:auto;
      font-size:0.24rem
    `}
  `}
`

export const BannerImg = styled.img`
  height: 418px;
  ${Media.phone`
    width: 100%;
    height: 4rem;
  `}
`
export const IndustryBannerContainer = styled(BannerContainer)`
  height: 400px;
`
